import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useAccountTypes = () => {

	const distinctAccountTypes = commonObjIncStoreZustand((state) => ({
		accountTypes: state.accountTypes,
		chains: state.chains,
		locations: state.locations,
	}));


	return { distinctAccountTypes };
};

export default useAccountTypes;
