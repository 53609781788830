import { create } from "zustand";
import { Boost, CustomProductsSummary } from "../types/boostTypes";
import api from "../../../utils/api";
import snack from "../../../utils/snack";
import { IWholeSaler } from "../../usersManagement/types/types";
import { IBoostProductsQuery, IBoostQuery } from "../../../hooks/useQuery";

export const BASE_PATH_V3 = "/api/v3";
export const BASE_PATH_V3_BOOSTS = `${BASE_PATH_V3}/boosts`;

interface Store {
	boosts: Boost[];
	boost: Boost | null;
	loading: boolean;
	boostLoading: boolean;
	boostsTotalPages: number;
	boostsCurrentPage: number;
	products: any[];
	productsTotalPages: number;
	productsCurrentPage: number;
	productsLoading: boolean;
	selectedProducts: any[];
	selectedProductsTotalPages: number;
	selectedProductsCurrentPage: number;
	selectedProductsLoading: boolean;
	selectedCustomProducts: any[];
	selectedCustomProductsTotalPages: number;
	selectedCustomProductsCurrentPage: number;
	selectedCustomProductsLoading: boolean;
	productsTypes: IWholeSaler | null;
	costEstimateData: any;
	costEstimateLoading: boolean;
	usedProductsFilters: {
		supplier: string[];
		brand: string[];
		packageType: string[];
		size: string[];
		productType: string[];
		brandFams: string[];
		wholesalerId?: string;
	};
	setBoostsCurrentPage: (page: number) => void;
	setProductsCurrentPage: (page: number) => void;
	setProductsLoading: (loading: boolean) => void;
	setSelectedProductsCurrentPage: (page: number) => void;
	setSelectedProductsLoading: (loading: boolean) => void;
	setSelectedCustomProductsCurrentPage: (page: number) => void;
	setSelectedCustomProductsLoading: (loading: boolean) => void;
	setCostEstimateLoading: (loading: boolean) => void;
	fetchBoost: (query: IBoostQuery, boostId?: string) => void;
	addBoost: (
		query: IBoostQuery,
		newItem: Omit<Boost, "_id" | "customId">
	) => any;
	editBoost: (query: IBoostQuery, boost: Partial<Boost>) => void;
	deleteBoost: (query: IBoostQuery, boostId: string) => void;
	fetchBoosts: (query: IBoostQuery, boostId?: string) => void;
	fetchUsedProductsFilters: (query: IBoostQuery) => void;
	fetchProducts: (query: IBoostProductsQuery) => void;
	fetchProductsTypes: (query: IBoostQuery) => void;
	fetchCostEstimateData: (
		query: IBoostQuery,
		boostId: string,
		selectedProductsFilters: CustomProductsSummary
	) => void;
	fetchSelectedProducts: (query: IBoostProductsQuery) => void;
	fetchSelectedCustomProducts: (
		query: IBoostQuery,
		boostId: string,
		body?: any
	) => void;
	updateProducts: (
		query: any,
		newProducts: { productId: string; checked: boolean }[]
	) => void;
}

export const boostStore = create<Store>((set) => ({
	boosts: [],
	boost: null,
	loading: false,
	boostLoading: false,
	boostsTotalPages: 0,
	boostsCurrentPage: 1,
	products: [],
	productsTotalPages: 0,
	productsCurrentPage: 1,
	productsLoading: false,
	usedProductsFilters: {
		supplier: [],
		brand: [],
		packageType: [],
		size: [],
		productType: [],
		brandFams: [],
		wholesalerId: undefined,
	},
	selectedProducts: [],
	selectedProductsTotalPages: 0,
	selectedProductsCurrentPage: 1,
	selectedProductsLoading: false,
	selectedCustomProducts: [],
	selectedCustomProductsTotalPages: 0,
	selectedCustomProductsCurrentPage: 1,
	selectedCustomProductsLoading: false,
	productsTypes: null,
	costEstimateData: null,
	costEstimateLoading: false,
	setBoostsCurrentPage: (page: number) => set({ boostsCurrentPage: page }),
	setProductsCurrentPage: (page: number) => set({ productsCurrentPage: page }),
	setSelectedProductsCurrentPage: (page: number) =>
		set({ selectedProductsCurrentPage: page }),
	setSelectedCustomProductsCurrentPage: (page: number) =>
		set({ selectedCustomProductsCurrentPage: page }),
	setCostEstimateLoading: (loading: boolean) =>
		set({ costEstimateLoading: loading }),
	setProductsLoading: (loading: boolean) => set({ productsLoading: loading }),
	setSelectedProductsLoading: (loading: boolean) =>
		set({ selectedProductsLoading: loading }),
	setSelectedCustomProductsLoading: (loading: boolean) =>
		set({ selectedCustomProductsLoading: loading }),
	fetchBoosts: async (query: IBoostQuery) => {
		set({ loading: true });

		const res = await api.fetch({
			path: BASE_PATH_V3_BOOSTS,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				boosts: res.payload,
				boostsTotalPages: res?.meta?.totalPages,
			});
			set({ loading: false });
		} else {
			snack.error("Error fetching boosts");
			set({ boosts: [] });
			set({ loading: false });
		}
	},
	fetchBoost: async (query: IBoostQuery, boostId?: string) => {
		set({ boostLoading: true });
		if (boostId !== undefined && boostId !== "") {
			const res = await api.fetch({
				path: `${BASE_PATH_V3_BOOSTS}/${boostId}`,
				method: "GET",
				query,
			});
			if (res.ok === true) {
				set({ boost: res.data });
				set({ boostLoading: false });
			} else {
				snack.error("Error fetching boost");
				set({ boost: null });
				set({ boostLoading: false });
			}
		}
	},
	fetchUsedProductsFilters: async (query: IBoostQuery) => {
		set({ boostLoading: true });
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/activeFilters`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({ usedProductsFilters: res.payload });

			set({ boostLoading: false });
		} else {
			snack.error("Error fetching boost");
			set({
				usedProductsFilters: {
					supplier: [],
					brand: [],
					packageType: [],
					size: [],
					productType: [],
					brandFams: [],
					wholesalerId: undefined,
				},
			});
			set({ boostLoading: false });
		}
	},
	addBoost: async (
		query: IBoostQuery,
		newItem: Omit<Boost, "_id" | "customId">
	) => {
		const res = await api.fetch({
			path: BASE_PATH_V3_BOOSTS,
			method: "POST",
			query,
			body: newItem,
		});

		if (res.ok) {
			set((state) => {
				const updatedBoosts = [...state.boosts, res.payload];
				return { boosts: updatedBoosts };
			});
			if (query.parentId) {
				snack.success("The boost has been successfully copied");
			}
			return res.payload;
		} else {
			snack.error("Error adding boost");
		}
	},
	editBoost: async (query: IBoostQuery, boost: Partial<Boost>) => {
		snack.info("Updating...");
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${boost._id}`,
			method: "PATCH",
			query,
			body: boost,
		});

		if (res.ok) {
			snack.success("Update completed 👍");
			set((state) => {
				const updatedBoosts = state.boosts.map((b) =>
					b._id === boost._id ? res.payload : b
				);
				return { boosts: updatedBoosts };
			});
		} else {
			snack.error("Error editing boost");
		}
	},
	deleteBoost: async (query: IBoostQuery, boostId: string) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${boostId}`,
			method: "DELETE",
			query,
		});

		if (res.ok) {
			set((state) => ({
				boosts: state.boosts.filter((boost) => boost._id !== boostId),
			}));
		} else {
			snack.error("Error deleting boost");
		}
	},
	fetchProducts: async (query: IBoostProductsQuery) => {
		set({ productsLoading: true });

		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${query.boostId}/products`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				products: res.payload.results,
				productsTotalPages: res?.meta?.totalPages,
			});
			set({ productsLoading: false });
		} else {
			snack.error("Error fetching products");
			set({ products: [] });
			set({ productsLoading: false });
		}
	},
	fetchProductsTypes: async (query: IBoostQuery) => {
		const res = await api.fetch({
			path: "/wholesaler",
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ productsTypes: res.payload });
		} else {
			console.log("error fetching users");
			set({ productsTypes: null });
		}
	},
	fetchCostEstimateData: async (
		query: IBoostQuery,
		boostId: string,
		selectedProductsFilters: CustomProductsSummary
	) => {
		set({ costEstimateLoading: true });
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${boostId}/selectedCustomProductsSummary`,
			method: "POST",
			query,
			body: selectedProductsFilters,
		});

		if (res.ok === true) {
			set({ costEstimateData: res.payload });
			set({ costEstimateLoading: false });
		} else {
			snack.error("Error fetching cost estimate data");
			set({ costEstimateLoading: false });
		}
	},
	fetchSelectedProducts: async (query: IBoostProductsQuery) => {
		set({ selectedProductsLoading: true });
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${query.boostId}/allProducts`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({
				selectedProducts: res.payload.results,
				selectedProductsTotalPages: res.payload.count,
			});
			set({ selectedProductsLoading: false });
		} else {
			snack.error("Error fetching selected products");
			set({ selectedProducts: [] });
			set({ selectedProductsLoading: false });
		}
	},
	fetchSelectedCustomProducts: async (
		query: IBoostQuery,
		boostId: string,
		body?: any
	) => {
		set({ selectedCustomProductsLoading: true });
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${boostId}/selectedCustomProducts`,
			method: "POST",
			query,
			body,
		});

		if (res.ok === true) {
			set({
				selectedCustomProducts: res.payload,
				selectedCustomProductsTotalPages: res.meta?.totalPages || 0,
			});
			set({ selectedCustomProductsLoading: false });
		} else {
			snack.error("Error fetching selected custom products");
			set({ selectedCustomProducts: [] });
			set({ selectedCustomProductsLoading: false });
		}
	},
	updateProducts: async (
		query: any,
		newProducts: { productId: string; checked: boolean }[]
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V3_BOOSTS}/${query.boostId}/updateProducts`,
			method: "POST",
			query,
			body: {
				boostId: query.boostId,
				products: newProducts,
			},
		});

		if (res.ok === true) {
			console.log("Products updated successfully");
		} else {
			console.log("error fetching users");
		}
	},
}));
