import { useMemo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { mapRowToColumns } from "../utils/mapRowToColumns";
import { Box, CircularProgress, Paper, styled } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { useStyles } from "./ItemManagementReportTable.styles";
import { Stack } from "@mui/material";
import { commonObjIncStoreZustand, IRow } from "../store/commonObjIncStore";
import {
	filterRows,
	calculateTableTotals,
	transformColumnName,
} from "../utils/utils";

interface IProps {
	itemType: string;
	loading: boolean;
	columns: string[];
	rows: IRow[];
	onEdit: (row: any) => void;
	searchValue: string;
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
	maxHeight: "calc(100vh - 300px)",
	overflowX: "auto",
	overflowY: "scroll",
	width: "100%",
	"&::-webkit-scrollbar": {
		// display: "none",
	},
	"& .MuiTableCell-head": {
		backgroundColor: "#D0D5ED",
		fontWeight: 600,
		color: "#3D4EA1",
	},
	"& .MuiTableCell-root": {
		padding: theme.spacing(1.2),
		maxWidth: "250px",
	},
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	padding: theme.spacing(1.5),
}));

export default function ItemsManagementReportTable(props: IProps) {
	const { itemType, loading, columns, rows, onEdit, searchValue } = props;

	const isObjective = itemType === "objective";

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
	} = commonObjIncStoreZustand();

	const classes = useStyles();

	const filteredRows = useMemo(
		() => filterRows(rows, searchValue),
		[rows, searchValue]
	);

	const totals = useMemo(() => {
		return calculateTableTotals(filteredRows, itemType);
	}, [filteredRows, itemType]);

	return (
		<Box sx={{ my: 2 }}>
			<Stack spacing={2}>
				{!loading ? (
					<Paper elevation={2}>
						<StyledTableContainer>
							<Table
								stickyHeader
								size="medium"
								style={{ width: "max-content" }}
							>
								<TableHead>
									<TableRow>
										{columns.map((column, index) => (
											<StyledTableCell key={index}>
												<TableSortLabel
													active={sortColumnName === column}
													direction={sortDirection}
													onClick={() => {
														const transformedColName =
															transformColumnName(column);
														setDirection(
															sortDirection === "asc" ? "desc" : "asc"
														);
														setColumnName(column);
														setOrder(
															sortDirection === "asc" ? "desc" : "asc",
															transformedColName
														);
													}}
												>
													{column}
												</TableSortLabel>
											</StyledTableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredRows.map((row, index) => (
										<TableRow key={index} hover>
											{mapRowToColumns(
												row,
												columns,
												isObjective,
												classes,
												onEdit
											)}
										</TableRow>
									))}
									{filteredRows.length > 0 && (
										<TableRow className={classes.totalRow}>
											<StyledTableCell>Total</StyledTableCell>
											{columns.slice(1).map((column, index) => (
												<StyledTableCell key={index}>
													{column === "Quantity" && totals.quantity.toFixed(2)}
													{column === "Eligible Quantity" &&
														totals.eligibleQuantity.toFixed(2)}
													{column === "Target" &&
														(isNaN(totals.target)
															? "∞"
															: totals.target.toFixed(2))}
													{column === "Payout MTD" &&
														(isNaN(totals.payoutMTD)
															? "∞"
															: `$ ${totals.payoutMTD.toFixed(2)}`)}
													{column === "Potential" &&
														(isNaN(totals.payout)
															? "∞"
															: `$ ${totals.payout.toFixed(2)}`)}
													{column === "Supplier Contribution" &&
														itemType === "incentive" &&
														(isNaN(totals.supplierContribution)
															? "0"
															: `$ ${totals.supplierContribution.toFixed(2)}`)}
												</StyledTableCell>
											))}
										</TableRow>
									)}
								</TableBody>
							</Table>
							{filteredRows.length === 0 && (
								<Box sx={{ p: 4, textAlign: "center" }}>
									<Typography variant="subtitle1">No data</Typography>
								</Box>
							)}
						</StyledTableContainer>
					</Paper>
				) : (
					<Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
						<CircularProgress />
					</Box>
				)}
			</Stack>
		</Box>
	);
}
