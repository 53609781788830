import { useEffect, useState } from "react";
import { ManualProductsFilters } from "../../../usersManagement/types/commonObjIncTypes";
import useBoostSelectedProducts from "../../hooks/useBoostSelectedProducts";
import snack from "../../../../utils/snack";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { TableContainer } from "@mui/material";
import { Paper } from "@mui/material";
import { Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import ManualProductsListItem from "../../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/accountsProductsUsersSelect/productsSelect/ManualProductListItem";
import { useStyles } from "./ProductsSelection.styles";
import Pagination from "@material-ui/lab/Pagination";

interface BoostSelectedProductsProps {
	boostId: string;
	filters: ManualProductsFilters;
	allSelected: boolean;
	clearSelection: boolean;
	setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
	setClearSelection: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BoostSelectedProducts({
	boostId,
	filters,
	allSelected,
	clearSelection,
	setAllSelected,
	setClearSelection,
}: BoostSelectedProductsProps) {
	const classes = useStyles();
	const [currentSelectedPage, setCurrentSelectedPage] = useState(1);

	const { selectedProducts, selectedProductsTotalPages, selectedProductsLoading, updateProducts } =
		useBoostSelectedProducts({
			page: currentSelectedPage,
			boostId,
			filters,
		});

	const [selectedProds, setSelectedProds] = useState<
		{ productId: string; checked: boolean }[]
	>([]);

	const handleSave = (showSnack = false) => {
		const productsToUpdate = selectedProds.map((product) => ({
			productId: product.productId,
			checked: product.checked,
		}));
		updateProducts(productsToUpdate);
		if (showSnack) {
			snack.success("Products updated 👍");
		}
	};

	useEffect(() => {
		const initialSelections = selectedProducts;
		const selections = initialSelections.map((prod: any) => ({
			productId: prod.customId,
			checked: prod.isSelected || false,
		}));

		setSelectedProds(selections);
	}, [selectedProducts]);

	useEffect(() => {
		if (allSelected) {
			setSelectedProds((prev) =>
				prev.map((prod) => ({ ...prod, checked: true }))
			);
		}
	}, [allSelected]);

	useEffect(() => {
		if (clearSelection) {
			setSelectedProds((prev) =>
				prev.map((prod) => ({ ...prod, checked: false }))
			);
		}
	}, [clearSelection]);

	const handleSelectedPageChange = (_event: any, page: number) => {
		handleSave();
		setCurrentSelectedPage(page);
		setAllSelected(false);
		setClearSelection(false);
	};

	const handleCheck = (productId: string) => {
		setSelectedProds((prev) =>
			prev.map((a) =>
				a.productId === productId ? { ...a, checked: !a.checked } : a
			)
		);
	};

	const loading = selectedProductsLoading;

	return (
		<div className={classes.listContainer}>
			<div className={classes.content}>
				{loading ? (
					<div className={classes.progress}>
						<CircularProgress size={"1rem"} />
					</div>
				) : selectedProducts.length === 0 ? (
					<Typography className={classes.notFound} variant="body1">
						No products selected
					</Typography>
				) : (
					<Paper className={classes.table}>
						<TableContainer className={classes.tableContainer}>
							<Table aria-label="products table">
								<TableHead>
									<TableRow>
										<TableCell className={classes.headerCell}>Select</TableCell>
										<TableCell className={classes.headerCell}>Name</TableCell>
										<TableCell className={classes.headerCell}>Size</TableCell>
										<TableCell className={classes.headerCell}>Supplier</TableCell>
										<TableCell className={classes.headerCell}>Brand</TableCell>
										<TableCell className={classes.headerCell}>Package</TableCell>
										<TableCell className={classes.headerCell}>Tag</TableCell>
										<TableCell className={classes.headerCell}>Brand Family</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedProducts.map((prod: any) => (
										<ManualProductsListItem
											key={prod.customId}
											product={prod}
											selected={
												selectedProds.find((a) => a.productId === prod.customId)
													?.checked || false
											}
											handleCheck={() => handleCheck(prod.customId)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<div className={classes.saveButtonContainer}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleSave(true)}
								className={classes.button}
							>
								SAVE
							</Button>
						</div>
					</Paper>
				)}
			</div>
			<div className={classes.pagination}>
				<Pagination
					count={selectedProductsTotalPages}
					page={currentSelectedPage}
					onChange={handleSelectedPageChange}
					color="primary"
				/>
			</div>
		</div>
	);
}
