import {
	Checkbox,
	CircularProgress,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@material-ui/core';
import { useStyles } from './GroupedTable.styles';
import { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from "@material-ui/icons/Edit";
import { Pagination } from '@material-ui/lab';
import { AccountTypeState, Item } from '../../../types/commonObjIncTypes';
import { useFormContext } from 'react-hook-form';
import { Incentive } from '../../../types/incentivesTypes';
import { Objective } from '../../../types/objectiveTypes';
import { EditGroupedItemComponent } from '../EditGroupedItemComponent';
import useHandleItemsUpdate from '../../../hooks/useHandleItemsUpdate';
import useChildItem from '../../../hooks/useChildItem';

interface FilteredData {
	totalFilteredPages: number;
	currentFilteredPage: number;
	setCurrentFilteredPage: (page: number) => void;
	loadingFiltered: boolean;
}

interface GroupedData {
	totalGroupedPages: number;
	currentGroupedPage: number;
	setCurrentGroupedPage: (page: number) => void;
	loadingGrouped: boolean;
}

interface GroupedTableProps {
	itemType: string;
	filteredItems: Item[];
	groupedItems: Item[];
	onGroupedItemsChange: (updatedGroupedItems: any[]) => void;
	filteredData: FilteredData;
	groupedData: GroupedData;
}

export const GroupedTable = ({
	itemType,
	filteredItems,
	groupedItems,
	onGroupedItemsChange,
	filteredData,
	groupedData,
}: GroupedTableProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	const { totalFilteredPages, currentFilteredPage, setCurrentFilteredPage, loadingFiltered } = filteredData;
	const { totalGroupedPages, currentGroupedPage, setCurrentGroupedPage, loadingGrouped } = groupedData;
	
	const { editItem } = useHandleItemsUpdate({ itemType, isObjective: itemType === "objective" ? true : false })


	const [initialFilteredItems, setInitialFilteredItems] = useState<Item[]>(filteredItems);
	const [initialGroupedItems, setInitialGroupedItems] = useState<Item[]>(groupedItems);
	const [selectedFilteredItems, setSelectedFilteredItems] = useState<Item[]>([]);
	const [selectedGroupedItems, setSelectedGroupedItems] = useState<Item[]>([]);
	const [editingWeightItemId, setEditingWeightItemId] = useState<string | null>(null);
	const [editingWeightValue, setEditingWeightValue] = useState<number>();
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editableItemId, setEditableItemId] = useState<string | undefined>(undefined);

	const { childItem, childItemLoading } = useChildItem({
		childItemId: editableItemId,
		isObjective: itemType === "objective" ? true : false,
	});

	const handleEditItem = (id: string) => {
		if (openEditModal) {
			setOpenEditModal(false);
			setEditableItemId(undefined);
		}

		if (id) {
			setEditableItemId(id);
			setOpenEditModal(true);
		}
	};

	const handleSaveEditItem = (item: Item) => {
		setOpenEditModal(false);
		if (editableItemId) {
			
			editItem(editableItemId, item as Partial<Objective | Incentive>, true);
			
			const updatedItems = initialGroupedItems.map((i) =>
				i._id === item._id ? item : i
			);
			setInitialGroupedItems(updatedItems);
			onGroupedItemsChange(updatedItems);
		}
	};

	useEffect(() => {
		setInitialFilteredItems(filteredItems);
	}, [filteredItems]);

	useEffect(() => {
		setInitialGroupedItems(groupedItems);
	}, [groupedItems]);

	const handleSelectFilteredItem = (item: Item) => {
		setSelectedFilteredItems((prevSelected) => {
			const isSelected = prevSelected.some(
				(selected) => selected._id === item._id
			);
			if (isSelected) {
				return prevSelected.filter(
					(selected) => selected._id !== item._id
				);
			} else {
				return [...prevSelected, item];
			}
		});
	};
	const handleSelectGroupedItem = (item: Item) => {
		setSelectedGroupedItems((prevSelected) => {
			const isSelected = prevSelected.some(
				(selected) => selected._id === item._id
			);
			if (isSelected) {
				return prevSelected.filter(
					(selected) => selected._id !== item._id
				);
			} else {
				return [...prevSelected, item];
			}
		});
	};

	const handleAddToGroupedItems = () => {
		const updatedGroupedItems = [
			...initialGroupedItems,
			...selectedFilteredItems,
		];
		setInitialGroupedItems(updatedGroupedItems);
		setInitialFilteredItems(
			initialFilteredItems.filter(
				(item) => !selectedFilteredItems.includes(item)
			)
		);
		setSelectedFilteredItems([]);
		onGroupedItemsChange(updatedGroupedItems);
	};

	const handleRemoveFromGroupedItems = () => {
		const updatedGroupedItems = initialGroupedItems.filter(
			(item) => !selectedGroupedItems.includes(item)
		);
		setInitialGroupedItems(updatedGroupedItems);
		setInitialFilteredItems([
			...initialFilteredItems,
			...selectedGroupedItems,
		]);
		setSelectedGroupedItems([]);
		onGroupedItemsChange(updatedGroupedItems);
	};

	const handleWeightEdit = (item: Item) => {
		setEditingWeightItemId(item._id);
		setEditingWeightValue(item.weight)
	};

	const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newWeight = parseInt(event.target.value);
		setEditingWeightValue(newWeight);

		const updatedItems = initialGroupedItems.map((item) =>
			item._id === editingWeightItemId
				? { ...item, weight: newWeight }
				: item
		);
		setInitialGroupedItems(updatedItems);
		onGroupedItemsChange(updatedItems);

	};

	return (
		<div className={classes.tables}>
			<div className={classes.container}>
				<div className={classes.tableContainer}>
					{loadingFiltered ? (
						<div className={classes.progress}>
							<CircularProgress size={"1rem"} />
						</div>
					) : initialFilteredItems.length === 0 ? (
						<Typography className={classes.notFound} variant="body1">
								No available {itemType === "objective" ? "objectives" : "incentives"}
						</Typography>
					) : (
						<TableContainer>
									<Table>
										<TableHead className={classes.tableHead}>
											<TableRow>
												<TableCell className={classes.tableHeadCell}></TableCell>
												<TableCell className={classes.tableHeadCell}>
													Available {itemType === "objective" ? "Objectives" : "Incentives"}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{initialFilteredItems.map((item) => (
												<TableRow key={item._id}>
													<TableCell className={classes.tableCell}>
														<Checkbox
															checked={selectedFilteredItems.some(obj => obj._id === item._id)}
															onChange={() => handleSelectFilteredItem(item)}
														/>
													</TableCell>
													<TableCell className={classes.tableCell}>{item.name}</TableCell>
												</TableRow>
											))
											}
										</TableBody>
									</Table>
						</TableContainer>
					)}
				</div>
				<div style={{ marginTop: 30 }}>
					<Pagination
						count={totalFilteredPages}
						page={currentFilteredPage}
						onChange={(_, value) => setCurrentFilteredPage(value)}
						color="primary"
					/>
				</div>
			</div>
			<div className={classes.arrowContainer}>
				<ArrowForwardIcon
					onClick={handleAddToGroupedItems}
					className={classes.arrow}
				/>
				<ArrowBackIcon
					onClick={handleRemoveFromGroupedItems}
					className={classes.arrow}
				/>
			</div>
			<div className={classes.container}>
				<div className={classes.tableContainer}>
					{loadingGrouped ? (
						<div className={classes.progress}>
							<CircularProgress size={"1rem"} />
						</div>
					) : initialGroupedItems.length === 0 ? (
						<Typography className={classes.notFound} variant="body1">
								No grouped {itemType === "objective" ? "objectives" : "incentives"} selected
						</Typography>
					) : (
						<TableContainer>
									<Table>
										<TableHead className={classes.tableHead}>
											<TableRow>
												<TableCell className={classes.tableHeadCell}></TableCell>
												<TableCell className={classes.tableHeadCell}>
													Grouped {itemType === "objective" ? "Objectives" : "Incentives"}
												</TableCell>
												<TableCell className={classes.tableHeadCell}>Weight %</TableCell>
												<TableCell className={classes.tableHeadCell}>Edit</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{initialGroupedItems.map((item) => (
												<TableRow key={item._id}>
													<TableCell className={classes.tableCell}>
														<Checkbox
															checked={selectedGroupedItems.some(obj => obj._id === item._id)}
															onChange={() => handleSelectGroupedItem(item)}
														/>
													</TableCell>
													<TableCell className={classes.tableCell}>
														{item.name}
													</TableCell>
													<TableCell className={classes.tableCell} onClick={() => handleWeightEdit(item)}>
														{editingWeightItemId === item._id ? (
															<TextField
																name="weight"
																defaultValue={editingWeightValue ?? 0}
																error={!!errors.weight}
																helperText={errors.weight && errors.weight.message}
																onChange={handleWeightChange}
																autoFocus
																type="number"
																className={classes.weightField}
																inputRef={register({
																	validate: {
																		positiveNumber: value => {
																			return parseInt(value) >= 0 || "Weight value must be a positive number";
																		}
																	}
																})}
															/>
														) : (
																item.weight ?? 0
														)}
													</TableCell>
													<TableCell className={classes.tableCell}>
														<IconButton
															size="small"
															color="primary"
															onClick={() => handleEditItem(item._id)}
														>
															<EditIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											))
											}
										</TableBody>
									</Table>
						</TableContainer>
					)}
				</div>
				{!childItemLoading && childItem && (
					<EditGroupedItemComponent
						initialGroupedItem={childItem}
						open={openEditModal}
						itemType={itemType === "objective" ? "objective" : "incentive"}
						onSave={handleSaveEditItem}
						onClose={() => setOpenEditModal((false))}
					/>
				)}
				<div style={{ marginTop: 30 }}>
					<Pagination
						count={totalGroupedPages}
						page={currentGroupedPage}
						onChange={(_, value) => setCurrentGroupedPage(value)}
						color="primary"
					/>
				</div>
			</div>
		</div>
	);
};
