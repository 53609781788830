import {
	CircularProgress,
	FormControl,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Switch,
	FormControlLabel,
	TextField,
	Box,
} from "@material-ui/core";
import Select from "react-select";
import { AddCircleOutline } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import { CustomObjIncProductFilters } from "../../../usersManagement/types/commonObjIncTypes";
import useBoostSelectedCustomProducts from "../../hooks/useBoostSelectedCustomProducts";
import useProductsFilters from "../../hooks/useProductsFilters";
import { Boost } from "../../types/boostTypes";
import { useStyles } from "./ProductsSelection.styles";

type BoostCustomProductsProps = {
	selectedProductsFilters: CustomObjIncProductFilters;
	setSelectedProductsFilters: React.Dispatch<
		React.SetStateAction<CustomObjIncProductFilters>
	>;
	boost: Boost;
};

export const BoostCustomProducts: React.FC<BoostCustomProductsProps> = ({
	selectedProductsFilters,
	setSelectedProductsFilters,
	boost,
}) => {
	const classes = useStyles();
	const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
	const [showTable, setShowTable] = useState(false);
	const [searchPhrase, setSearchPhrase] = useState("");
	const { distinctProducts, usedProductsFilters } = useProductsFilters();
	const [removedFilters, setRemovedFilters] = useState<{ [key: string]: string[] }>({});


	const {
		selectedCustomProducts,
		selectedCustomLoading,
		selectedCustomProductsTotalPages,
	} = useBoostSelectedCustomProducts({
		page: currentSelectedPage,
		boost,
		selectedProductsFilters,
		searchPhrase,
	});

	const getOptionsForFilter = (filterName: string) => {
		const usedValues =
			usedProductsFilters[filterName as keyof typeof usedProductsFilters] || [];

		// Check if the value is used and not in removedFilters
		const isValueDisabled = (value: string) => {
			const isRemoved = removedFilters[filterName]?.includes(value);
			return usedValues.includes(value) && !isRemoved;
		};

		switch (filterName) {
			case "supplier":
				return distinctProducts.suppliers.map((supplier) => ({
					value: supplier,
					label: supplier,
					isDisabled: isValueDisabled(supplier),
				}));
			case "brand":
				return distinctProducts.brands.map((brand) => ({
					value: brand,
					label: brand,
					isDisabled: isValueDisabled(brand),
				}));
			case "packageType":
				return distinctProducts.packageTypes.map((packageType) => ({
					value: packageType,
					label: packageType,
					isDisabled: isValueDisabled(packageType),
				}));
			case "size":
				return distinctProducts.sizes.map((size) => ({
					value: size,
					label: size,
					isDisabled: isValueDisabled(size),
				}));
			case "productType":
				return distinctProducts.productTypes.map((productType) => ({
					value: productType,
					label: productType,
					isDisabled: isValueDisabled(productType),
				}));
			case "brandFams":
				return distinctProducts.brandFams?.map((bf) => ({
					value: bf,
					label: bf,
					isDisabled: isValueDisabled(bf),
				}));
			default:
				return [];
		}
	};

	const handleProductSelectChange = (
		selectedOptions: any,
		selectName: string
	) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => {
			const previousValues = prevSelectedProducts[selectName] || [];
			const newValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
			
			// Find values that were removed
			const removedValues = previousValues.filter((value: string) => !newValues.includes(value));
			
			// Find values that were re-added
			const readdedValues = newValues.filter(
				(value: string) => 
					!previousValues.includes(value) && 
					removedFilters[selectName]?.includes(value)
			);

			// Update the state for removed filters
			if (removedValues.length > 0 || readdedValues.length > 0) {
				setRemovedFilters(prev => {
					const updatedRemovedFilters = { ...prev };
					
					// Add new removed values
					if (removedValues.length > 0) {
						updatedRemovedFilters[selectName] = [
							...(prev[selectName] || []),
							...removedValues
						];
					}
					
					// Remove re-added values
					if (readdedValues.length > 0) {
						updatedRemovedFilters[selectName] = prev[selectName]?.filter(
							value => !readdedValues.includes(value)
						) || [];
					}

					return updatedRemovedFilters;
				});
			}

			return {
				...prevSelectedProducts,
				[selectName]: newValues,
			};
		});
	};

	const handleSelectAll = (filterName: string) => {
		const allOptions = getOptionsForFilter(filterName)
			.filter((option) => !option.isDisabled) // Only select enabled options
			.map((option) => option.value);

		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: allOptions,
		}));
	};

	const handleRemoveAll = (filterName: string) => {
		setSelectedProductsFilters((prevSelectedProducts: any) => ({
			...prevSelectedProducts,
			[filterName]: [],
		}));
	};

	const filterLabels: { [key: string]: string } = {
		supplier: "Suppliers",
		brand: "Brands",
		packageType: "Package Types",
		size: "Sizes",
		productType: "Product Types",
		brandFams: "Brand Family",
	};

	const handlePageChange = (_event: any, page: number) => {
		setCurrentSelectedPage(page);
	};

	const handleShowTableChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setShowTable(event.target.checked);
	};

	const handleSearchPhraseChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchPhrase(event.target.value);
		setSelectedProductsFilters((prev) => ({
			...prev,
			searchPhrase: event.target.value,
		}));
	};

	return (
		<div className={classes.container}>
			<div>
				{Object.entries(selectedProductsFilters).map(
					([filterName, selectedValues]) => (
						<FormControl
							key={filterName}
							variant="outlined"
							className={classes.marginTop}
						>
							<div className={classes.rowContainer}>
								<Select
									className={classes.select}
									isMulti
									name={filterName}
									placeholder={`Select ${filterLabels[filterName]}`}
									options={getOptionsForFilter(
										filterName as keyof CustomObjIncProductFilters
									)}
									value={
										Array.isArray(selectedValues)
											? selectedValues.map((value: string) => ({
													value,
													label: value,
											  }))
											: []
									}
									onChange={(selectedOption: any) =>
										handleProductSelectChange(
											selectedOption,
											filterName as keyof CustomObjIncProductFilters
										)
									}
									styles={{
										menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
										valueContainer: (provided) => ({
											...provided,
											maxHeight: "100px",
											overflowY: "auto",
										}),
									}}
									menuPortalTarget={document.body}
								/>
								<div className={classes.buttonContainer}>
									<IconButton
										color="primary"
										size="small"
										onClick={() => handleSelectAll(filterName)}
									>
										<AddCircleOutline />
									</IconButton>
									<IconButton
										size="small"
										onClick={() => handleRemoveAll(filterName)}
									>
										<HighlightOffIcon />
									</IconButton>
								</div>
							</div>
						</FormControl>
					)
				)}
			</div>
			<FormControlLabel
				control={
					<Switch
						checked={showTable}
						onChange={handleShowTableChange}
						name="showTable"
						color="primary"
					/>
				}
				label="Show Selected Products"
				style={{ marginTop: "10px" }}
			/>
			{showTable && (
				<div>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						{/* <Typography variant="h6">Selected Products</Typography> */}
						<TextField
							variant="outlined"
							size="small"
							label="Search Products"
							value={searchPhrase}
							onChange={handleSearchPhraseChange}
							style={{
								width: "200px",
								marginBottom: "10px",
								marginTop: "10px",
							}}
						/>
					</Box>
					<div
						className={classes.selectedTable}
						style={{
							height: selectedCustomProducts.length === 0 ? "15vh" : "45vh",
						}}
					>
						{selectedCustomLoading ? (
							<div className={classes.progress}>
								<CircularProgress size={"1rem"} />
							</div>
						) : selectedCustomProducts.length === 0 ? (
							<Typography className={classes.notFound} variant="body1">
								No products selected
							</Typography>
						) : (
							<Paper className={classes.table}>
								<TableContainer className={classes.tableContainer}>
									<Table stickyHeader aria-label="products table">
										<TableHead>
											<TableRow>
												<TableCell className={classes.headerCell}>
													Name
												</TableCell>
												<TableCell className={classes.headerCell}>
													Size
												</TableCell>
												<TableCell className={classes.headerCell}>
													Supplier
												</TableCell>
												<TableCell className={classes.headerCell}>
													Brand
												</TableCell>
												<TableCell className={classes.headerCell}>
													Premise
												</TableCell>
												<TableCell className={classes.headerCell}>
													Package
												</TableCell>
												<TableCell className={classes.headerCell}>
													Tag
												</TableCell>
												<TableCell className={classes.headerCell}>
													Brand Family
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedCustomProducts.map((prod: any) => (
												<TableRow>
													<TableCell>{prod.name}</TableCell>
													<TableCell>{prod.size ? prod.size : "-"}</TableCell>
													<TableCell>
														{prod.supplier ? prod.supplier : "-"}
													</TableCell>
													<TableCell>{prod.brand ? prod.brand : "-"}</TableCell>
													<TableCell>
														{prod.premise ? prod.premise : "-"}
													</TableCell>
													<TableCell>
														{prod.packageType ? prod.packageType : "-"}
													</TableCell>
													<TableCell>{prod.tags ? prod.tags : "-"}</TableCell>
													{/* <TableCell>{product.units}</TableCell> */}
													<TableCell>
														{prod.brandFam && prod.brandFam.length > 0
															? prod.brandFam
															: "-"}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Paper>
						)}
					</div>
					<div className={classes.pagination}>
						<Pagination
							count={selectedCustomProductsTotalPages}
							page={currentSelectedPage}
							onChange={handlePageChange}
							color="primary"
						/>
					</div>
				</div>
			)}
		</div>
	);
};
