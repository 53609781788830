import { useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { boostStore } from "../store/boostStore";

const useFetchProductsFilters = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	useEffect(() => {
		boostStore.getState().fetchProductsTypes({ wholesalerId });
	}, [wholesalerId]);
};

export default useFetchProductsFilters;
