import { Button } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import * as XLSX from "xlsx";
import { mapForExport } from "./mapForExport";

interface ExportXLSButtonProps {
	columns?: string[];
	rows?: any[];
	totals: {
		payout: number;
		payoutMTD: number;
		target: number;
		quantity: number;
		eligibleQuantity: number;
		supplierContribution?: number;
	};
}

export const ExportXLSButton = ({
	columns,
	rows,
	totals,
}: ExportXLSButtonProps) => {
	const exportToXLSX = () => {
		const wb = XLSX.utils.book_new();

		const typeIndex = columns?.indexOf("Type");
		const filteredColumns = [
			...((columns || [])).slice(0, typeIndex),
			"Parent Name",
			...((columns || []).slice(typeIndex).filter(
				(column) => column !== "Accounts" && column !== "Products" && column !== "Accounts Results"
			)),
		];

		const totalRow = [
			"Total",
			...filteredColumns.slice(1).map((column) => {
				if (column === "Potential") return isNaN(totals?.payout) ? "∞" : totals?.payout || 0;
				if (column === "Payout MTD") return totals?.payoutMTD || 0;
				if (column === "Target") return isNaN(totals?.target) ? "∞" : totals?.target || 0;
				if (column === "Quantity") return totals?.quantity || 0;
				if (column === "Eligible Quantity") return totals?.eligibleQuantity || 0;
				if (column === "Supplier Contribution") return totals?.supplierContribution || 0;
				return "";
			}),
		];

		const transformedRows = rows?.map((row) =>
			mapForExport(row, filteredColumns)
		);

		const dataWithTotal = [filteredColumns, ...(transformedRows || []), totalRow]; 

		const ws = XLSX.utils.aoa_to_sheet(dataWithTotal);
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "exported_data.xlsx");
	};

	return (
		<Button
			size="small"
			variant="outlined"
			startIcon={<PrintIcon />}
			onClick={exportToXLSX}
			style={{
				width: "100%",
				color: "#3D4EA1",
				backgroundColor: "#e8eaf6",
				borderColor: "#D0D5ED",
			}}
		>
			XLSX
		</Button>
	);
};
