import {
	Box,
	CircularProgress,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { BoostDisplayComponent } from "./BoostDisplayComponent";
import { useStyles } from "./BoostTable.styles";
import { useMemo, useState } from "react";
import { Pagination } from "@material-ui/lab";
import { Boost } from "./types/boostTypes";

interface BoostsListProps {
	boosts: Boost[];
	loading?: boolean;
	currentPage?: number;
	totalPages?: number;
	setCurrentPage: (page: number) => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	onDuplicate: (id: string) => void;
}

export const BoostsList: React.FC<BoostsListProps> = ({
	boosts,
	loading,
	currentPage,
	totalPages,
	setCurrentPage,
	onEdit,
	onDelete,
	onDuplicate,
}) => {
	const classes = useStyles();

	const [sortConfig, setSortConfig] = useState<{
		key: keyof Boost;
		direction: "asc" | "desc";
	} | null>(null);

	const sortedBoosts = useMemo(() => {
		const sortedArray = [...boosts];
		if (sortConfig !== null) {
			sortedArray.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === "asc" ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === "asc" ? 1 : -1;
				}
				return 0;
			});
		}
		return sortedArray;
	}, [boosts, sortConfig]);

	const handleSort = (key: keyof Boost) => {
		setSortConfig((prev) => {
			if (prev && prev.key === key) {
				return {
					key,
					direction: prev.direction === "asc" ? "desc" : "asc",
				};
			}
			return { key, direction: "desc" };
		});
	};

	return (
		<Box className={classes.itemList}>
			<Paper className={classes.tableContainer}>
				<TableContainer className={classes.tableWrapper}>
					<Table stickyHeader className={classes.customTable}>
						<TableHead>
							<TableRow className={classes.tableHead}>
								<TableCell className={classes.customCell}>
									<TableSortLabel
										active={sortConfig?.key === "name"}
										direction={
											sortConfig?.key === "name"
												? sortConfig.direction
												: "asc"
										}
										onClick={() => handleSort("name")}
									>
										Name
									</TableSortLabel>
								</TableCell>
								<TableCell className={classes.customCell}>
									<TableSortLabel
										active={sortConfig?.key === "type"}
										direction={
											sortConfig?.key === "type"
												? sortConfig.direction
												: "asc"
										}
										onClick={() => handleSort("type")}
									>
										Type
									</TableSortLabel>
								</TableCell>
								<TableCell className={classes.customCell}>
									<TableSortLabel
										active={sortConfig?.key === "boost"}
										direction={
											sortConfig?.key === "boost"
												? sortConfig.direction
												: "asc"
										}
										onClick={() => handleSort("boost")}
									>
										Boost
									</TableSortLabel>
								</TableCell>
								<TableCell className={classes.customCell}>
									Period
								</TableCell>
								<TableCell className={classes.customCell}>
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedBoosts.map((boost) => (
								<BoostDisplayComponent
									key={boost._id}
									boost={boost}
									onDelete={() => onDelete(boost._id)}
									onEdit={() => onEdit(boost._id)}
									onDuplicate={() => onDuplicate(boost._id)}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<div className={classes.footer}>
					{loading ? (
						<CircularProgress size={24} />
					) : (
						<Typography variant="body2"></Typography>
					)}
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={(_, value) => setCurrentPage(value)}
						color="primary"
						size="small"
					/>
				</div>
			</Paper>
		</Box>
	);
};
