import { Route, Switch, Redirect } from "react-router-dom";
import FilesView from "../files/FilesView";
import NotFoundView from "../notFound/NotFoundView";
import ExecutiveReportView from "../executiveReport/ExecutiveReportView";
import SuppliersView from "../suppliers/SuppliersView";
import BrandsView from "../suppliers/BrandsView";
import SalesRepsView from "../users/SalesRepsView";
import TeamLeads from "../users/TeamLeads";
import Leadership from "../users/Leadership";
import ProductsView from "../products/ProductsView";
import Buyers from "../buyers/Buyers";
import WholesalerView from "../wholesaler/WholesalerView";
import DiffView from "../diff/DiffView";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import qs from "qs";
import ProfileView from "../profile/ProfileView";
import UserManagementView from "../usersManagement/UserManagementView";
import PrivateRoute from "./PrivateRoute";
import { ItemsReportingTab } from "../objectivesIncentives/itemsReporting/ItemsReportingTab";
import { BoostView } from "../boost/BoostView";

function App() {
	const { query } = useQuery<IGeneralQuery>();
	const isAdmin = useSelector(
		(state: RootState) => state.session.role === "admin"
	);
	return (
		<Switch>
			<Route exact path="/">
				<Redirect
					to={
						isAdmin
							? {
									pathname: "/wholesaler",
									search: qs.stringify({
										wholesalerId: query.wholesalerId,
										db: query.db,
									}),
							  }
							: "/files"
					}
				/>
			</Route>
			<Route exact path="/files" component={FilesView} />
			<Route exact path="/profile" component={ProfileView} />
			<PrivateRoute
				exact
				path="/executiveReport"
				component={ExecutiveReportView}
				roles={["admin", "manager"]}
			/>
			<PrivateRoute
				exact
				path="/suppliers"
				component={SuppliersView}
				roles={["admin", "manager", "teamLead"]}
				requiresLeadership={true}
			/>
			<PrivateRoute
				exact
				path="/brands"
				component={BrandsView}
				roles={["admin", "manager", "teamLead"]}
				requiresLeadership={true}
			/>
			<PrivateRoute
				exact
				path="/userManagement"
				component={UserManagementView}
				roles={["admin", "manager"]}
			/>
			<PrivateRoute
				exact
				path="/objectives"
				component={()=><ItemsReportingTab type="objective"/>}
				roles={["admin", "manager"]}
			/>
			<PrivateRoute
				exact
				path="/incentives"
				component={() => <ItemsReportingTab type="incentive" />}
				roles={["admin", "manager"]}
			/>
			<Route exact path="/salesreps" component={SalesRepsView} />
			<PrivateRoute
				exact
				path="/teamleads"
				component={TeamLeads}
				roles={["teamLead", "manager", "admin"]}
			/>
			<PrivateRoute
				exact
				path="/leadership"
				component={Leadership}
				roles={["manager", "admin", "teamLead"]}
				requiresLeadership={true}
			/>
			<Route exact path="/products" component={ProductsView} />
			<Route exact path="/boost" component={BoostView} />
			<Route exact path="/customers" component={Buyers} />
			{/* <Route exact path="/analytics" component={Analytics} /> */}
			<PrivateRoute
				exact
				path="/wholesaler"
				component={WholesalerView}
				roles={["admin"]}
			/>
			<Route exact path="/diff" component={DiffView} />
			<Route path="*">
				<NotFoundView />
			</Route>
		</Switch>
	);
}

export default App;
