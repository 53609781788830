import React, { useState, useEffect } from "react";
import { Objective } from "../../types/objectiveTypes"; // Import your Objective and GenericItem types
import { Item } from "../../types/commonObjIncTypes";
import ItemComponent from "../../common/ItemComponent";
import moment from "moment";
import useHandleItemsUpdate from "../../hooks/useHandleItemsUpdate";
import useItems from "../../hooks/useItems";
import useItem from "../../hooks/useItem";

export const ObjectivesComponent: React.FC = () => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedTag, setSelectedTag] = useState<string>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);
	const [selectedType, setSelectedType] = useState<string | undefined>("");
	const [selectedObjectiveId, setSelectedObjectiveId] = useState<string>("");
	const [pendingAction, setPendingAction] = useState<{
		id: string;
		type: "archive" | "favorite";
	} | null>(null);

	const { items, currentPage, setCurrentPage, totalPages, loading } = useItems({
		searchPhrase: searchTerm !== "" ? searchTerm : undefined,
		type: selectedType,
		tag: selectedTag || undefined,
		periodStart,
		periodEnd,
		itemsType: "objectives",
		isObjective: true,
		isArchived: false,
		isFavorite: false,
	});

	const { item, itemLoading } = useItem({
		itemId: selectedObjectiveId,
		isObjective: true,
		isArchived: false,
	});

	console.log("item obj", item);
	const { addItem, editItem, deleteItem, runQuery, resetValues } =
		useHandleItemsUpdate({ itemType: "objective", isObjective: true });

	const handleAddObjective = async (
		newObjectiveData: Omit<Item, "_id">,
		parentId?: string
	) => {
		return await addItem(newObjectiveData as Omit<Objective, "_id">, parentId);
	};

	const handleEditObjective = (id: string, updatedObjective: Partial<Item>) => {
		editItem(id, updatedObjective as Partial<Objective>);
	};

	const handleDeleteObjective = (id: string) => {
		deleteItem(id);
	};

	const handleArchiveObjective = (id: string) => {
		setSelectedObjectiveId(id);
		setPendingAction({ id, type: "archive" });
	};

	const handleFavoriteObjective = (id: string) => {
		setSelectedObjectiveId(id);
		setPendingAction({ id, type: "favorite" });
	};

	const handleDuplicateObjective = (id: string) => {
		const objective = items.find((objective) => objective._id === id);

		if (objective) {
			const newName = `${objective.name} copy`;
			const {
				_id: id,
				customId,
				users: originalUsers,
				...restOfObject
			} = objective;

			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));

			handleAddObjective(
				{
					...restOfObject,
					name: newName,
					favorite: false,
					users: initializedUsers,
				},
				id
			);
		}
	};

	useEffect(() => {
		if (
			pendingAction &&
			!itemLoading &&
			item &&
			item._id === pendingAction.id
		) {
			const updatedItem = {
				...item,
				archived:
					pendingAction.type === "archive" ? !item.archived : item.archived,
				favorite:
					pendingAction.type === "favorite" ? !item.favorite : item.favorite,
			};

			editItem(
				pendingAction.id,
				updatedItem,
				updatedItem.isGrouped,
				pendingAction.type === "archive" ? true : false
			);
			setPendingAction(null);
		}
	}, [pendingAction, itemLoading, item, editItem]);

	const handleAction = (id: string) => {
		const objective = items.find((objective) => objective._id === id);
		console.log("objective", objective);
		if (objective) {
			runQuery(objective._id);
		}
	};

	const handleReset = (id: string) => {
		const objective = items.find((objective) => objective._id === id);
		if (objective) {
			resetValues(objective._id);
		}
	};
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
	};

	const handleTagChange = (selectedOption: any) => {
		if (selectedOption.value === selectedTag) {
			setSelectedTag("");
		} else {
			setSelectedTag(selectedOption.value);
		}
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
	};

	const handleClearFilters = () => {
		setCurrentPage(1);
		setSearchTerm("");
		setSelectedTag("");
		setSelectedType("");
		setPeriodStart(null);
		setPeriodEnd(null);
	};

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			loading={loading}
			items={items}
			itemType="objective"
			onAdd={handleAddObjective}
			onEdit={handleEditObjective}
			onDelete={handleDeleteObjective}
			onFavorite={handleFavoriteObjective}
			onDuplicate={handleDuplicateObjective}
			onArchive={handleArchiveObjective}
			onAction={handleAction}
			onReset={handleReset}
			searchTerm={searchTerm}
			onSearchChange={handleSearchChange}
			selectedTag={selectedTag}
			onTagChange={handleTagChange}
			periodStart={periodStart}
			periodEnd={periodEnd}
			onPeriodStartChange={handlePeriodStartChange}
			onPeriodEndChange={handlePeriodEndChange}
			selectedType={selectedType}
			onTypeChange={handleTypeChange}
			handleClearFilters={handleClearFilters}
		/>
	);
};
