import { makeStyles } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
	nameDescriptionPeriod: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "3rem",
		marginTop: "2rem",
	},
	supplierContribution: {
		marginRight: "1rem",
		marginTop: "1rem",
	},
	textField: {
		width: "9rem",
	},
	boostContainer: {
		display: "flex",
		flexDirection: "row",
	},
	select: {
		width: "9rem",
		marginLeft: "1rem",
		height: "40px",
		marginTop: "8px",
	},
	buttonsWrapper: {
		margin: "20px",
		display: "flex",
		justifyContent: "space-between",
		position: "relative",
		bottom: 0,
	},
	flexColumn: {
		display: "flex",
		flexDirection: "column",
	},
	accordionsContainer: {
		maxWidth: "100%",
		minWidth: "100%",
	},
}));
