import { TableRow, TableCell, Tooltip, IconButton, DialogTitle, DialogContentText, DialogContent, Button, Dialog, DialogActions } from "@material-ui/core";
import { capitalizeFirstLetter } from "../usersManagement/utils";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import { formatDate } from "../objectivesIncentives/utils/utils";
import { useStyles } from "./BoostTable.styles";
import { useState } from "react";
import { Boost } from "./types/boostTypes";

interface BoostDisplayComponentProps {
	boost: Boost;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate?: () => void;
}

export const BoostDisplayComponent: React.FC<BoostDisplayComponentProps> = ({ boost, onDelete, onEdit, onDuplicate }) => {
	const classes = useStyles();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const handleDeleteClick = () => {
		setDeleteModalOpen(true);
	};

	const handleConfirmDelete = () => {
		onDelete(boost._id);
		setDeleteModalOpen(false);
	};

	return (
		<>
			<TableRow className={classes.customRow}>
				<TableCell className={classes.customRow}>
					<Tooltip title={capitalizeFirstLetter(boost.name)}>
						<div>
							{capitalizeFirstLetter(boost.name)}
						</div>
					</Tooltip>
				</TableCell>
				<TableCell className={classes.customRow}>{boost.type}</TableCell>
				<TableCell className={classes.customRow}>${boost.boost}</TableCell>
				<TableCell className={classes.customRow}>
					{formatDate(boost.startDate || '')} - {formatDate(boost.endDate || '')}
				</TableCell>
				<TableCell className={classes.customRow}>
					<IconButton
						size="small"
						color="primary"
						onClick={() => onEdit(boost._id)}
					>
						<Tooltip title="Edit">
							<EditIcon />
						</Tooltip>
					</IconButton>
					<IconButton size="small" color="primary" onClick={onDuplicate}>
						<Tooltip title="Duplicate">
							<FileCopyIcon />
						</Tooltip>
					</IconButton>
					<IconButton size="small" onClick={handleDeleteClick}>
						<Tooltip title="Delete">
							<DeleteIcon />
						</Tooltip>
					</IconButton>
				</TableCell>
			</TableRow>
			<Dialog
				open={deleteModalOpen}
				onClose={() => setDeleteModalOpen(false)}
				aria-labelledby="delete-dialog-title"
				aria-describedby="delete-dialog-description"
			>
				<DialogTitle>Delete Boost</DialogTitle>
				<DialogContent>
					<DialogContentText>Are you sure you want to delete this boost?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
					<Button onClick={handleConfirmDelete}>Delete</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
