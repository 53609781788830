import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BuyersControls from "./BuyersControls";
import BuyersList from "./BuyersList";
import BuyerDetails from "./BuyerDetails";
import useQuery, { IBuyersQuery } from "../../hooks/useQuery";
import SpacingBox from "../spacingBox/SpacingBoxView";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import BuyersSkeleton from "./BuyersSkeleton";
import { getBuyers } from "./buyersSlice";
import useFetchDistinctAccounts from "../usersManagement/hooks/useFetchDistinctAccounts";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
    marginRight: -theme.spacing(3),
    marginBottom: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    marginTop: -theme.spacing(),
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  listAndSummary: {
    marginRight: theme.spacing(2),
  },
}));

export default function BuyersView() {
  const { query } = useQuery<IBuyersQuery>();

  const isIdle = useSelector(
    (state: RootState) => state.buyers.status === "idle"
  );
  const selectedBuyer = query.selectedBuyer;
  useFetchDistinctAccounts();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      getBuyers({
        wholesalerId: query.wholesalerId,
        salesRep: query.salesRep,
        sortBy: query.sortBy,
        searchPhrase: query.searchPhrase,
        page: query.page ? Number(query.page) : undefined,
        chain: query.chain,
        location: query.location,
      })
    );
  }, [
    dispatch,
    query.salesRep,
    query.sortBy,
    query.searchPhrase,
    query.page,
    query.wholesalerId,
    query.chain,
    query.location,
  ]);

  const classes = useStyles();
  if (isIdle) {
    return <BuyersSkeleton />;
  }
  return (
    <Box className={classes.container}>
      <BuyersControls />
      <Box className={classes.content}>
        <BuyersList />
        <SpacingBox />
        {!!selectedBuyer && <BuyerDetails buyerId={selectedBuyer} />}
      </Box>
    </Box>
  );
}
