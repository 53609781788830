import { ImageListCell } from "./ImageListCell";
import { ProductsList } from "./ProductsList";
import AccountsList from "./AccountsList";
import { formatDate, formatTypeLabel } from "./utils";
import { useState } from "react";
import { TableCell } from "@material-ui/core";
import AccountsResultsList from "./AccountsResultsList";
import { IRow } from "../store/commonObjIncStore";

const getStatusCell = (progress: number | string) => {
	let statusColor = "black";
	if (progress === "∞") {
		return <span>{progress}</span>;
	} else if (!isNaN(Number(progress))) {
		let progressNum = Number(progress);
		if (progressNum < 0) {
			progressNum = 0;
		}
		if (progressNum < 50) statusColor = "red";
		else if (progressNum >= 50 && progressNum < 80) statusColor = "#F4C01D";
		else if (progressNum >= 80) statusColor = "#4E915E";
		return <span style={{ color: statusColor }}>{`${progressNum}%`}</span>;
	}
	return <span style={{ color: "red" }}>{`0 %`}</span>;
};

const RouteCell = ({ route }: { route: number[] }) => {
	const [isHovered, setIsHovered] = useState(false);
	const maxLength = 20;

	const formattedRoute = Array.isArray(route) ? route.join(", ") : "";

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{ position: "relative", display: "inline-block" }}
		>
			<span>
				{formattedRoute !== ", "
					? formattedRoute.length <= maxLength || isHovered
						? formattedRoute
						: `${formattedRoute.substring(0, maxLength)}...`
					: "-"}
			</span>
		</div>
	);
};

export const mapRowToColumns = (
	row: IRow,
	columns: string[],
	isObjective: boolean,
	classes?: any,
	onRowClick?: (row: IRow) => void
) => {
	const orderedRow: any[] = [];

	columns.forEach((column) => {
		switch (column) {
			case "Item ID":
				orderedRow.push(row.itemId);
				break;
			case "Item Name":
				orderedRow.push(row.name);
				break;
			case "User Name":
				orderedRow.push(row.userName);
				break;
			case "Start Date":
				orderedRow.push(row.startDate ? formatDate(row.startDate) : "-");
				break;

			case "End Date":
				orderedRow.push(row.endDate ? formatDate(row.endDate) : "-");
				break;

			case "Status":
				orderedRow.push(getStatusCell(row.status));
				break;

			case "Image":
				orderedRow.push(
					row.images && row.images.length > 0 ? (
						<ImageListCell
							images={row.images}
							isRejected={false}
							itemId={row.itemId}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Rejected Images":
				orderedRow.push(
					row.rejectedImages && row.rejectedImages.length > 0 ? (
						<ImageListCell
							images={row.rejectedImages}
							isRejected={true}
							itemId={row.itemId}
						/>
					) : (
						"-"
					)
				);
				break;

			case "Route":
				orderedRow.push(<RouteCell route={row.route || []} />);
				break;

			case "Name":
				orderedRow.push(row.name);
				break;

			case "Type":
				orderedRow.push(formatTypeLabel(row.type));
				break;

			case "Measure":
				orderedRow.push(row.measure || "-");
				break;

			case "Percentage Achieved":
				orderedRow.push(`${row.percentageAchieved}%`);
				break;

			case "Quantity":
				orderedRow.push(row.quantity || "0");
				break;

			case "Eligible Quantity":
				orderedRow.push(row.eligibleQuantity || "-");
				break;
			case "Minimum Quantity":
				orderedRow.push(row.minQuantityValue || "-");
				break;

			case "Target":
				orderedRow.push(row.userTarget !== "∞" ? row.userTarget : "∞");
				break;

			case "Potential":
				orderedRow.push(
					row.potential !== "∞" && !row.isOpenEnded ? `$ ${row.potential}` : "∞"
				);
				break;

			case "Payout MTD":
				orderedRow.push(row.payoutMTD ? `$ ${row.payoutMTD}` : "$ 0");
				break;

			case "Parent Item ID":
				orderedRow.push(row.parentItemId || "-");
				break;

			case "Parent Item Name":
				orderedRow.push(row.parentItemName || "-");
				break;

			case "Supplier Contribution":
				orderedRow.push(
					row.supplierContribution ? `$ ${row.supplierContribution}` : "-"
				);
				break;

			case "Accounts":
				orderedRow.push(
					<AccountsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={isObjective}
						userType={row.isTeamLead ? "teamLead" : "salesRep"}
					/>
				);
				break;

			case "Products":
				orderedRow.push(
					<ProductsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={isObjective}
					/>
				);
				break;

			case "Accounts Results":
				orderedRow.push(
					row.isAccountSpecific ? (
						<AccountsResultsList
							itemId={row.itemId}
							userId={row.userId}
							isDelta={row?.measure?.includes("Δ")}
						/>
					) : (
						"-"
					)
				);
				break;

			default:
				orderedRow.push("-");
				break;
		}
	});

	if (!onRowClick) {
		return orderedRow;
	}

	return orderedRow.map((cellContent, index) => {
		const column = columns[index];
		const isInteractive = [
			"Accounts",
			"Products",
			"Image",
			"Rejected Images",
			"Accounts Results",
		].includes(column);

		return (
			<TableCell
				key={index}
				onClick={!isInteractive ? () => onRowClick(row) : undefined}
				className={`${classes.clickableRow} ${
					column === "User Name"
						? classes.stickyColumn
						: column === "Route"
						? classes.stickyColumnLeft
						: classes.tableCell
				}`}
			>
				{cellContent}
			</TableCell>
		);
	});
};
