import { makeStyles } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles({
	customTable: {
		width: "100%",
		overflowX: "auto",
		// tableLayout: "fixed",
	},
	customCell: {
		backgroundColor: "lightgrey",
		padding: "15px",
	},
	customRow: {
		padding: "5px 10px",
		"& td": {
			minWidth: "fit-content",
		},
	},
	actionsButtons: {
		minWidth: "250px",
		width: "auto",
		justifyContent: "flex-start",
		flexWrap: "wrap",
		cursor: "pointer",
	},
	customRowName: {
		minWidth: "200px",
		maxWidth: "300px",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customRowTag: {
		minWidth: "120px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customRowPeriod: {
		minWidth: "200px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customQuantityType: {
		minWidth: "120px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	customMeasuresType: {
		minWidth: "150px",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	tableHead: {
		"& .MuiTableCell-head": {
			color: "white",
			backgroundColor: indigo[300],
		},
	},
	notFound: {
		marginTop: "2rem",
		marginLeft: "1rem",
	},
	truncateText: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	tableContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		maxHeight: "calc(100% - 16px)",
		position: "relative",
	},
	tableWrapper: {
		minHeight: 0,
		flex: 1,
		overflow: "auto",
	},
	footer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "8px 16px",
		borderTop: "1px solid rgba(224, 224, 224, 1)",
		backgroundColor: "#fff",
		position: "sticky",
		bottom: 0,
		zIndex: 2,
	},
});
