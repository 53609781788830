import { useState, useEffect } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";

const useImages = ({ itemId }: { itemId: string }) => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const rejectImage = (imageId: string, rejectedReason: string) => {
		commonObjIncStoreZustand.getState().rejectImage(query, itemId, imageId, rejectedReason);

	};

	const unRejectImage = (imageId: string) => {

		commonObjIncStoreZustand
			.getState()
			.unRejectImage(query, itemId, imageId);

	};

	return {
		rejectImage,
		unRejectImage
	};
};

export default useImages;
