import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		gap: "3rem",
		padding: "2rem 3rem",
		justifyContent: "space-between",
		height: "100vh",
	},
	title: {
		textAlign: "center",
		fontSize: "1.5em",
		paddingTop: "1em",
	},
	target: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem",
	},
	radio: {
		display: "flex",
		flexDirection: "column",
		marginBottom: "2rem",
	},
	textField: {
		width: "50%",
	},
	buttons: {
		display: "flex",
		justifyContent: "space-between",
		alignSelf: "flex-end",
		width: "100%",
	},
	payout: {
		display: "flex",
		flexDirection: "column",
		gap: "1.5rem",
	},
	targetPayout: {
		display: "flex",
		flexDirection: "column",
		gap: "3rem",
	},
	editedInfo: {
		display: "flex",
		alignItems: "center",
		fontStyle: "italic",
		marginLeft: "5px",
	},
});
