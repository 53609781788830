import { create } from "zustand";
import api from "../../../utils/api";
import {
	IAccountsPaginated,
	IExportItemsQuery,
	IGeneralQuery,
	IGeneralQueryItem,
	IGeneralQueryWithCopy,
	IPaginatedQuery,
	IProductsPaginatedQuery,
	IQueryFavorite,
	IQueryFavoriteWithSearch,
	IQueryFiltered,
	IQueryReportingAccounts,
	ISelectedCustomQuery,
} from "../../../hooks/useQuery";
import { IBuyer, ISortDirection } from "../../../interfaces";
import { Accounts } from "./objectivesStore";
import { Objective } from "../../usersManagement/types/objectiveTypes";
import {
	AdvancedPayment,
	Incentive,
} from "../../usersManagement/types/incentivesTypes";
import snack from "../../../utils/snack";
import { ObjIncentiveImage } from "../utils/utils";

export const BASE_PATH_V2 = "/api/v2";
export const BASE_PATH_V2_OBJECTIVE = `${BASE_PATH_V2}/objective`;
export const BASE_PATH_V2_INCENTIVE = `${BASE_PATH_V2}/incentive`;

export const BASE_PATH_V3 = "/api/v3";
export const BASE_PATH_V3_ITEMS = `${BASE_PATH_V3}/items`;
export interface IRow {
	itemId: string;
	userId: string;
	userName: string;
	route: any;
	name: string;
	type: string; // Could be "multi", "quantity", etc.
	measure: string; // Represents measure type or "-"
	status: number; // Numeric status value
	percentageAchieved: number; // Percentage as a number
	quantity: number; // Numeric quantity
	eligibleQuantity: string | number; // Could be a string ("-") or a number
	target: number | string; // Could be a number or "∞"
	potential: number | string; // Could be a number or "∞"
	payoutMTD: number; // Monetary value
	parentItemId: string; // Parent item ID or "-"
	parentItemName: string; // Parent item name or "-"
	supplierContribution?: number | string;
	startDate: string;
	endDate: string;
	images?: ObjIncentiveImage[];
	rejectedImages?: ObjIncentiveImage[];
	isAccountSpecific: boolean;
	isOpenEnded: boolean;
	isTeamLead: boolean;
	isLeadership: boolean;
	valueInputType?: string;
	valueInput?: number;
	weight?: number;
	minQuantityValue?: number;
	threshold: {
		value: number;
		units: string;
	};
	cap: {
		value: number;
		units: string;
	};
	payoutType?: string;
	quantityPayout?: number;
	userTarget: number | string;
	allOrNothingAmount: number;
	perActionAmount: number;
	atRiskAmount: number;
	advancedBudget?: number;
	advancedPayments?: AdvancedPayment[];
	payout: number;
	isFullAmount: boolean;
	isEdited?: boolean;
}
interface Store {
	item: Objective | Incentive | null;
	semiItem: Objective | Incentive | null;
	archivedItems: (Objective | Incentive)[];
	favoriteItems: (Objective | Incentive)[];
	items: (Objective | Incentive)[];
	reportingItems: {
		item: Objective | Incentive;
		subItems: (Objective | Incentive)[];
		groupedChildren: (Objective | Incentive)[];
	}[];
	subItems: (Objective | Incentive)[];
	filteredItems: (Objective | Incentive)[];
	groupedChildren: (Objective | Incentive)[];
	rows: IRow[];
	columns: string[];
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	loadingRows: boolean;
	hasMoreRows: boolean;
	rowsCurrentPage: number;
	rowsTotalPages: number;
	rowsLimit: number;
	loading: boolean;
	reportingItemsTotalPages?: number;
	reportingItemsCurrentPage?: number;
	reportingItemsLoading: boolean;
	itemsTotalPages?: number;
	itemsCurrentPage?: number;
	itemLoading: boolean;
	semiItemLoading: boolean;
	totalFilteredPages: number;
	currentFilteredPage: number;
	loadingFiltered: boolean;
	totalGroupedChildrenPages: number;
	currentGroupedChildrenPage: number;
	loadingGroupedChildren: boolean;
	accounts: Accounts[];
	superTeamMembers: any;
	superTeamMembersToRemove: any;
	teamMembers: any;
	teamMembersToRemove: any;
	products: any;
	tags: { _id: string; name: string; color: string }[];
	selectedProducts: any;
	selectedProductsTotalPages: number;
	selectedProductsLoading: boolean;
	selectedProductsCurrentPage: number;
	selectedCustomProducts: any;
	selectedCustomProductsTotalPages: number;
	selectedCustomProductsLoading: boolean;
	selectedCustomProductsCurrentPage: number;
	selectedAccounts: any;
	selectedAccountsTotalPages: number;
	selectedAccountsLoading: boolean;
	selectedAccountsCurrentPage: number;
	totalPages?: number;
	productsTotalPages: number;
	productsCurrentPage: number;
	productsLoading: boolean;
	allBuyers: IBuyer[];
	allProducts: any[];
	totalProdPages?: number;
	currentPage: number;
	secondaryTotalPages: number;
	secondaryLoading: boolean;
	buyersLoading: boolean;
	paginatedProductsLoading: boolean;
	selectedCustomAccounts: any[];
	selectedCustomAccountsTotalPages: number;
	selectedCustomAccountsLoading: boolean;
	accountsResults: any[]; // Replace 'any' with the correct type if known
	accountsResultsCurrentPage: number;
	accountsResultsTotalPages: number;
	accountsResultsLoading: boolean;
	accountTypes: string[];
	chains: string[];
	locations: string[];
	setLoading: (loading: boolean) => void;
	setColumns: (columns: string[]) => void;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	setLoadingRows: (loading: boolean) => void;
	setReportingItemsCurrentPage: (page: number) => void;
	setReportingItemsTotalPages: (pages: number) => void;
	setReportingItemsLoading: (loading: boolean) => void;
	setItemLoading: (itemLoading: boolean) => void;
	setLoadingFiltered: (loading: boolean) => void;
	setCurrentFilteredPage: (page: number) => void;
	setLoadingGroupedChildren: (loading: boolean) => void;
	setCurrentGroupedChildrenPage: (page: number) => void;
	addItem: (
		query: IGeneralQueryItem,
		newItem: Omit<Objective | Incentive, "_id" | "customId">
	) => any;
	editItem: (
		query: IGeneralQuery,
		item: Partial<Objective | Incentive>,
		isGrouped?: boolean,
		shouldRemove?: boolean
	) => any;
	addSubItem: (
		query: IGeneralQueryWithCopy,
		newSubItem: Omit<Objective | Incentive, "_id" | "customId">,
		subItemType: string,
		parentItemId?: string
	) => any;
	editSubItem: (
		query: IGeneralQuery,
		item: any,
		subItemType: string,
		suppressSnack: boolean
	) => any;
	setCurrentPage: (page: number) => void;
	setItemsCurrentPage: (page: number) => void;
	setProductsCurrentPage: (page: number) => void;
	setProductsLoading: (productsLoading: boolean) => void;
	setSelectedProductsCurrentPage: (page: number) => void;
	setSelectedProductsLoading: (selectedProductsLoading: boolean) => void;
	setSelectedCustomProductsCurrentPage: (page: number) => void;
	setSelectedCustomProductsLoading: (
		selectedCustomProductsLoading: boolean
	) => void;
	setSelectedAccountsCurrentPage: (page: number) => void;
	setSelectedAccountsLoading: (selectedAccountsLoading: boolean) => void;
	fetchCustomersPaginated: (query: IAccountsPaginated) => void;
	fetchProductsPaginated: (query: IProductsPaginatedQuery) => void;
	fetchSelectedProducts: (query: IProductsPaginatedQuery) => void;
	fetchSelectedAccounts: (query: IAccountsPaginated) => void;
	setSecondaryLoading: (secondaryLoading: boolean) => void;
	setBuyersLoading: (buyersLoading: boolean) => void;
	setPaginatedProductsLoading: (paginatedProductsLoading: boolean) => void;
	updateAccounts: (
		isObjective: boolean,
		query: any,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => void;
	updateProducts: (
		isObjective: boolean,
		query: any,
		newProducts: { productId: string; checked: boolean }[]
	) => void;
	fetchAccounts: (
		query: IQueryReportingAccounts,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => void;
	fetchProducts: (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => void;
	fetchSelectedCustomProducts: (
		query: ISelectedCustomQuery,
		body?: any
	) => void;
	fetchTeamMembers: (query: IGeneralQuery, userId?: string) => void;
	fetchSuperTeamMembers: (query: IGeneralQuery, userId?: string) => void;
	fetchTeamMembersToRemove: (
		query: IGeneralQuery,
		userIds?: string[]
	) => void;
	fetchSuperTeamMembersToRemove: (
		query: IGeneralQuery,
		userIds?: string[]
	) => void;
	fetchSelectedCustomAccounts: (
		query: ISelectedCustomQuery,
		body?: any
	) => void;
	setAccountsResultsCurrentPage: (page: number) => void;
	setAccountsResultsLoading: (loading: boolean) => void;
	fetchAccountsResults: (
		itemId: string,
		userId: string,
		query: any
	) => Promise<void>; // Update query type if known
	fetchTags: (query: IGeneralQuery) => void;
	addTag: (
		newTag: { name: string; color: string },
		query: IGeneralQuery
	) => void;
	fetchItem: (
		query: IGeneralQueryItem,
		itemId?: string,
		isObjective?: boolean
	) => void;
	fetchSemiItem: (
		query: IGeneralQueryItem,
		subItemId?: string,
		isObjective?: boolean
	) => void;
	fetchAccountTypes: (query: IGeneralQuery) => void;
	fetchItems: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchReportingItems: (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => void;
	fetchFilteredItems: (query: IQueryFiltered, callback?: () => void) => void;
	fetchGroupedItems: (
		query: IQueryFavorite,
		itemId: string,
		isObjective: boolean
	) => void;
	addGroupedItems: (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentItemId: string
	) => void;
	deleteItem: (
		query: IGeneralQuery,
		itemId: string,
		itemType: string
	) => void;
	deleteSubItem: (
		query: IGeneralQueryItem,
		subItemId: string,
		subItemType: string
	) => void;
	runQuery: (query: IGeneralQuery, itemId: string, itemType: string) => void;
	resetValues: (
		query: IGeneralQuery,
		itemId: string,
		itemType: string
	) => void;
	fetchReportingRows: (
		itemIds: string[],
		query: IQueryFavoriteWithSearch,
		isLoadMore?: boolean
	) => Promise<void>;
	setRows: (rows: IRow[]) => void;
	selectedItemIds: Set<string>;
	updateUserTargetAndPayout: (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number | string,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		isAccountSpecific: boolean,
		isFullAmount: boolean,
		isObjective: boolean,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		filterType?: string
	) => void;
	rejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => void;
	unRejectImage: (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => void;
	exportItems: (
		query: IGeneralQuery,
		itemIds: string[],
		userEmail: string
	) => void;
}

export const commonObjIncStoreZustand = create<Store>((set, get) => ({
	item: null,
	semiItem: null,
	items: [],
	rows: [],
	columns: [],
	sortColumnName: undefined,
	sortDirection: "asc",
	loadingRows: false,
	hasMoreRows: false,
	rowsCurrentPage: 1,
	rowsTotalPages: 1,
	rowsLimit: 10,
	archivedItems: [],
	reportingItems: [],
	reportingItemsTotalPages: 1,
	reportingItemsCurrentPage: 1,
	reportingItemsLoading: false,
	favoriteItems: [],
	subItems: [],
	filteredItems: [],
	groupedChildren: [],
	loading: false,
	itemLoading: false,
	semiItemLoading: false,
	itemsTotalPages: 1,
	itemsCurrentPage: 1,
	totalFilteredPages: 1,
	currentFilteredPage: 1,
	loadingFiltered: false,
	totalGroupedChildrenPages: 1,
	currentGroupedChildrenPage: 1,
	loadingGroupedChildren: false,
	allBuyers: [],
	superTeamMembers: [],
	superTeamMembersToRemove: [],
	teamMembers: [],
	teamMembersToRemove: [],
	allProducts: [],
	tags: [],
	selectedProducts: [],
	selectedProductsTotalPages: 1,
	selectedProductsLoading: false,
	selectedProductsCurrentPage: 1,
	selectedCustomProducts: [],
	selectedCustomProductsTotalPages: 1,
	selectedCustomProductsLoading: false,
	selectedCustomProductsCurrentPage: 1,
	selectedAccounts: [],
	selectedAccountsTotalPages: 1,
	selectedAccountsLoading: false,
	selectedAccountsCurrentPage: 1,
	productsTotalPages: 1,
	productsCurrentPage: 1,
	productsLoading: false,
	totalProdPages: 1,
	accounts: [],
	products: [],
	currentPage: 1,
	secondaryTotalPages: 1,
	secondaryLoading: false,
	buyersLoading: false,
	paginatedProductsLoading: false,
	selectedCustomAccounts: [],
	selectedCustomAccountsTotalPages: 1,
	selectedCustomAccountsLoading: false,
	accountsResults: [],
	accountsResultsCurrentPage: 1,
	accountsResultsTotalPages: 1,
	accountsResultsLoading: false,
	accountTypes: [],
	chains: [],
	locations: [],
	selectedItemIds: new Set<string>(),
	setLoading: (loading: boolean) => set({ loading }),
	setColumns: (columns: string[]) => set({ columns }),
	setLoadingRows: (loading: boolean) => set({ loadingRows: loading }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setReportingItemsCurrentPage: (page: number) =>
		set({ reportingItemsCurrentPage: page }),
	setReportingItemsTotalPages: (pages: number) =>
		set({ reportingItemsTotalPages: pages }),
	setReportingItemsLoading: (loading: boolean) =>
		set({ reportingItemsLoading: loading }),
	setItemLoading: (itemLoading: boolean) => set({ itemLoading: itemLoading }),
	setLoadingFiltered: (loadingFiltered: boolean) => set({ loadingFiltered }),
	setCurrentFilteredPage: (page: number) =>
		set({ currentFilteredPage: page }),
	setLoadingGroupedChildren: (loadingGroupedChildren: boolean) =>
		set({ loadingGroupedChildren }),
	setCurrentGroupedChildrenPage: (page: number) =>
		set({ currentGroupedChildrenPage: page }),
	setSelectedProductsCurrentPage: (page: number) =>
		set({ selectedProductsCurrentPage: page }),
	setSelectedProductsLoading: (selectedCustomProductsLoading: boolean) =>
		set({ selectedCustomProductsLoading: selectedCustomProductsLoading }),
	setSelectedCustomProductsCurrentPage: (page: number) =>
		set({ selectedCustomProductsCurrentPage: page }),
	setSelectedCustomProductsLoading: (selectedProductsLoading: boolean) =>
		set({ selectedProductsLoading: selectedProductsLoading }),
	setSelectedAccountsCurrentPage: (page: number) =>
		set({ selectedAccountsCurrentPage: page }),
	setSelectedAccountsLoading: (selectedAccountsLoading: boolean) =>
		set({ selectedAccountsLoading: selectedAccountsLoading }),
	setProductsLoading: (productsLoading: boolean) => set({ productsLoading }),
	setProductsCurrentPage: (page: number) =>
		set({ productsCurrentPage: page }),
	setSecondaryLoading: (secondaryLoading: boolean) =>
		set({ secondaryLoading }),
	setBuyersLoading: (buyersLoading: boolean) => set({ buyersLoading }),
	setPaginatedProductsLoading: (paginatedProductsLoading: boolean) =>
		set({ paginatedProductsLoading }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setItemsCurrentPage: (page: number) => set({ itemsCurrentPage: page }),
	setAccountsResultsCurrentPage: (page: number) =>
		set({ accountsResultsCurrentPage: page }),
	setAccountsResultsLoading: (loading: boolean) =>
		set({ accountsResultsLoading: loading }),
	fetchAccountsResults: async (
		itemId: string,
		userId: string,
		query: any
	) => {
		set({ accountsResultsLoading: true });
		try {
			const res = await api.fetch({
				path: `${BASE_PATH_V2}/incentiveObjectiveQueryResults/${itemId}/${userId}`,
				method: "GET",
				query,
			});
			if (res.ok) {
				set({
					accountsResults: res.payload,
					accountsResultsTotalPages: res.meta?.totalPages || 1,
				});
			} else {
				console.error("Error fetching accounts results");
				set({ accountsResults: [] });
			}
		} catch (error) {
			console.error("Error fetching accounts results:", error);
			set({ accountsResults: [] });
		} finally {
			set({ accountsResultsLoading: false });
		}
	},
	fetchCustomersPaginated: async (query: IAccountsPaginated) => {
		set({ buyersLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/buyers`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/buyers`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				allBuyers: res.payload,
				totalPages: res.meta?.totalPages,
			});
			set({ buyersLoading: false });
		} else {
			console.log("error fetching users");
			set({ allBuyers: [] });
			set({ buyersLoading: false });
		}
	},
	fetchProductsPaginated: async (query: IProductsPaginatedQuery) => {
		let res;
		set({ paginatedProductsLoading: true });
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/products`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/products`,
				method: "GET",
				query,
			});
		}
		if (res.ok === true) {
			set({
				allProducts: res.payload.results,
				totalProdPages: res.payload.count,
			});
			set({ paginatedProductsLoading: false });
		} else {
			console.log("error fetching users");
			set({ allProducts: [] });
			set({ paginatedProductsLoading: false });
		}
	},
	fetchSelectedProducts: async (query: IProductsPaginatedQuery) => {
		let res;
		set({ selectedProductsLoading: true });
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/allProducts`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/allProducts`,
				method: "GET",
				query,
			});
		}
		if (res.ok === true) {
			set({
				selectedProducts: res.payload.results,
				selectedProductsTotalPages: res.payload.count,
			});
			set({ selectedProductsLoading: false });
		} else {
			console.log("error fetching users");
			set({ selectedProducts: [] });
			set({ selectedProductsLoading: false });
		}
	},
	fetchSelectedAccounts: async (query: IAccountsPaginated) => {
		set({ selectedAccountsLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/allBuyers`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/allBuyers`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				selectedAccounts: res.payload,
				selectedAccountsTotalPages: res.meta?.totalPages,
			});
			set({ selectedAccountsLoading: false });
		} else {
			console.log("error fetching users");
			set({ selectedAccounts: [] });
			set({ selectedAccountsLoading: false });
		}
	},
	updateAccounts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					accounts: newAccounts,
				},
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					accounts: newAccounts,
				},
			});
		}

		if (res.ok === true) {
			console.log("Accounts updated successfully");
		} else {
			console.log("error fetching users");
		}
	},
	updateProducts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newProducts: { productId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					products: newProducts,
				},
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					products: newProducts,
				},
			});
		}

		if (res.ok === true) {
			console.log("Products updated successfully");
		} else {
			console.log("error fetching users");
		}
	},
	fetchAccountTypes: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/distinctAccountTypes`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ accountTypes: res.payload.accountTypes });
			set({ chains: res.payload.chains });
			set({ locations: res.payload.locations });
		} else {
			console.log("error fetching account types");
			set({ accountTypes: [] });
			set({ chains: [] });
			set({ locations: [] });
		}
	},
	fetchAccounts: async (
		query: IQueryReportingAccounts,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => {
		if (itemId !== "" || itemId !== undefined) {
			set({ secondaryLoading: true });
			let res;
			if (isObjective) {
				res = await api.fetch({
					path: `${BASE_PATH_V2_OBJECTIVE}/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			} else {
				res = await api.fetch({
					path: `${BASE_PATH_V2_INCENTIVE}/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			}

			if (res.ok === true) {
				set({
					accounts: res.payload,
					secondaryTotalPages: res?.meta?.totalPages,
				});
				if (callback) callback();
				set({ secondaryLoading: false });
			} else {
				console.log("error fetching objectives");
				set({ accounts: [] });
				set({ secondaryLoading: false });
			}
		}
	},
	fetchProducts: async (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => {
		if (itemId !== "" || itemId !== undefined) {
			set({ productsLoading: true });
			let res;
			if (isObjective) {
				res = await api.fetch({
					path: `${BASE_PATH_V2_OBJECTIVE}/${itemId}/products/${userId}`,
					method: "GET",
					query,
				});
			} else {
				res = await api.fetch({
					path: `${BASE_PATH_V2_INCENTIVE}/${itemId}/products/${userId}`,
					method: "GET",
					query,
				});
			}

			if (res.ok === true) {
				set({
					products: res.payload,
					productsTotalPages: res?.meta?.totalPages,
				});
				if (callback) callback();
				set({ productsLoading: false });
			} else {
				console.log("error fetching objectives");
				set({ products: [] });
				set({ productsLoading: false });
			}
		}
	},
	fetchSelectedCustomProducts: async (
		query: ISelectedCustomQuery,
		body?: any
	) => {
		set({ selectedCustomProductsLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/selectedCustomProducts`,
				method: "POST",
				query,
				body,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/selectedCustomProducts`,
				method: "POST",
				query,
				body,
			});
		}

		if (res.ok === true) {
			set({
				selectedCustomProducts: res.payload,
				selectedCustomProductsTotalPages: res?.meta?.totalPages,
			});
			set({ selectedCustomProductsLoading: false });
		} else {
			console.log("error fetching objectives");
			set({ selectedCustomProducts: [] });
			set({ selectedCustomProductsLoading: false });
		}
	},
	fetchTeamMembers: async (query: IGeneralQuery, userId?: string) => {
		const res = await api.fetch({
			path: `/teamMembers/${userId}`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({ teamMembers: res.payload });
		} else {
			set({ teamMembers: [] });
		}
	},
	fetchSuperTeamMembers: async (query: IGeneralQuery, userId?: string) => {
		const res = await api.fetch({
			path: `/superTeamMembers/${userId}`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({ superTeamMembers: res.payload });
		} else {
			set({ superTeamMembers: [] });
		}
	},
	fetchTeamMembersToRemove: async (
		query: IGeneralQuery,
		userIds?: string[]
	) => {
		const res = await api.fetch({
			path: `/teamMembersForTeamLeads`,
			method: "POST",
			query,
			body: { customIds: userIds },
		});
		if (res.ok === true) {
			set({ teamMembersToRemove: res.payload });
		} else {
			set({ teamMembersToRemove: [] });
		}
	},
	fetchSuperTeamMembersToRemove: async (
		query: IGeneralQuery,
		userIds?: string[]
	) => {
		const res = await api.fetch({
			path: `/superTeamMembers`,
			method: "POST",
			query,
			body: { customIds: userIds },
		});
		if (res.ok === true) {
			set({ superTeamMembersToRemove: res.payload });
		} else {
			set({ superTeamMembersToRemove: [] });
		}
	},
	fetchSelectedCustomAccounts: async (
		query: ISelectedCustomQuery,
		body?: any
	) => {
		set({ selectedCustomAccountsLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2_OBJECTIVE}/${query.objectiveId}/selectedCustomAccounts`,
				method: "POST",
				query,
				body,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2_INCENTIVE}/${query.incentiveId}/selectedCustomAccounts`,
				method: "POST",
				query,
				body,
			});
		}

		if (res.ok === true) {
			set({
				selectedCustomAccounts: res.payload,
				selectedCustomAccountsTotalPages: res?.meta?.totalPages,
			});
		} else {
			console.log("error fetching selected custom accounts");
			set({ selectedCustomAccounts: [] });
		}
		set({ selectedCustomAccountsLoading: false });
	},
	fetchTags: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/tags`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ tags: res.payload });
		} else {
			console.log("error fetching tags");
			set({ tags: [] });
		}
	},
	addTag: async (
		newTag: { name: string; color: string },
		query: IGeneralQuery
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/tag`,
			method: "POST",
			query,
			body: {
				name: newTag.name,
				color: newTag.color,
			},
		});
		if (res.ok === true) {
			set((state) => ({
				tags: [...state.tags, res.payload],
			}));
			return res.payload;
		} else {
			console.log("Error adding new tag");
		}
	},
	fetchItem: async (query: IGeneralQueryItem, itemId?: string) => {
		set({ itemLoading: true });
		if (itemId !== "" || itemId !== undefined) {
			const res = await api.fetch({
				path: `${BASE_PATH_V3_ITEMS}/${itemId}`,
				method: "GET",
				query,
			});

			if (res.ok === true) {
				set({
					item: res.payload.item,
					subItems: res.payload.subItems,
				});
				set({ itemLoading: false });
			} else {
				console.log("error fetching item");
				set({ item: null, subItems: [] });
				set({ itemLoading: false });
			}
		}
	},
	fetchSemiItem: async (query: IGeneralQueryItem, semiItemId?: string) => {
		set({ semiItemLoading: true });
		if (semiItemId !== "" || semiItemId !== undefined) {
			const res = await api.fetch({
				path: `${BASE_PATH_V3_ITEMS}/${semiItemId}`,
				method: "GET",
				query,
			});

			if (res.ok === true) {
				set({
					semiItem: res.payload.item,
				});
				set({ semiItemLoading: false });
			} else {
				console.log("error fetching item");
				set({ semiItem: null });
				set({ semiItemLoading: false });
			}
		}
	},
	fetchItems: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ loading: true });

		const res = await api.fetch({
			path: BASE_PATH_V3_ITEMS,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({ items: res.payload, itemsTotalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			snack.error("Error fetching items");
			set({ items: [] });
			set({ loading: false });
		}
	},
	fetchFilteredItems: async (
		query: IQueryFiltered,
		callback?: () => void
	) => {
		set({ loadingFiltered: true });
		let res = await api.fetch({
			path: `${BASE_PATH_V3}/filteredItems`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				filteredItems: res.payload,
				totalFilteredPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingFiltered: false });
		} else {
			snack.error("Error fetching filtered items");
			set({ filteredItems: [] });
			set({ loadingFiltered: false });
		}
	},
	fetchGroupedItems: async (
		query: IQueryFavoriteWithSearch,
		objectiveId: string,
		isObjective: boolean
	) => {
		set({ loadingGroupedChildren: true });
		let res;

		if (isObjective) {
			res = await api.fetch({
				path: `${BASE_PATH_V2}/groupedObjectives/${objectiveId}/children`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `${BASE_PATH_V2}/groupedIncentives/${objectiveId}/children`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true && res.payload?.length > 0) {
			set({
				groupedChildren: res.payload,
				totalGroupedChildrenPages: res.meta?.totalPages,
			});
			set({ loadingGroupedChildren: false });
		} else {
			set({ groupedChildren: [] });
			set({ loadingGroupedChildren: false });
		}
	},
	addGroupedItems: async (
		query: IGeneralQuery,
		childIds: { id: string; weight: number }[],
		parentItemId: string
	) => {
		let res = await api.fetch({
			path: `${BASE_PATH_V3_ITEMS}/addGroupedChildren/${parentItemId}`,
			method: "POST",
			query,
			body: { childIds },
		});

		if (res.ok) {
			return res;
		} else {
			snack.error("error adding grouped objectives");
			return [];
		}
	},
	addItem: async (
		query: IGeneralQueryItem,
		newItem: Omit<Objective | Incentive, "_id" | "customId">
	) => {
		let res = await api.fetch({
			path: BASE_PATH_V3_ITEMS,
			method: "POST",
			query,
			body: newItem,
		});
		if (res.ok) {
			set((state) => {
				const updatedItems = [...state.items, res.payload];
				return { items: updatedItems };
			});
			if (query.parentId) {
				snack.success("The item has been successfully copied");
			}
			return res.payload;
		} else {
			snack.error("Error adding item");
		}
	},
	editItem: async (
		query: IGeneralQueryItem,
		item: Partial<Objective | Incentive>,
		isGrouped?: boolean,
		shouldRemove?: boolean
	) => {
		snack.info("Updating...");
		const res = await api.fetch({
			path: `${BASE_PATH_V3_ITEMS}/${item._id}`,
			method: "PATCH",
			query,
			body: item,
		});

		if (res.ok) {
			snack.success("Update completed 👍");
			set((state) => {
				const updatedItems = state.items.map((it) => {
					return it._id === item._id ? res.payload : it;
				});

				if (shouldRemove) {
					return {
						items: updatedItems.filter((it) => it._id !== item._id),
					};
				}

				return { items: updatedItems };
			});
			if (!isGrouped) {
				set({ item: res.payload });
			}
		} else {
			snack.error("Error updating item");
		}
	},
	addSubItem: async (
		query: IGeneralQueryWithCopy,
		newSubItem: Omit<Objective | Incentive, "_id" | "customId">,
		parentItemId?: string
	) => {
		let res = await api.fetch({
			path: `${BASE_PATH_V3_ITEMS}/addSubItem`,
			method: "POST",
			query,
			body: { ...newSubItem, parentItemId },
		});

		if (res.ok) {
			set((state) => {
				const updatedSubItems = [...state.subItems, res.payload];
				return { subItems: updatedSubItems };
			});
			if (query.parentId) {
				snack.success("The subItem has been successfully copied");
			}
			return res.payload;
		} else {
			snack.error("Error adding subItem");
		}
	},
	editSubItem: async (
		query: IGeneralQuery,
		subItem: Partial<Objective | Incentive>,
		subItemType: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info("Updating...");
		}

		const res = await api.fetch({
			path: `${BASE_PATH_V2}/${subItemType}/${subItem._id}`,
			method: "PATCH",
			query,
			body: subItem,
		});
		if (res.ok) {
			set((state) => {
				const updatedSubItems = state.subItems.map((sub) => {
					return sub._id === subItem._id ? res.payload : sub;
				});

				return { subItems: updatedSubItems };
			});
			if (!suppressSnack) {
				snack.success("Update completed 👍");
			}

			return res.payload;
		} else {
			snack.error("Error updating subItem");
		}
	},
	deleteItem: async (
		query: IGeneralQueryItem,
		itemId: string,
		itemType: string
	) => {
		const res = await api.fetch({
			path: `/${itemType}/${itemId}`,
			method: "DELETE",
			query,
		});
		if (res.ok) {
			set((state) => ({
				items: [...state.items.filter((it) => it._id !== itemId)],
			}));
			snack.success("Item deleted successfully");
		} else {
			snack.error("Error deleting item");
		}
	},
	deleteSubItem: async (
		query: IGeneralQueryItem,
		subItemId: string,
		subItemType: string
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/${subItemType}/${subItemId}`,
			method: "DELETE",
			query,
		});
		if (res.ok) {
			set((state) => ({
				subItems: [
					...state.subItems.filter((sub) => sub._id !== subItemId),
				],
			}));
		} else {
			snack.error("Error deleting subItem");
		}
	},
	runQuery: async (
		query: IQueryFavorite,
		itemId: string,
		itemType: string
	) => {
		let res;

		if (itemType === "objective") {
			res = await api.fetch({
				path: `/runQueryForObjective/${itemId}`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/runQueryForIncentive/${itemId}`,
				method: "GET",
				query,
			});
		}

		if (res.ok) {
			set((state) => {
				const newItems = state.items.map((item) =>
					item._id === itemId
						? { ...item, lastRunAt: new Date().toISOString() }
						: item
				);

				return { items: newItems };
			});
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			snack.error("Error");
		}
	},
	resetValues: async (
		query: IQueryFavorite,
		itemId: string,
		itemType: string
	) => {
		let res;

		if (itemType === "objective") {
			res = await api.fetch({
				path: `/resetValuesForObjective/${itemId}`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/resetValuesForIncentive/${itemId}`,
				method: "GET",
				query,
			});
		}

		if (res.ok) {
			snack.success("Action completed 👍", 10000);
			console.log("Action completed 👍", res.payload);
		} else {
			snack.error("Error");
		}
	},
	fetchReportingItems: async (
		query: IQueryFavoriteWithSearch,
		callback?: () => void
	) => {
		set({ reportingItemsLoading: true });

		const res = await api.fetch({
			path: `${BASE_PATH_V3_ITEMS}/allWithSubItemsAndGroupedChildren`,
			method: "GET",
			query,
		});

		if (res.ok === true) {
			set({
				reportingItems: res.payload,
				reportingItemsTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ reportingItemsLoading: false });
		} else {
			snack.error("Error fetching items");
			set({ reportingItems: [] });
			set({ reportingItemsLoading: false });
		}
	},
	fetchReportingRows: async (
		itemIds: string[],
		query: IQueryFavoriteWithSearch,
		isLoadMore: boolean = false
	) => {
		if (!query.isIncentive && !query.isObjective) {
			snack.error("Either isIncentive or isObjective must be specified");
			return;
		}

		set({ loadingRows: true });
		try {
			const res = await api.fetch({
				path: `${BASE_PATH_V3_ITEMS}/reporting`,
				method: "POST",
				query: {
					...query,
				},
				body: { itemIds },
			});

			if (res.ok) {
				set((state) => ({
					rows: isLoadMore
						? [...state.rows, ...res.payload]
						: res.payload,
					hasMoreRows:
						res?.meta?.page && res?.meta?.totalPages
							? res?.meta?.page < res?.meta?.totalPages
							: false,
					rowsCurrentPage: res?.meta?.page,
					rowsTotalPages: res?.meta?.totalPages,
					rowsLimit: res?.meta?.limit,
					selectedItemIds: new Set(itemIds),
				}));
			} else {
				snack.error("Error fetching reporting items");
				set({
					rows: [],
					hasMoreRows: false,
					rowsCurrentPage: 1,
					rowsTotalPages: 1,
					rowsLimit: 10,
				});
			}
		} catch (error) {
			console.error("Error:", error);
			snack.error("Error fetching reporting items");
		} finally {
			set({ loadingRows: false });
		}
	},
	setRows: (rows) => set({ rows }),
	updateUserTargetAndPayout: async (
		query: IQueryFavoriteWithSearch,
		itemId: string,
		userId: string,
		payoutType: string,
		payout: number,
		target: number | string,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		isAccountSpecific: boolean,
		isFullAmount: boolean,
		isObjective: boolean,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[],
		filterType?: string
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/updateUserTargetAndPayout`,
			method: "POST",
			query,
			body: {
				itemId,
				userId,
				payoutType,
				payout,
				target,
				isOpenEnded,
				threshold,
				cap,
				isObjective,
				isEdited: true,
				minQuantityValue,
				isAccountSpecific,
				isFullAmount,
				advancedBudget,
				advancedPayments,
			},
		});

		if (res.ok) {
			const updatedUser = res.payload.users.find(
				(u: any) => u.user === userId
			);
			if (updatedUser) {
				set((state) => ({
					rows: state.rows.map((row) =>
						row.itemId === itemId && row.userId === userId
							? {
									...row,
									potential:
										isOpenEnded || isAccountSpecific
											? "∞"
											: updatedUser.totalPayout,
									payout: payout,
									payoutType: payoutType,
									userTarget: isOpenEnded ? "∞" : target,
									target:
										isOpenEnded || isAccountSpecific
											? "∞"
											: target,
									cap: cap,
									threshold: threshold,
									isAccountSpecific: isAccountSpecific,
									isOpenEnded: isOpenEnded,
									payoutMTD: updatedUser.totalAchieved,
									minQuantityValue: minQuantityValue,
									eligibleQuantity:
										updatedUser.isAccountSpecific
											? updatedUser.eligibleQuantity
											: "-",
									isFullAmount: isFullAmount,
									advancedBudget: advancedBudget,
									advancedPayments: advancedPayments,
									isEdited: true,
									isObjective,
							  }
							: row
					),
				}));
			}
			snack.success("Update completed 👍");
		} else {
			snack.error("Error updating item");
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			updatedState.rows = [...state.rows].sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === "asc" ? 1 : -1;
				} else if (aVal < bVal) {
					return dir === "asc" ? -1 : 1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	rejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string,
		rejectedReason: string
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/item/${itemId}/rejectImage/${imageId}`,
			method: "PATCH",
			query,
			body: { rejectedReason },
		});

		if (res.ok) {
			snack.success("Image was rejected 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const updatedImages = row.images?.filter(
						(image: ObjIncentiveImage) => image._id !== imageId
					);
					const rejectedImage = row.images?.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages = rejectedImage
						? [
								...(row.rejectedImages || []),
								{
									...rejectedImage,
									rejected: true,
									rejectedReason: rejectedReason,
								},
						  ]
						: row.rejectedImages || [];

					if (rejectedImage) {
						return {
							...row,
							images: updatedImages,
							rejectedImages: updatedRejectedImages,
							quantity: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						images: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to reject image 👎");
		}
	},
	unRejectImage: async (
		query: IGeneralQuery,
		itemId: string,
		imageId: string
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V2}/item/${itemId}/unRejectImage/${imageId}`,
			method: "PATCH",
			query,
		});

		if (res.ok) {
			snack.success("Image was restored 👍");

			set(() => {
				const rows = get().rows;
				const updatedRows = rows.map((row) => {
					const unrejectedImage = row.rejectedImages?.find(
						(image: ObjIncentiveImage) => image._id === imageId
					);

					const updatedRejectedImages =
						row.rejectedImages?.filter(
							(image: ObjIncentiveImage) => image._id !== imageId
						) || [];

					const updatedImages = unrejectedImage
						? [
								...(row.images || []),
								{
									...unrejectedImage,
									rejected: false,
									rejectedReason: "",
								},
						  ]
						: row.images || [];

					if (unrejectedImage) {
						return {
							...row,
							images: updatedImages,
							rejectedImages: updatedRejectedImages,
							quantity: res.payload.totalItems,
							status: res.payload.percentageAchieved,
							payoutMTD: res.payload.totalAchieved,
						};
					}

					return {
						...row,
						images: updatedImages,
						rejectedImages: updatedRejectedImages,
					};
				});

				return { rows: updatedRows };
			});
		} else {
			snack.error("Failed to restore image 👎");
		}
	},
	exportItems: async (
		query: IGeneralQuery,
		itemIds: string[],
		userEmail: string
	) => {
		const res = await api.fetch({
			path: `${BASE_PATH_V3_ITEMS}/exportItems`,
			method: "POST",
			query,
			body: { itemIds, userEmail },
		});
		if (res.ok) {
			snack.success("Items exported successfully");
		} else {
			snack.error("Error exporting items");
		}
	},
}));
