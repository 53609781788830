import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../app/store";
import {
  IBuyer,
  IBuyerWithRecommendations,
  ISliceStatus,
  IGpRank,
  IVolumeRank,
} from "../../interfaces";
import { IGeneralQuery } from "../../hooks/useQuery";

interface IState {
  buyersById: {
    [customId: string]: IBuyerWithRecommendations;
  };
  buyersList: string[];
  buyersCount?: number;
  status: ISliceStatus;
}

export type IListType = "sold" | "sell" | "remove";
export const listTypes: IListType[] = ["sold", "sell", "remove"];

const initialState: IState = {
  buyersById: {},
  buyersList: [],
  status: "idle",
};

export const buyersSlice = createSlice({
  name: "buyers",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    buyerFetched: (state, action: PayloadAction<IBuyer>) => {
      const buyer = action.payload;
      state.buyersById[buyer.customId] = {
        ...state.buyersById[buyer.customId],
        ...buyer,
      };
    },
    buyersFetched: (
      state,
      action: PayloadAction<{ results: IBuyer[]; count: number }>
    ) => {
      const buyers = action.payload.results;
      if (!buyers.length) {
        state.status = "empty";
        state.buyersCount = 0;
      } else {
        buyers.forEach((item) => {
          state.buyersById[item.customId] = {
            ...state.buyersById[item.customId],
            ...item,
          };
        });
        state.status = "ready";
        state.buyersCount = action.payload.count;
      }
      state.buyersList = buyers.map((buyer) => buyer.customId);
    },
    recommendedProductsFetched: (
      state,
      action: PayloadAction<Partial<IBuyerWithRecommendations>>
    ) => {
      const buyer = action.payload;
      state.buyersById[buyer.customId!] = {
        ...state.buyersById[buyer.customId!],
        ...buyer,
      };
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const {
  statusChanged,
  buyersFetched,
  buyerFetched,
  recommendedProductsFetched,
} = buyersSlice.actions;

interface IGetBuyersQuery {
  salesRep?: string;
  sortBy?: string;
  searchPhrase?: string;
  page?: number;
  wholesalerId?: string;
  preview?: string;
  chain?: string;
  location?: string;
}

export const getBuyers = (query: IGetBuyersQuery): AppThunk => async (
  dispatch,
  getState
) => {
  const isIdle = getState().buyers.status === "idle";
  if (!isIdle) {
    dispatch(statusChanged("loading"));
  }
  const res = await api.fetch({
    path: "/buyers",
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(buyersFetched(res.payload));
  }
};

export const getBuyer = (
  buyerId: string,
  query: IGeneralQuery
): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: `/buyer/${encodeURIComponent(buyerId)}`,
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(buyerFetched(res.payload));
  }
};

interface IGetRecommendedProductsQuery extends IGeneralQuery {
  brand?: string[];
  brandFams?: string[],
  gpRank?: IGpRank[];
  packageType?: string;
  size?: string[];
  supplier?: string[];
  tags?: string[];
  volumeRank?: IVolumeRank[];
  productsSortBy?: number;
}

export const getRecommendedProducts = (
  buyerId: string,
  listType: IListType,
  query?: IGetRecommendedProductsQuery
): AppThunk => async (dispatch) => {
  const res = await api.fetch({
    path: `/recommendedProducts/${encodeURIComponent(buyerId)}/${listType}`,
    method: "GET",
    query,
  });
  if (res.ok) {
    dispatch(
      recommendedProductsFetched({
        customId: buyerId,
        [listType]: res.payload,
      })
    );
  }
};

export default buyersSlice.reducer;
