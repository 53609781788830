import { useEffect } from "react";
import useQuery, { IBoostQuery } from "../../../hooks/useQuery";
import { Boost } from "../types/boostTypes";
import { boostStore } from "../store/boostStore";
import { CustomObjIncProductFilters } from "../../usersManagement/types/commonObjIncTypes";

const useBoostSelectedCustomProducts = ({
	page,
	boost,
	selectedProductsFilters,
	searchPhrase,
}: {
	page: number;
	boost: Boost;
	selectedProductsFilters: CustomObjIncProductFilters;
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IBoostQuery>();
	const { wholesalerId } = query;

	const selectedCustomLoading = boostStore(
		(state) => state.selectedCustomProductsLoading
	);

	const selectedCustomProducts = boostStore(
		(state) => state.selectedCustomProducts
	);

	useEffect(() => {
		const extendedFilters = {
			...selectedProductsFilters,
			searchPhrase,
			wholesalerId,
		};
		boostStore.getState().fetchSelectedCustomProducts(
			{
				wholesalerId,
				page,
				searchPhrase,
			},
			boost._id,
			{ ...extendedFilters, searchPhrase }
		);
	}, [page, boost, selectedProductsFilters, searchPhrase, wholesalerId]);

	return {
		selectedCustomLoading,
		selectedCustomProducts,
		selectedCustomProductsTotalPages:
			boostStore.getState().selectedCustomProductsTotalPages,
	};
};

export default useBoostSelectedCustomProducts;
