import { Button } from "@material-ui/core";
import PrintIcon from "@mui/icons-material/Print";
import * as XLSX from "xlsx";
import { mapImagesDataForExport, formatImagesDataToRows } from "./utils";

interface ExportImagesXLSXProps {
    rows?: any[];
    rowTypes: any[];
}

export const ExportImagesXLSX = ({ rows, rowTypes }: ExportImagesXLSXProps) => {
    const includeQuantities = rowTypes.includes("imageValidation");
    const imagesData = mapImagesDataForExport(rows, includeQuantities);
    
    const xlsxData = formatImagesDataToRows(imagesData || [], includeQuantities);
    const handleExportXLSX = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(xlsxData);
        XLSX.utils.book_append_sheet(wb, ws, "Images");

        XLSX.writeFile(wb, "images_data.xlsx");
    };

    return (
        <Button
            size="small"
            variant="outlined"
            startIcon={<PrintIcon />}
            onClick={handleExportXLSX}
            style={{
                width: "100%",
                color: "#3D4EA1",
                backgroundColor: "#e8eaf6",
                borderColor: "#D0D5ED",
            }}
        >
            Images XLSX
        </Button>
    );
};
