import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./SubItemCard.styles";

interface SubItemCardProps {
	subItem: any;
	handleSubSelect: (subItem: any, event?: React.MouseEvent<Element>) => void;
	isSelected?: boolean;
}

const SubItemCard: React.FC<SubItemCardProps> = ({
	subItem,
	handleSubSelect,
	isSelected,
}) => {
	const classes = useStyles();

	return (
		<Box
			key={subItem._id}
			className={classes.subItemBox}
			onClick={(event) => handleSubSelect(subItem, event)}
			style={{
				backgroundColor: isSelected ? "#e8eaf6" : "#fff",
				padding: "8px",
				borderRadius: "4px",
				marginBottom: "4px",
				cursor: "pointer",
			}}
		>
			<Typography variant="subtitle2">Name: {subItem.name}</Typography>
			<Typography variant="body2">Type: {subItem.type}</Typography>
		</Box>
	);
};

export default SubItemCard;
