import React, { useState } from "react";
import {
	Box,
	FormControl,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	styled,
	TextField,
} from "@mui/material";
import { Search, WidgetsSharp } from "@mui/icons-material";
import moment from "moment";
import useTags from "../../usersManagement/hooks/useTags";
import { AttachEmail, LocalOffer } from "@material-ui/icons";
import { Button, Menu, Typography } from "@material-ui/core";
import { ExportCSVButton } from "../utils/ExportCSVButton";
import { ExportXLSButton } from "../utils/ExportXLSXButton";
import { ExportImagesCSV } from "../utils/ExportImagesCSV";
import { ExportImagesXLSX } from "../utils/ExportImagesXLSX";
import { useStyles } from "./FiltersSection.styles";
import { calculateTableTotals } from "../utils/utils";
import useFetchTags from "../../usersManagement/hooks/useFetchTags";
import useExportItems from "../hooks/useExportItems";
import { EmailModal } from "./EmailModal";

interface FilterSectionProps {
	filterType?: string;
	onTypeChange?: (event: any) => void;
	selectedTag?: string;
	onTagChange?: (event: any) => void;
	periodStart?: any;
	periodEnd?: any;
	onPeriodStartChange?: (event: any) => void;
	onPeriodEndChange?: (event: any) => void;
	userSearch?: string;
	onSearchUserChange?: (event: any) => void;
	columns?: string[];
	rows?: any[];
	itemType: string;
	toggleShowAllColumns?: () => void;
	showAllColumns?: boolean;
	handleClearFilters?: () => void;
	selectedItemsIds?: string[];
}

const FilterContainer = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2.5),
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(2),
}));

const FilterBox = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: theme.spacing(2),
}));

export const FilterSection: React.FC<FilterSectionProps> = ({
	filterType,
	onTypeChange,
	selectedTag,
	onTagChange,
	periodStart,
	periodEnd,
	onPeriodStartChange,
	onPeriodEndChange,
	userSearch,
	onSearchUserChange,
	columns,
	rows,
	itemType,
	toggleShowAllColumns,
	showAllColumns,
	handleClearFilters,
	selectedItemsIds,
}) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [userEmail, setUserEmail] = useState<string>('');

	const { exportItems } = useExportItems({
		itemIds: selectedItemsIds || [],
		userEmail: userEmail,
		isObjective: itemType === 'objective'
	});

	const handleEmailChange = (email: string) => {
		setUserEmail(email);
	};

	const handleEmailSubmit = async (email: string) => {
		try {
			setUserEmail(email);
			await exportItems();
			setIsEmailModalOpen(false);
			setAnchorEl(null);
		} catch (error) {
			console.error('Export failed:', error);
		}
	};
	

	const uniqueRowTypes = Array.from(new Set(rows?.map((row) => row.type)));

	const filterOptions = [
		{ label: "Binary", value: "binary" },
		{ label: "Image", value: "image" },
		{ label: "Quantity", value: "quantity" },
		{ label: "Multi", value: "multi" },
		{ label: "Validation", value: "validation" },
		{ label: "Grouped", value: "grouped" },
		{ label: "Image Validation", value: "imageValidation" },
	];

	useFetchTags();
	const { tags } = useTags();

	const tagOptions = tags.map((tag) => ({
		value: tag._id,
		label: tag.name,
	}));

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<FilterContainer elevation={2} className={classes.container}>
			<Box className={classes.filterBox}>
				<Box className={classes.box}>
					<TextField
						placeholder="Search user..."
						size="small"
						variant="outlined"
						value={userSearch}
						onChange={onSearchUserChange}
						InputProps={{
							startAdornment: <Search style={{ color: "gray" }} />,
							style: {
								borderRadius: "8px",
								backgroundColor: "white",
								width: "417px",
							},
						}}
					/>
					<Button
						onClick={toggleShowAllColumns}
						variant="outlined"
						className={classes.button}
					>
						{showAllColumns ? (
							<Typography className={classes.typography}>
								Hide Columns
							</Typography>
						) : (
							<Typography className={classes.typography}>
								Show All Columns
							</Typography>
						)}
					</Button>
				</Box>
			</Box>
			<FilterBox sx={{ flexWrap: "wrap" }}>
				<FormControl>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={filterType || ""}
						onChange={onTypeChange}
						placeholder="Select a type"
						displayEmpty
						size="small"
						renderValue={(selected) => {
							if (!selected) {
								return (
									<Typography className={classes.grayTypography}>
										Type
									</Typography>
								);
							}
							return filterOptions.find((option) => option.value === selected)
								?.label;
						}}
						startAdornment={
							<InputAdornment position="start">
								<WidgetsSharp />
							</InputAdornment>
						}
						sx={{
							width: "200px",
							"& .MuiSelect-select": {
								paddingRight: "32px !important",
							},
						}}
					>
						<MenuItem value="">
							<em>Show all</em>
						</MenuItem>
						{filterOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={selectedTag || ""}
						onChange={onTagChange}
						displayEmpty
						size="small"
						renderValue={(selected) => {
							if (!selected) {
								return (
									<Typography className={classes.grayTypography}>
										Tag
									</Typography>
								);
							}
							return tagOptions.find((option) => option.value === selected)
								?.label;
						}}
						startAdornment={
							<InputAdornment position="start">
								<LocalOffer />
							</InputAdornment>
						}
						sx={{
							width: "200px",
							"& .MuiSelect-select": {
								paddingRight: "32px !important",
							},
						}}
					>
						<MenuItem value="">
							<em>Show all</em>
						</MenuItem>
						{tagOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Box className={classes.datePicker}>
					<TextField
						label="Start Period"
						type="date"
						value={periodStart ? moment(periodStart).format("YYYY-MM-DD") : ""}
						onChange={onPeriodStartChange}
						variant="outlined"
						size="small"
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							style: { color: "gray" },
						}}
					/>
					<TextField
						label="End Period"
						type="date"
						value={periodEnd ? moment(periodEnd).format("YYYY-MM-DD") : ""}
						onChange={onPeriodEndChange}
						variant="outlined"
						size="small"
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							style: { color: "gray" },
						}}
					/>
					<Button
						variant="outlined"
						onClick={handleClearFilters}
						className={classes.button}
					>
						<Typography className={classes.typography}>
							Clear Filters
						</Typography>
					</Button>
				</Box>
				<Box className={classes.exportButton}>
					<Button
						aria-controls={anchorEl ? "export-menu" : undefined}
						aria-haspopup="true"
						onClick={handleClick}
						variant="outlined"
						className={classes.button}
					>
						Export
					</Button>
					<Menu
						id="export-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
						PaperProps={{
							style: {
								marginLeft: "-50px",
							},
						}}
					>
						<MenuItem onClick={handleClose}>
							<Button
								startIcon={<AttachEmail />}
								aria-controls={anchorEl ? "export-menu" : undefined}
								aria-haspopup="true"
								onClick={() => setIsEmailModalOpen(true)}
								size="small"
								variant="outlined"
								className={classes.button}
								style={{
									width: "100%",
									color: "#3D4EA1",
									backgroundColor: "#e8eaf6",
									borderColor: "#D0D5ED",
								}}
							>
								Email
							</Button>
						</MenuItem>
						{(uniqueRowTypes.includes("image") ||
							uniqueRowTypes.includes("imageValidation")) && (
							<>
								<MenuItem onClick={handleClose}>
									<ExportImagesCSV rows={rows} rowTypes={uniqueRowTypes} />
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<ExportImagesXLSX rows={rows} rowTypes={uniqueRowTypes} />
								</MenuItem>
							</>
						)}
						<MenuItem onClick={handleClose}>
							<ExportCSVButton
								columns={columns}
								rows={rows}
								totals={calculateTableTotals(rows || [], itemType)}
							/>
						</MenuItem>
						<MenuItem onClick={handleClose}>
							<ExportXLSButton
								columns={columns}
								rows={rows}
								totals={calculateTableTotals(rows || [], itemType)}
							/>
						</MenuItem>
					</Menu>
				</Box>
				<EmailModal
					open={isEmailModalOpen}
					onClose={() => setIsEmailModalOpen(false)}
					onSubmit={handleEmailSubmit}
					onEmailChange={handleEmailChange}
					email={userEmail}
				/>
			</FilterBox>
		</FilterContainer>
	);
};
