import { Box, TextField } from "@material-ui/core";
import { FormLabel } from "@material-ui/core";
import { useStyles } from "./EditBoost.styles";
import Select from "react-select";
import { Boost } from "../types/boostTypes";

interface BoostComponentProps {
	boost: Boost;
	handleChange: (field: string, value: string) => void;
	onTypeChange: (selectedOption: any) => void;
}

export const BoostComponent = ({ boost, handleChange, onTypeChange }: BoostComponentProps) => {
	const classes = useStyles();

	const filterOptions = [
		{ label: "GP/CE", value: "GP/CE" },
		{ label: "GP/Unit", value: "GP/Unit" },
	];


	return (
		<Box className={classes.boostContainer}>
			<FormLabel className={classes.supplierContribution}>
				Boost: 
			</FormLabel>
			<TextField
				margin="dense"
				id="boost"
				variant="outlined"
				type="number"
				fullWidth
				name="boost"
				value={boost.boost}
				onChange={(event) =>
					handleChange("boost", event.target.value)
				}
				className={classes.textField}
			/>
			<Select
				value={filterOptions.find(option => option.value === boost.type)}
				options={filterOptions}
				onChange={onTypeChange}
				placeholder="Select a type"
				styles={{
					menuPortal: (provided) => ({
						...provided,
						zIndex: 9999,
					}),
					control: (provided) => ({
						...provided,
						width: "200px",
						cursor: "pointer",
					}),
				}}
				menuPortalTarget={document.body}
				className={classes.select}
			/>
		</Box>
	);
};