	import React, { useState } from 'react';
	import {
		Dialog,
		DialogTitle,
		DialogContent,
		DialogActions,
		TextField,
		Button,
	} from '@material-ui/core';
import { useStyles } from './FiltersSection.styles';

	interface EmailModalProps {
		open: boolean;
		onClose: () => void;
		onSubmit: (email: string) => void;
		email: string;
		onEmailChange: (email: string) => void;
	}

	export const EmailModal: React.FC<EmailModalProps> = ({ open, onClose, onSubmit, email, onEmailChange }) => {
		const classes = useStyles();

		const [error, setError] = useState('');

		const handleSubmit = () => {
			if (!email) {
				setError('Email is required');
				return;
			}
			if (!/\S+@\S+\.\S+/.test(email)) {
				setError('Please enter a valid email');
				return;
			}
			onSubmit(email);
		};


		return (
			<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
				<DialogTitle className={classes.dialogTitle}>Export Items</DialogTitle>
				<DialogContent className={classes.modalContent}>
					<TextField
						autoFocus
						margin="dense"
						label="Email Address"
						type="email"
						fullWidth
						value={email}
						onChange={(e) => {
							onEmailChange(e.target.value);
						}}
						error={!!error}
						helperText={error}
					/>
				</DialogContent>
				<DialogActions className={classes.modalActions}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Send
					</Button>
				</DialogActions>
			</Dialog>
		);
	};