import { useEffect, useState } from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";
import { debounce } from "lodash";


const useReportingItems = ({
	searchPhrase,
	type,
	tag,
	periodStart,
	periodEnd,
	isObjective,
	isArchived,
}: {
	searchPhrase?: string;
	type?: string;
	tag?: string;
	periodStart?: any;
	periodEnd?: any;
	isObjective?: boolean;
	isArchived?: boolean;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	const { wholesalerId } = query;

	const [pageSize, setPageSize] = useState(10);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const reportingItems = commonObjIncStoreZustand((state) => state.reportingItems);

	const currentPage = commonObjIncStoreZustand(
		(state) => state.reportingItemsCurrentPage
	);
	const setCurrentPage = commonObjIncStoreZustand(
		(state) => state.setReportingItemsCurrentPage
	);
	const totalPages = commonObjIncStoreZustand(
		(state) => state.reportingItemsTotalPages
	);
	const reportingItemsLoading = commonObjIncStoreZustand(
		(state) => state.reportingItemsLoading
	);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		if (
			searchPhrase ||
			type ||
			tag ||
			periodStart ||
			periodEnd ||
			isObjective ||
			isArchived ||
			isObjective
		) {
			setCurrentPage(1);
		}
	}, [
		searchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
		isObjective,
		isArchived,
		setCurrentPage,
	]);
	
	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchReportingItems({
			wholesalerId,
			page: currentPage,
			limit: pageSize,
			searchPhrase: debouncedSearchPhrase,
			type,
			tag,
			periodStart,
			periodEnd,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
			...(isArchived !== undefined ? { isArchived } : {}),
		});
	}, [
		wholesalerId,
		currentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		tag,
		periodStart,
		periodEnd,
		isObjective,
		isArchived,
	]);
	
	return {
		reportingItems,
		currentPage,
		loading: reportingItemsLoading,
		totalPages,
		setCurrentPage,
		setPageSize,
	};

};

export default useReportingItems;