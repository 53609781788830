import { Dialog, TextField, DialogContent, DialogTitle, Button, DialogActions } from "@material-ui/core";
import { useStyles } from "./BoostTable.styles";
import { Boost, initialBoostState } from "./types/boostTypes";
import { useState } from "react";
interface AddBoostModalProps {
	open: boolean;
	onAdd: (data: Omit<Boost, "_id">) => Promise<Boost | undefined>;
	onClose: () => void;
}
export const AddBoostModal = ({ open, onAdd, onClose }: AddBoostModalProps) => {
	const [boost, setBoost] = useState<Omit<Boost, "_id">>(initialBoostState);
	
	const handleChange = (
		event: React.ChangeEvent<{ name?: string; value: unknown }>
	) => {
		const name = event.target.name as keyof Boost;
		setBoost((prev) => ({
			...prev,
			[name]: event.target.value,
		}));
	};

	const handleSubmit = () => {
		onAdd(boost);
		onClose();
	};
	
	const classes = useStyles();
	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.addModal}>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>New Boost</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					type="text"
					fullWidth
					name="name"
					value={boost.name}
					onChange={handleChange}
				/>
			</DialogContent>
			<DialogActions className={classes.buttonContainer}>
				<Button
					onClick={handleSubmit}
					className={`${classes.button} ${classes.addButton}`}
				>
					Add
				</Button>
				<Button
					onClick={onClose}
					color="default"
					className={`${classes.button} ${classes.cancelButton}`}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};
