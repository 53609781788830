import { CircularProgress, FormLabel, TextField } from "@mui/material";
import { Box } from "@mui/material";
import useFetchCostEstimate from "../hooks/useFetchCostEstimate";
import { CustomProductsSummary } from "../types/boostTypes";

export const TotalCostEstimate = ({
	boostId,
	selectedProductsFilters,
	boost,
}: {
	boostId: string;
	selectedProductsFilters: CustomProductsSummary;
	boost?: number;
}) => {
	const { costEstimateData, costEstimateLoading } = useFetchCostEstimate(boostId, selectedProductsFilters);

	return (
		<Box sx={{ maxWidth: '90%'}}>
			<FormLabel sx={{ fontSize: '1rem', color: 'text.primary', display: 'block', marginBottom: 3 }}>
				Total Cost Estimate:
			</FormLabel>
			<Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: 2, alignItems: 'center' }}>
				{/* Header */}
				<Box></Box>
				<FormLabel>Volume</FormLabel>
				<FormLabel>Cost</FormLabel>

				<FormLabel >Same period LY:</FormLabel>
				<Box sx={{ bgcolor: 'grey.100', padding: 1, borderRadius: 1 }}>
					{costEstimateLoading ? <CircularProgress size={16} /> : costEstimateData?.totalVolumeLastYear}
				</Box>
				<Box sx={{ bgcolor: 'grey.100', padding: 1, borderRadius: 1 }}>
					{costEstimateLoading ? <CircularProgress size={16} /> : `$${((costEstimateData?.totalVolumeLastYear || 0) * (boost || 0)).toFixed(2)}`}
				</Box>

				<FormLabel >Rolling 30:</FormLabel>
				<Box sx={{ bgcolor: 'grey.100', padding: 1, borderRadius: 1 }}>-</Box>
				<Box sx={{ bgcolor: 'grey.100', padding: 1, borderRadius: 1 }}>-</Box>

				<FormLabel>Other (optional):</FormLabel>
				<TextField
					variant="outlined"
					size="small"
					placeholder="-"
				/>
				<Box sx={{ bgcolor: 'grey.100', padding: 1, borderRadius: 1 }}>-</Box>
			</Box>
		</Box>
	);
};
