import { useState } from "react";
import { ItemsManagement } from "./ItemsManagement";

interface ItemsReportingTabProps {
	type: "objective" | "incentive";
}

export const ItemsReportingTab = ({ type }: ItemsReportingTabProps) => {
	const [isArchived, setIsArchived] = useState(false);

	return (
		<ItemsManagement
			type={type}
			isArchived={isArchived}
			setIsArchived={setIsArchived}
		/>
	);
};
