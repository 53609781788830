import { IBoostQuery } from "../../../hooks/useQuery";

import useQuery from "../../../hooks/useQuery";
import { boostStore } from "../store/boostStore";
import { Boost } from "../types/boostTypes";

const useHandleBoostUpdate = () => {
	const { query } = useQuery<IBoostQuery>();

	const { wholesalerId } = query;

	const addBoost = async (newBoost: Omit<Boost, "_id">, parentId?: string) => {
		const dynamicQuery: IBoostQuery = { wholesalerId, parentId };

		const boost = await boostStore
			.getState()
			.addBoost(dynamicQuery, { ...newBoost });

		return boost;
	};

	const editBoost = (boostId: string, updatedFields: Partial<Boost>) => {
		const dynamicQuery: IBoostQuery = { wholesalerId };

		boostStore
			.getState()
			.editBoost(dynamicQuery, { _id: boostId, ...updatedFields });
	};

	const deleteBoost = (boostId: string) => {
		const dynamicQuery: IBoostQuery = { wholesalerId };

		boostStore.getState().deleteBoost(dynamicQuery, boostId);
	};

	return {
		addBoost,
		editBoost,
		deleteBoost,
	};
};

export default useHandleBoostUpdate;
