import { boostStore } from "../store/boostStore";

const useProductsFilters = () => {
	const usedProductsFilters = boostStore((state) => state.usedProductsFilters);
	const distinctProducts = boostStore((state) => ({
		suppliers:
			state.productsTypes?.productSuppliers
				?.map((supplier) => supplier.title)
				.sort((a, b) => a.localeCompare(b)) || [],
		brands: [
			...new Set(
				state.productsTypes?.productSuppliers?.flatMap(
					(supplier) => supplier.brands
				) || []
			),
		].sort((a, b) => a.localeCompare(b)),
		packageTypes: state.productsTypes?.productPackageTypes || [],
		sizes: state.productsTypes?.productSizes || [],
		productTypes: state.productsTypes?.productTags || [],
		brandFams: state.productsTypes?.brandFams || [],
	}));

	return { distinctProducts, usedProductsFilters };
};

export default useProductsFilters;
