import { IBoostQuery} from "../../../hooks/useQuery";

import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { boostStore } from "../store/boostStore";
import { CustomProductsSummary } from "../types/boostTypes";

const useFetchCostEstimate = (boostId: string, selectedProductsFilters: CustomProductsSummary) => {
	const { query } = useQuery<IBoostQuery>();
	const { wholesalerId } = query;

	const costEstimateData = boostStore((state) => state.costEstimateData);
	const costEstimateLoading = boostStore((state) => state.costEstimateLoading);

	useEffect(() => {
		const extendedFilters = { ...selectedProductsFilters, wholesalerId };

		boostStore.getState().fetchCostEstimateData({ wholesalerId }, boostId, extendedFilters);
	}, [wholesalerId, boostId, selectedProductsFilters]);

	return { costEstimateData, costEstimateLoading };
};

export default useFetchCostEstimate;
