import { useEffect, useState } from "react";
import { IGeneralQuery } from "../../../hooks/useQuery";
import useQuery from "../../../hooks/useQuery";
import { boostStore } from "../store/boostStore";
import { debounce } from "lodash";

const useBoosts = ({
	searchPhrase,
	type,
	periodStart,
	periodEnd,
}: {
	searchPhrase?: string;
	type?: string;
	periodStart?: any;
	periodEnd?: any;
}) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const [pageSize, setPageSize] = useState(15);
	const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState<
		string | undefined
	>(searchPhrase);

	const boosts = boostStore((state) => state.boosts);
	const loading = boostStore((state) => state.loading);
	const boostsTotalPages = boostStore((state) => state.boostsTotalPages);
	const currentPage = boostStore((state) => state.boostsCurrentPage);
	const setCurrentPage = boostStore((state) => state.setBoostsCurrentPage);

	useEffect(() => {
		const handler = debounce(() => {
			setDebouncedSearchPhrase(searchPhrase);
		}, 300);

		handler();

		return () => {
			handler.cancel();
		};
	}, [searchPhrase]);

	useEffect(() => {
		boostStore.getState().fetchBoosts({
			wholesalerId,
			page: currentPage,
			limit: pageSize,
			searchPhrase: debouncedSearchPhrase,
			type,
			periodStart,
			periodEnd,
		});

		boostStore.getState().fetchUsedProductsFilters({
			wholesalerId,
		});
	}, [
		wholesalerId,
		currentPage,
		pageSize,
		debouncedSearchPhrase,
		type,
		periodStart,
		periodEnd,
	]);

	return {
		boosts,
		loading,
		boostsTotalPages,
		currentPage,
		setCurrentPage,
		setPageSize,
	};
};

export default useBoosts;
