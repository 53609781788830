import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	card: {
		border: "1px solid #e0e0e0",
		borderRadius: "4px",
		padding: "5px 10px 5px 10px",
		cursor: "pointer",
		width: "100%",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.08)",
		margin: "0px 0px 10px 0px",
	},
	mainBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		wordBreak: "break-word",
	},
	expandedContent: {
		padding: "10px",
		borderTop: "1px solid #ccc",
	},
	expandedText: {
		marginTop: "10px",
		marginBottom: "10px",
	},
	title: {
		fontWeight: "bold",
		fontSize: "15px",
		marginBottom: "5px",
	},
	subItemBox: {
		border: "1px solid #ccc",
		borderRadius: "8px",
		padding: "10px",
		marginTop: "10px",
		overflowX: "auto",
		whiteSpace: "nowrap",
	},
	subItemContainer: {
		maxHeight: "350px",
		overflowY: "auto",
	},
	groupedItem: {
		display: 'flex',
		alignItems: 'center',
		fontStyle: 'italic',
		fontSize: '12px',
		marginLeft: '5px',
	},
});
