import { useState } from "react";
import useBoosts from "./hooks/useBoosts";
import useFetchProductsFilters from "./hooks/useFetchProductsFilters";
import useHandleBoostUpdate from "./hooks/useHandleBoostUpdate";
import { Boost } from "./types/boostTypes";
import moment from "moment";
import { BoostComponent } from "./BoostComponent";

export const BoostView = () => {
	useFetchProductsFilters();

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedType, setSelectedType] = useState<string | undefined>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);

	const { boosts, loading, boostsTotalPages, currentPage, setCurrentPage } = useBoosts({
		searchPhrase: searchTerm,
		type: selectedType,
		periodStart,
		periodEnd,
	});

	const { addBoost, editBoost, deleteBoost } = useHandleBoostUpdate();

	const handleAddBoost = async (
		newBoostData: Omit<Boost, "_id" | "customId">, 
		parentId?: string
	) => {
		return await addBoost(newBoostData as Omit<Boost, "_id" | "customId">, parentId);
	};

	const handleDelete = (id: string) => {
		deleteBoost(id);
	};

	const handleDuplicate = (id: string) => {
		const boost = boosts.find((boost) => boost._id === id);
		if (boost) {
			const {
				_id: id,
				...restOfBoost
			} = boost;
			const newName = `${boost.name} copy`;

			handleAddBoost({
				...restOfBoost,
				name: newName,
			}, id);
		}
	};

	const handleEditBoost = (id: string, updatedBoost: Partial<Boost>) => {
		editBoost(id, updatedBoost);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
	};

	const handleClearFilters = () => {
		setSearchTerm("");
		setSelectedType("");
		setPeriodStart(null);
		setPeriodEnd(null);
	};


	return(
		<BoostComponent
			boosts={boosts}
			loading={loading}
			totalPages={boostsTotalPages}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			handleClearFilters={handleClearFilters}
			onAdd={handleAddBoost}
			onEdit={handleEditBoost}
			onDelete={handleDelete}
			onDuplicate={handleDuplicate}
			searchTerm={searchTerm}
			onSearchChange={handleSearchChange}
			selectedType={selectedType}
			onTypeChange={handleTypeChange}
			periodStart={periodStart}
			onPeriodStartChange={handlePeriodStartChange}
			periodEnd={periodEnd}
			onPeriodEndChange={handlePeriodEndChange}
		/>
	)
}