import { useEffect, useMemo, useState } from "react";
import { IBoostProductsQuery } from "../../../hooks/useQuery";
import useQuery from "../../../hooks/useQuery";
import { ManualProductsFilters } from "../../usersManagement/types/commonObjIncTypes";
import { boostStore } from "../store/boostStore";
import { debounce } from "lodash";

const useBoostSelectedProducts = ({
	page,
	boostId,
	filters,
}: {
	page: number;
	boostId: string;
	filters: ManualProductsFilters;
}) => {
	const { query } = useQuery<IBoostProductsQuery>();
	const { wholesalerId } = query;

	const selectedProducts = boostStore((state) => state.selectedProducts);
	const selectedProductsLoading = boostStore((state) => state.selectedProductsLoading);

	const [debouncedFilters, setDebouncedFilters] = useState<ManualProductsFilters>(filters);

	const debouncedUpdateFilters = useMemo(
		() =>
			debounce((updatedFilters: ManualProductsFilters) => {
				setDebouncedFilters(updatedFilters);
			}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdateFilters(filters);
	}, [filters, debouncedUpdateFilters]);

	const updateProducts = (
		newProducts: { productId: string; checked: boolean }[]
	) => {
		boostStore
			.getState()
			.updateProducts({ boostId, wholesalerId }, newProducts);
	};

	useEffect(() => {
		const extendedFilters = { ...debouncedFilters, boostId };

		boostStore.getState().fetchSelectedProducts({
			page,
			wholesalerId,
			boostId,
			query: extendedFilters,
		});
	}, [page, debouncedFilters, wholesalerId, boostId]);

	return {
		selectedProducts,
		selectedProductsLoading,
		selectedProductsTotalPages:
			boostStore.getState().selectedProductsTotalPages,
		updateProducts,
	};
};

export default useBoostSelectedProducts;
