import { useMemo } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";

export interface TeamLead {
	name: string;
	customId: string;
}

export interface UserSelection {
	leadership: boolean;
	name: string;
	customId: string;
	role: string;
	teamLead: TeamLead[];
}

export interface UserGroups {
	Leadership: UserSelection[];
	TeamLead: UserSelection[];
	SalesRep: UserSelection[];
}

const useUsers = () => {
	const users = objectivesStoreZustand((state) => state.users);

	const groupedUsers = useMemo(() => {
		const groups: UserGroups = { Leadership: [], TeamLead: [], SalesRep: [] };
		users.forEach((user) => {
			if (user.leadership)
				groups.Leadership.push({
					name: user.name,
					customId: user.customId,
					leadership: user.leadership,
					role: user.role,
					teamLead: user.teamLead.map((teamLead) => ({
						name: teamLead.name,
						customId: teamLead.customId,
					})),
				});
			else if (user.role === "teamLead")
				groups.TeamLead.push({
					name: user.name,
					customId: user.customId,
					leadership: user.leadership,
					role: user.role,
					teamLead: user.teamLead.map((teamLead) => ({
						name: teamLead.name,
						customId: teamLead.customId,
					})),
				});
			else if (user.role === "salesRep")
				groups.SalesRep.push({
					name: user.name,
					customId: user.customId,
					leadership: user.leadership,
					role: user.role,
					teamLead: user.teamLead.map((teamLead) => ({
						name: teamLead.name,
						customId: teamLead.customId,
					})),
				});
		});
		return groups;
	}, [users]);
	// Function to create a map of team leads to their team members
	const createTeamMap = (
		users: UserSelection[]
	): Map<string, UserSelection[]> => {
		const teamMap = new Map<string, UserSelection[]>();

		users.forEach((user) => {
			user.teamLead.forEach((lead) => {
				if (!teamMap.has(lead.customId)) {
					teamMap.set(lead.customId, []);
				}
				teamMap.get(lead.customId)?.push(user);
			});
		});

		return teamMap;
	};

	// Function to filter leadership members
	const filterLeadership = (users: UserSelection[]): UserSelection[] => {
		return users.filter((user) => user.leadership);
	};

	const selectUserAndTeam = (
		userId: string,
		teamMap: Map<string, UserSelection[]>,
		selectedUsers: Set<string> = new Set()
	): Set<string> => {
		if (selectedUsers.has(userId)) {
			return selectedUsers;
		}

		selectedUsers.add(userId);

		const teamMembers = teamMap.get(userId) || [];
		teamMembers.forEach((member) => {
			selectUserAndTeam(member.customId, teamMap, selectedUsers);
		});

		return selectedUsers;
	};

	return {
		users,
		groupedUsers,
		createTeamMap,
		filterLeadership,
		selectUserAndTeam,
	};
};

export default useUsers;
