import useQuery, { IGeneralQueryItem } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Incentive } from "../types/incentivesTypes";
import { Objective } from "../types/objectiveTypes";

const useHandleSubItemUpdate = ({ isObjective, subItemType }: { isObjective: boolean, subItemType: string }) => {
	const { query } = useQuery<IGeneralQueryItem>();

	const { wholesalerId } = query;

	const addSubItem = (
		newSubItem: Omit<Objective | Incentive, "_id">,
		parentItemId: string,
		parentId?: string,
	) => {
		const dynamicQuery: IGeneralQueryItem = {
			wholesalerId,
			parentId,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
		};

		const subItem = commonObjIncStoreZustand
			.getState()
			.addSubItem(dynamicQuery, { ...newSubItem, parentId }, parentItemId);
		return subItem;
	};

	const editSubItem = (
		subItemId: string,
		updatedFields: Partial<Objective | Incentive>,
		suppressSnack: boolean
	) => {
		commonObjIncStoreZustand
			.getState()
			.editSubItem(
				query,
				{ _id: subItemId, ...updatedFields },
				subItemType,
				suppressSnack,
			);
	};

	const deleteSubItem = (subItemId: string, subItemType: string) => {
		commonObjIncStoreZustand.getState().deleteSubItem(query, subItemId, subItemType)
	}

	return {
		addSubItem,
		editSubItem,
		deleteSubItem
	};
};

export default useHandleSubItemUpdate;
