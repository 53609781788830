export type IGpRank = "Low" | "Med" | "High" | "Very High" | "Super High";
export const gpRanks: IGpRank[] = [
	"Low",
	"Med",
	"High",
	"Very High",
	"Super High",
];
export type IVolumeRank = "Low" | "Med" | "High";
export const volumeRanks: IVolumeRank[] = ["Low", "Med", "High"];
export type ITrend = "up" | "down" | "no change";
export const trends: ITrend[] = ["up", "down", "no change"];

export interface IProduct {
	name: string;
	supplier: string;
	brand: string;
	size: string;
	volumeRank: IVolumeRank;
	gpRank: IGpRank;
	packageType: string;
	totalVolume: number;
	totalGp: number;
	lastYearTotalVolume: number;
	lastYearTotalGP: number;
	lastYearGP_CE: number;
	trend: ITrend;
	premise: string;
	customId: string;
	gp_ce: number;
	boost?: number;
	// the following data is not delivered to client for performance reasons
	// wholesaler: {
	//   customId: string
	//   title: string
	// }
	// buyers: {
	//   buyerId: string
	//   totalGp: number
	//   totalVolume: number
	//   CE28Days: number
	//   CE56Days: number
	//   gp_ce: number
	//   accountType: string
	//  totalGp30Days: number
	//  totalVolume30Days: number
	//  gp_ce30Days: number
	// }[]
	// salesReps: {
	//   customId: string
	//   totalGp: number
	//   totalVolume: number
	//   gp_ce: number
	// }[]
	sponsored: {
		isSponsored: boolean;
		isExcluded: boolean;
	};
	tags: string[];
	updatedAt: Date;
	gp: {
		thisYear: number;
		lastYear: number;
		lastYearMTD: number;
		delta: number;
		deltaAbs: number;
		margin: number;
	};
	volume: {
		thisYear: number;
		lastYear: number;
		lastYearMTD: number;
		delta: number;
		deltaAbs: number;
	};
	gpCe: {
		thisYear: number;
		lastYear: number;
		lastYearMTD: number;
		delta: number;
		deltaAbs: number;
	};
	revenue: {
		thisYear: number;
		lastYear: number;
		lastYearMTD: number;
		delta: number;
		deltaAbs: number;
	};
}
