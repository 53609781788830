import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";

const useProductsFilters = () => {

	const distinctProducts = objectivesStoreZustand((state) => ({
		suppliers: state.products?.productSuppliers?.map(supplier => supplier.title).sort((a, b) => a.localeCompare(b)) || [],
		brands: [...new Set(state.products?.productSuppliers?.flatMap(supplier => supplier.brands) || [])].sort((a, b) => a.localeCompare(b)),
		packageTypes: state.products?.productPackageTypes || [],
		sizes: state.products?.productSizes || [],
		productTypes: state.products?.productTags || [],
		brandFams: state.products?.brandFams || []
	}));

	return { distinctProducts };
};

export default useProductsFilters;
