import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";

const useAddTag = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const addTag = (name: string, color: string) => {
		const tag = commonObjIncStoreZustand
			.getState()
			.addTag({ name, color }, { wholesalerId });
		return tag;
	};

	return { addTag };
};

export default useAddTag;
