import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";

const useExportItems = ({
	itemIds,
	userEmail,
	isObjective,
}: {
	itemIds: string[];
	userEmail: string;
	isObjective: boolean;
}) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const exportItems = async () => {
		const dynamicQuery: IGeneralQuery = {
			wholesalerId,
			...(isObjective ? { isObjective: true } : { isIncentive: true }),
		};

		commonObjIncStoreZustand
			.getState()
			.exportItems(dynamicQuery, itemIds, userEmail);
	};

	return { exportItems };
};

export default useExportItems;
