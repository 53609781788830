import { useEffect, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Paper,
	styled,
	TextField,
	Typography,
	Tooltip,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { ItemCard } from "../utils/ItemCard";
import { useStyles } from "./ItemsList.styles";
import { Search } from "@material-ui/icons";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";

export interface SelectAllState {
	[page: number]: boolean;
}

export interface ItemListProps {
	itemType: string;
	loading: boolean;
	items?: any[];
	currentPage?: number;
	setCurrentPage?: (page: number) => void;
	totalPages?: number;
	onItemSelect?: (selectedItemsIds: string[]) => void;
	selectedItemsIds?: string[];
	onFilterChange?: () => void;
	showArchived: boolean;
	setShowArchived: (value: boolean) => void;
	searchTerm?: string;
	onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ListContainer = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	height: "99%",
	display: "flex",
	flexDirection: "column",
	maxWidth: "270px",
	minWidth: "270px",
}));

const ItemsContainer = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	overflowY: "scroll",
	"&::-webkit-scrollbar": {
		display: "none",
	},
	marginTop: theme.spacing(2),
	marginBottom: theme.spacing(2),
}));

export const ItemsList = ({
	itemType,
	items,
	currentPage,
	totalPages,
	loading,
	setCurrentPage,
	onItemSelect,
	selectedItemsIds,
	showArchived,
	setShowArchived,
	searchTerm,
	onSearchChange,
}: ItemListProps) => {
	const classes = useStyles();
	const [allSelectedOnPage, setAllSelectedOnPage] = useState(false);

	useEffect(() => {
		if (items) {
			const allItemIds = items.flatMap(item =>
				item.item.type === 'grouped'
					? [item.item._id, ...item.groupedChildren.map((child: any) => child._id)]
					: [] 
			);
			const selectedIds = selectedItemsIds || [];
			setAllSelectedOnPage(allItemIds.length > 0 && allItemIds.every(id => selectedIds.includes(id)));
		}
	}, [items, selectedItemsIds, currentPage]); 




	const handleItemSelect = (itemId: string) => {
		const newSelectedItemsIds = selectedItemsIds?.includes(itemId)
			? selectedItemsIds.filter(id => id !== itemId)
			: [...(selectedItemsIds || []), itemId];

		if (onItemSelect) {
			onItemSelect(newSelectedItemsIds);
		}
	};

	const handleSelectAll = () => {
		if (items) {
			const allItemIds = items.flatMap(item => 
				item.item.type === 'multi' 
					? item.subItems.map((subItem: any) => subItem._id) 
					: item.item.type === 'grouped' 
						? [item.item._id, ...item.groupedChildren.map((child: any) => child._id)]
						: [item.item._id]
			);
			const newSelectedItemsIds = allItemIds.every(id => selectedItemsIds?.includes(id))
				? selectedItemsIds?.filter(id => !allItemIds.includes(id))
				: Array.from(new Set([...selectedItemsIds || [], ...allItemIds]));
			if (onItemSelect && newSelectedItemsIds) {
				onItemSelect(newSelectedItemsIds);
			}
		}
	};

	return (
		<ListContainer elevation={2}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography
					variant="h6"
					style={{
						marginRight: "8px",
						color: "black",
						width: "6rem",
					}}
				>
					{showArchived ? "Archived" : itemType}
				</Typography>
				<Box style={{ display: "flex", alignItems: "center" }}>
					<Tooltip title={showArchived ? "Show Active Items" : "Show Archived Items"} arrow>
						<Button
							onClick={() => setShowArchived(!showArchived)}
							style={{
								color: "black",
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								padding: "2px 4px", 
								minWidth: "auto",
								height: "24px",
								fontSize: "0.875rem",
							}}
						>
							<SwapVertIcon />
						</Button>
					</Tooltip>
					<Tooltip title={allSelectedOnPage ? "Deselect All" : "Select All"} arrow>
						<Button
							onClick={handleSelectAll}
							style={{
								color: "black",
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								padding: "2px 4px", 
								minWidth: "auto",
								height: "24px",
								fontSize: "0.875rem",
							}}
						>
							{allSelectedOnPage ? <RemoveDoneIcon /> : <DoneAllIcon />}
						</Button>
					</Tooltip>
				</Box>
			</Box>

			<Box
				style={{
					borderBottom: "1px solid #e0e0e0",
					marginTop: "18px",
				}}
			>
				<TextField
					placeholder="Search items..."
					size="small"
					variant="outlined"
					value={searchTerm}
					onChange={onSearchChange}
					className={classes.searchField}
					InputProps={{
						startAdornment: <Search style={{ color: "gray" }} />,
						style: {
							borderRadius: "8px",
							backgroundColor: "white",
						},
					}}
				/>
			</Box>

			<ItemsContainer>
				{loading ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<CircularProgress />
					</Box>
				) : items?.length === 0 ? (
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="100%"
					>
						<Typography variant="body1" color="textSecondary">
							No items available.
						</Typography>
					</Box>
				) : (
					items?.map((item) => (
						<ItemCard
							key={item.item?._id}
							item={item.item}
							handleItemSelect={handleItemSelect}
							subItems={item.subItems}
							groupedChildren={item.groupedChildren}
							selectedItemsIds={selectedItemsIds}
						/>
					))
				)}
			</ItemsContainer>

			{!loading && (
				<Box display="flex" justifyContent="center" mt={1}>
					<Pagination
						count={totalPages ?? 0}
						page={currentPage ?? 1}
						onChange={(_, value) => setCurrentPage?.(value)}
						color="primary"
						size="small"
					/>
				</Box>
			)}
		</ListContainer>
	);
};
