import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	List,
	ListItem,
	ListItemText,
	Typography,
	TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import useAccountsItem from "../hooks/useAccountsItem";
import { useStyles } from "./AccountsProductsList.styles";

interface AccountsListProps {
	itemId: string;
	userId: string;
	isObjective: boolean;
	userType: string;
}

export default function AccountsList({
	itemId,
	userId,
	isObjective,
	userType,
}: AccountsListProps) {
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const classes = useStyles();

	const {
		accounts,
		secondaryLoading,
		currentPage,
		setCurrentPage,
		secondaryTotalPages,
		fetchAccounts,
	} = useAccountsItem(itemId, userId, isObjective, userType === "teamLead", searchTerm);

	const handleClickOpen = () => {
		setOpen(true);
		fetchAccounts();
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentPage(1);
	};

	const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
		setCurrentPage(value);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = event.target.value;
		setSearchTerm(newSearchTerm);
		setCurrentPage(1)
	};

	useEffect(() => {
		if (open) {
			fetchAccounts();
		}
	}, [currentPage, fetchAccounts, open]);

	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
				className={classes.center}
			>
				See All
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle className={classes.center}>All Accounts</DialogTitle>
				<TextField
					label="Search by name"
					variant="outlined"
					value={searchTerm}
					onChange={handleSearchChange}
					className={classes.searchField}
				/>
				<DialogContent className={classes.dialogContent}>
					{secondaryLoading ? (
						<Typography className={classes.centerText}>Loading...</Typography>
					) : (
						<>
							{accounts.length === 0 ? (
								<Typography className={classes.centerText}>
									No account selected that belongs to this User
								</Typography>
							) : (
								<>
									<List className={classes.list}>
										{accounts.map((buyer) => (
											<ListItem key={buyer._id} className={classes.listItem}>
												<ListItemText primary={`Name: ${buyer.name}`} />
											</ListItem>
										))}
									</List>
								</>
							)}
						</>
					)}
				</DialogContent>
				<Pagination
					className={classes.pagination}
					count={secondaryTotalPages}
					page={currentPage}
					onChange={handleChangePage}
					color="primary"
				/>
				<DialogActions className={classes.center}>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
