import { useEffect } from "react";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";
import { incColumns, objColumns } from "../utils/types";

const useColumns = ({
	type,
}: {
	type?: string;
}) => {

	const { columns, setColumns } = commonObjIncStoreZustand();

	useEffect(() => {
		if (type === "objective") {
			setColumns(objColumns);
		} else {
			setColumns(incColumns);
		}
	}, [setColumns, type]);
	
	return {
		columns,
		setColumns,
	};
};

export default useColumns;
