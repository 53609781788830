
export type Boost = {
	_id: string;
	name: string;
	description: string;
	type?: string;
	boost?: number;
	supplierContribution?: number;
	startDate?: string;
	endDate?: string;
	customProductsSelected?: boolean;
	productsFilters?: CustomProductsSummary;
};

export const initialBoostState: Omit<Boost, "_id"> = {
	name: "",
	description: "",
	type: "GP/CE",
	boost: 0,
	supplierContribution: 0,
	startDate: new Date().toISOString(),
	endDate: new Date().toISOString(),
	customProductsSelected: true,
	productsFilters: {
		supplier: [],
		brand: [],
		packageType: [],
		size: [],
		productType: [],
		brandFams: [],
	},
};

export type CustomProductsSummary = {
	supplier: string[];
	brand: string[];
	packageType: string[];
	size: string[];
	productType: string[];
	brandFams: string[];
};
