import { useEffect, useMemo } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { IUser } from "../../../interfaces";

interface UserGroups {
	Leadership: IUser[];
	TeamLead: IUser[];
	SalesRep: IUser[];
}

const useFetchUsers = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	useEffect(() => {
		objectivesStoreZustand.getState().fetchAllUsers({ wholesalerId });
	}, [wholesalerId]);

};

export default useFetchUsers;
