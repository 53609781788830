import { useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import React from "react";

interface IProps {
	setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
	props.setUseSnackbarRef(useSnackbar());
	return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
	useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
	return (
		<InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
	);
};

const snack = {
	success(msg: string, duration?: number) {
		this.toast(msg, "success", duration);
	},
	warning(msg: string, duration?: number) {
		this.toast(msg, "warning", duration);
	},
	info(msg: string, duration?: number) {
		this.toast(msg, "info", duration);
	},
	error(msg: string, duration?: number) {
		this.toast(msg, "error", duration);
	},
	toast(
		msg: string,
		variant: VariantType = "default",
		duration: number = 3000
	) {
		useSnackbarRef.enqueueSnackbar(msg, {
			variant,
			anchorOrigin: { horizontal: "right", vertical: "top" },
			autoHideDuration: duration,
		});
	},
};

export default snack;
