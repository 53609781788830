import { useState } from "react";
import { ManualProductsFilters } from "../../../usersManagement/types/commonObjIncTypes";
import useProductsFilters from "../../hooks/useProductsFilters";
import { Boost } from "../../types/boostTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { Box, Button, FormControlLabel, Switch, TextField, Typography } from "@material-ui/core";
import { SelectionControls } from "../../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/selectComponent/SelectionControls";
import { MultiSelect, SingleSelect } from "../../../select/Select";
import { gpRanks, trends, volumeRanks } from "../../../../interfaces/IProduct";
import BoostSelectedProducts from "./BoostSelectedProducts";
import BoostAllProducts from "./BoostAllProducts";
import { useStyles } from "./ProductsSelection.styles";

interface BoostManualProductsProps {
	boost: Boost;
}

export default function BoostManualProducts({
	boost,
}: BoostManualProductsProps) {
	const classes = useStyles();

	const { distinctProducts } = useProductsFilters();

	const [filters, setFilters] = useState<ManualProductsFilters>({});
	const [isReseted, setIsReseted] = useState<boolean>(false);
	const [showSelected, setShowSelected] = useState<boolean>(false);
	const [allSelected, setAllSelected] = useState<boolean>(false);
	const [clearSelection, setClearSelection] = useState<boolean>(false);

	const isAdmin = useSelector(
		(state: RootState) => state.session.role === "admin"
	);

	const handleShowSelectedChange = () => {
		setShowSelected((prev) => !prev);
		setAllSelected(false);
	};

	const handleSearchChange = (event: any) => {
		setFilters({ ...filters, searchPhrase: event.target.value });
	};

	const onSelect =
		(filterName: keyof ManualProductsFilters) => (selected: any) => {
			setFilters({
				...filters,
				[filterName]: selected,
			});
		};

	const handleReset = () => {
		setFilters({});
		setIsReseted(true);
	};

	const handleSelectAll = () => {
		setAllSelected(true);
		setClearSelection(false);
	};

	const handleClearSelection = () => {
		setClearSelection(true);
		setAllSelected(false);
	};

	return (
		<div className={classes.manualContainer}>
			<Box className={classes.flexColumn}>
				<div className={classes.flexColumn}>
					<SelectionControls
						onSelectAll={handleSelectAll}
						onClear={handleClearSelection}
					/>
					<TextField
						className={classes.inputWrapper}
						label="Search by title"
						size="small"
						variant="outlined"
						defaultValue={filters.searchPhrase}
						onChange={handleSearchChange}
					/>
					<FormControlLabel
						control={
							<Switch
								checked={showSelected}
								onChange={handleShowSelectedChange}
								color="primary"
							/>
						}
						label="Show Selected"
					/>
				</div>
				<div>
					<Typography variant="body1" className={classes.text}>
						Filters:
					</Typography>
					<div className={classes.filters}>
						<MultiSelect
							label="Include Brands"
							options={distinctProducts.brands}
							selected={filters.brand}
							onSelect={onSelect("brand")}
						/>
						<MultiSelect
							label="Exclude Brands"
							options={distinctProducts.brands}
							selected={filters.noBrand}
							onSelect={onSelect("noBrand")}
						/>
						<MultiSelect
							label="GP Rank"
							options={gpRanks}
							selected={filters.gpRank}
							onSelect={onSelect("gpRank")}
						/>
						<SingleSelect
							label="Package Type"
							options={distinctProducts.packageTypes}
							defaultValue={filters.packageType}
							onSelect={onSelect("packageType")}
							isReseted={isReseted}
						/>
						<MultiSelect
							label="Size"
							options={distinctProducts.sizes}
							selected={filters.size}
							onSelect={onSelect("size")}
						/>
						<MultiSelect
							label="Include Supplier Families"
							options={distinctProducts.suppliers}
							selected={filters.supplier}
							onSelect={onSelect("supplier")}
						/>
						<MultiSelect
							label="Exclude Supplier Families"
							options={distinctProducts.suppliers}
							selected={filters.noSupplier}
							onSelect={onSelect("noSupplier")}
						/>
						<MultiSelect
							label="Tags"
							options={distinctProducts.productTypes}
							selected={filters.tags}
							onSelect={onSelect("tags")}
						/>
						<SingleSelect
							label="Trend"
							options={trends}
							defaultValue={filters.trend}
							onSelect={onSelect("trend")}
							isReseted={isReseted}
						/>
						<MultiSelect
							label="Brand Family"
							options={distinctProducts.brandFams}
							selected={filters.brandFams}
							onSelect={onSelect("brandFams")}
						/>
						<MultiSelect
							label="Volume Rank"
							options={volumeRanks}
							selected={filters.volumeRank}
							onSelect={onSelect("volumeRank")}
						/>
						<MultiSelect
							label="Brand Family"
							options={distinctProducts.brandFams}
							selected={filters.brandFams}
							onSelect={onSelect("brandFams")}
						/>
						{isAdmin && (
							<>
								<SingleSelect
									label="Sponsored"
									options={[
										{ label: "Yes", key: "true" },
										{ label: "No", key: "false" },
									]}
									defaultValue={filters.sponsored}
									onSelect={onSelect("sponsored")}
									isReseted={isReseted}
								/>
								<SingleSelect
									label="Excluded"
									options={[
										{ label: "Yes", key: "true" },
										{ label: "No", key: "false" },
									]}
									defaultValue={filters.excluded}
									onSelect={onSelect("excluded")}
									isReseted={isReseted}
								/>
							</>
						)}

						<Button className={classes.button} onClick={handleReset}>
							Reset Filters
						</Button>
					</div>
				</div>
			</Box>
			{showSelected ? (
				<BoostSelectedProducts
					boostId={boost._id}
					filters={filters}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			) : (
				<BoostAllProducts
					boostId={boost._id}
					filters={filters}
					allSelected={allSelected}
					clearSelection={clearSelection}
					setAllSelected={setAllSelected}
					setClearSelection={setClearSelection}
				/>
			)}
		</div>
	)
}

