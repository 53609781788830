import { Accordion, AccordionDetails, AccordionSummary, Button, DialogContent, FormLabel, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import { NameDescriptionPeriod } from "./editBoostComponents/NameDescriptionPeriod";
import { DividerFormControl } from "../usersManagement/common/DividerFormControl";
import { useStyles } from "./editBoostComponents/EditBoost.styles";
import { BoostComponent } from "./editBoostComponents/BoostComponent";
import { TotalCostEstimate } from "./editBoostComponents/TotalCostEstimate";
import { CustomObjIncProductFilters } from "../usersManagement/types/commonObjIncTypes";
import { ExpandMore } from "@material-ui/icons";
import { Boost } from "./types/boostTypes";
import SelectionTabs from "../usersManagement/objectivesIncentivesComponent/editObjIncComponents/accountsProductsUsersSelect/SelectionTabs";
import { BoostCustomProducts } from "./editBoostComponents/products/BoostCustomProducts";
import BoostManualProducts from "./editBoostComponents/products/BoostManualProducts";

interface BoostEditModalProps {	
	initialBoost: Boost;
	onSave: (updatedBoost: Boost) => void;
	onClose: () => void;
}

export const BoostEditModal = ({ initialBoost, onSave, onClose }: BoostEditModalProps) => {
	const methods = useForm();
	const classes = useStyles();

	const [selectionType, setSelectionType] = useState<boolean>(
		initialBoost.customProductsSelected ?? true
	);

	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>(
			initialBoost.productsFilters ?? {
				supplier: [],
				brand: [],
				packageType: [],
				size: [],
				productType: [],
				brandFams: [],
			}
		);

	const [boost, setBoost] = useState<Boost>(initialBoost);

	const handleChange = (name: string, value: string | number) => {
		setBoost((prev: any) => {
			const updatedItem = { ...prev, [name]: value };
			return updatedItem;
		});
	};

	const handleBoostSubmit = () => {
		const updatedBoost = {
			...boost,
			productsFilters: selectedProductsFilters,
			customProductsSelected: selectionType,
			supplierContribution: Number(boost.supplierContribution),
		};
		onSave(updatedBoost);
		onClose();
	};

	const handleTypeChange = (selectedOption: any) => {
		setBoost((prev: any) => ({
			...prev,
			type: selectedOption.value,
		}));
	};

	return (
		<FormProvider {...methods}>
			<DialogContent>
				<Typography align="center" variant="h6">
					Boost
				</Typography>
				<NameDescriptionPeriod
					initialBoost={boost}
					handleChange={handleChange}
				/>
				<DividerFormControl>
					<div className={classes.accordionsContainer}>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1-content"
								id="panel1-header"
							>
								<Typography>Products:</Typography>
							</AccordionSummary>
							<AccordionDetails className={classes.flexColumn}>
								<SelectionTabs
									selectionType={selectionType}
									setSelectionType={setSelectionType}
								/>
								{selectionType && (
									<BoostCustomProducts
										selectedProductsFilters={
											selectedProductsFilters
										}
										setSelectedProductsFilters={
											setSelectedProductsFilters
										}
										boost={boost}
									/>
								)}
								{!selectionType && (
									<BoostManualProducts boost={boost} />
								)}
							</AccordionDetails>
						</Accordion>
					</div>
				</DividerFormControl>
				<DividerFormControl>
					<FormLabel className={classes.supplierContribution}>
						Supplier Contribution: %
					</FormLabel>
					<TextField
						margin="dense"
						id="supplierContribution"
						variant="outlined"
						type="number"
						fullWidth
						name="supplierContribution"
						value={boost.supplierContribution}
						onChange={(event) =>
							handleChange(
								"supplierContribution",
								event.target.value
							)
						}
						className={classes.textField}
					/>
				</DividerFormControl>
				<DividerFormControl>
					<BoostComponent
						boost={boost}
						handleChange={handleChange}
						onTypeChange={handleTypeChange}
					/>
				</DividerFormControl>
				<DividerFormControl>
					<TotalCostEstimate boostId={boost._id} selectedProductsFilters={selectedProductsFilters} boost={boost.boost}/>
				</DividerFormControl>
			</DialogContent>
			<div className={classes.buttonsWrapper}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					type="submit"
					onClick={methods.handleSubmit(handleBoostSubmit)}
					color="primary"
				>
					Save
				</Button>
			</div>
		</FormProvider>
	);
};