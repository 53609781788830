type IRow = (string | number)[];
export default function exportCsv(filename: string, rows: IRow[]) {
	const processRow = (row: IRow) => {
		return (
			row
				.map((value) => {
					let innerValue =
						value === null || value === undefined ? "" : value?.toString();
					// Uncomment and adjust if you need to handle Date objects
					// if (value instanceof Date) {
					//     innerValue = value.toLocaleString();
					// }
					let result = innerValue.replace(/"/g, '""');
					if (result.search(/("|,|\n)/g) >= 0) {
						result = `"${result}"`;
					}
					return result;
				})
				.join(",") + "\n"
		);
	};

	var csvFile = "";
	for (var i = 0; i < rows.length; i++) {
		csvFile += processRow(rows[i]);
	}

	const fileNameWithExtension = `${filename}.csv`;
	var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, fileNameWithExtension);
	} else {
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", fileNameWithExtension);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}
