import { FormControl, FormLabel, TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import { useStyles } from "./EditBoost.styles";
import { DateRange } from "react-date-range";
import { useFormContext } from "react-hook-form";
import { Boost } from "../types/boostTypes";

interface NameDescriptionPeriodProps {
	initialBoost: Boost;
	handleChange: (name: string, value: string | number) => void;
}

const extractDate = (dateTime: string): string => {
	const parts = dateTime.split("T");

	return parts[0];
};

export const NameDescriptionPeriod = ({ initialBoost, handleChange }: NameDescriptionPeriodProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();

	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: initialBoost.startDate
				? moment(extractDate(initialBoost.startDate)).toDate() 
				: moment().toDate(),
			endDate: initialBoost.endDate
				? moment(extractDate(initialBoost.endDate)).toDate() 
				: moment().toDate(), 
			key: "selection",
		},
	]);

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format("YYYY-MM-DD");
		const endInGMT = moment(endDate).format("YYYY-MM-DD");

		if (startDate && endDate) {
			handleChange("startDate", startInGMT);
			handleChange("endDate", endInGMT);
		}
	};

	return (
		<Box className={classes.nameDescriptionPeriod}>
			<Box>
			<TextField
				size="small"
				error={!!errors.name}
				helperText={errors.name && errors.name.message}
				label="Name"
				name="name"
				variant="outlined"
				fullWidth
				defaultValue={initialBoost.name}
				inputRef={register({
					required: "Name is required",
				})}
				onChange={(event) => handleChange("name", event.target.value)}
			/>
			<TextField
				margin="dense"
				id="description"
				label="Description"
				variant="outlined"
				multiline
				minRows={7}
				maxRows={15}
				fullWidth
				name="description"
				value={initialBoost.description}
					onChange={(event) => handleChange("description", event.target.value)}
				/>
			</Box>
			<Box>
				<FormControl
					component="fieldset"
				>
					<FormLabel component="legend">Period</FormLabel>
					<div>
						<DateRange
							editableDateInputs={true}
							onChange={(item) => {
								handlePeriodChange(item);
							}}
							moveRangeOnFirstSelection={false}
							ranges={periodDates}
						/>
					</div>
				</FormControl>
			</Box>
		</Box>
	)
}
