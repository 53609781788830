import { useEffect} from "react";
import useQuery, { IQueryFavoriteWithSearch } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../store/commonObjIncStore";
import { useStore } from "zustand";

const useReportingRows = ({
	itemsIds,
	itemType
}: {
	itemsIds: string[];
	itemType: string;
}) => {
	const { query } = useQuery<IQueryFavoriteWithSearch>();
	

	const rows = useStore(commonObjIncStoreZustand, (state) => state.rows);
	const loadingRows = useStore(
		commonObjIncStoreZustand,
		(state) => state.loadingRows
	);


    useEffect(() => {
		commonObjIncStoreZustand.getState().fetchReportingRows(itemsIds, {
			...query,
			...(itemType === "incentive"
				? { isIncentive: true }
				: { isObjective: true }),
		});
	}, [itemsIds, query, itemType]);
	
	return {
		rows,
		loadingRows,
	};
};

export default useReportingRows;
