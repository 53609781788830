import { useState } from "react";
import { useStyles } from "./BoostTable.styles";
import { Boost } from "./types/boostTypes";
import useBoost from "./hooks/useBoost";
import { Box, Button, TextField, Typography, Dialog, CircularProgress } from "@material-ui/core";
import moment from "moment";
import { AddBoostModal } from "./AddBoostModal";
import { BoostEditModal } from "./BoostEditModal";
import { BoostsList } from "./BoostsList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Select from "react-select";


const filterOptions = [
	{ label: "GP/CE", value: "GP/CE" },
	{ label: "GP/Unit", value: "GP/Unit" },
];

interface BoostComponentProps {
	boosts: Boost[];
	loading: boolean;
	totalPages?: number;
	currentPage?: number;
	setCurrentPage: (page: number) => void;
	handleClearFilters?: () => void;
	searchTerm?: string;
	onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	periodStart?: any;
	periodEnd?: any;
	onPeriodStartChange?: (event: any) => void;
	onPeriodEndChange?: (event: any) => void;
	selectedType?: string;
	onTypeChange?: (event: any) => void;
	onAdd: (
		data: Omit<Boost, "_id">
	) => Promise<Boost | undefined>;
	onEdit: (id: string, data: Partial<Boost>) => void;
	onDelete: (id: string) => void;
	onDuplicate: (id: string) => void;
}

export const BoostComponent = ({
	boosts,
	loading,
	totalPages,
	currentPage,
	setCurrentPage,
	handleClearFilters,
	searchTerm,
	onSearchChange,
	periodStart,
	periodEnd,
	onPeriodStartChange,
	onPeriodEndChange,
	selectedType,
	onTypeChange,
	onAdd,
	onEdit,
	onDelete,
	onDuplicate,
}: BoostComponentProps) => {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editableBoostId, setEditableBoostId] = useState<string | undefined>(
		undefined
	);

	const { boost, boostLoading } = useBoost({
		boostId: editableBoostId,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleSaveEditBoost = (boost: Boost) => {
		setOpenEditModal(false);
		onEdit(boost._id, boost);
		setEditableBoostId(undefined);
	};

	const handleEditBoost = (id: string) => {
		if (openEditModal) {
			setOpenEditModal(false);
			setEditableBoostId(undefined);
		}
		if (id) {
			setEditableBoostId(id);
			setOpenEditModal(true);
		}
	};

	const handleAddBoost = async (newBoostData: Omit<Boost, "_id">) => {
		const newBoost = await onAdd(newBoostData);
		if (newBoost && newBoost._id) {
			setEditableBoostId(newBoost._id);
			setOpenEditModal(true);
		}
		return newBoost;
	};

	const handleCloseModal = () => {
		setOpenEditModal(false);
		setEditableBoostId(undefined);
	};

	return (
		<Box>
			<Box>
				<Box className={classes.filterContainer}>
					<Button
						color="primary"
						startIcon={<AddCircleOutlineIcon />}
						onClick={handleClickOpen}
					>
						New Boost
					</Button>
					<div>
						<TextField
							label="Search"
							value={searchTerm}
							onChange={onSearchChange}
							variant="outlined"
							size="small"
						/>
					</div>

					<Select
						value={
							selectedType
								? {
										value: selectedType,
										label: filterOptions.find(
											(option) =>
												option.value === selectedType
										)?.label,
								  }
								: null
						}
						options={filterOptions}
						onChange={onTypeChange}
						placeholder="Select a type"
						styles={{
							menuPortal: (provided) => ({
								...provided,
								zIndex: 9999,
							}),
							control: (provided) => ({
								...provided,
								width: "200px",
								cursor: "pointer",
							}),
						}}
						menuPortalTarget={document.body}
					/>
					<div className={classes.datePickerContainer}>
						<TextField
							label="Start Period"
							type="date"
							value={
								periodStart
									? moment(periodStart).format("YYYY-MM-DD")
									: ""
							}
							onChange={onPeriodStartChange}
							variant="outlined"
							size="small"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<TextField
							label="End Period"
							type="date"
							value={
								periodEnd
									? moment(periodEnd).format("YYYY-MM-DD")
									: ""
							}
							onChange={onPeriodEndChange}
							variant="outlined"
							size="small"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<Button
							variant="outlined"
							onClick={handleClearFilters}
							className={classes.resetButton}
							size="small"
						>
							<Typography className={classes.typography}>
								Clear Filters
							</Typography>
						</Button>
					</div>
				</Box>
			</Box>
			<AddBoostModal
				open={open}
				onAdd={handleAddBoost}
				onClose={() => setOpen(false)}
			/>
			<Box className={classes.listWrapper}>
				<BoostsList
					boosts={boosts}
					currentPage={currentPage}
					totalPages={totalPages}
					setCurrentPage={setCurrentPage}
					onEdit={handleEditBoost}
					onDelete={onDelete}
					onDuplicate={onDuplicate}
					loading={loading}
				/>
				{openEditModal && (
					<Dialog
						open={openEditModal}
						onClose={handleCloseModal}
						fullWidth
						disableEnforceFocus
						PaperProps={{
							style: {
								position: "absolute",
								right: 0,
								minWidth: "55vw",
								maxHeight: "100vh",
								height: "100%",
								margin: 0,
								borderRadius: 0,
							},
						}}
					>
						{boostLoading || !boost || boost._id !== editableBoostId ? (
         					<div className={classes.loadingContainer}>
								<CircularProgress />
							</div>
						) : boost ? (
							<BoostEditModal
								key={boost._id}
								initialBoost={boost}
								onSave={handleSaveEditBoost}
								onClose={handleCloseModal}
							/>
						) : null}
					</Dialog>
				)}
			</Box>
		</Box>
	);
};
