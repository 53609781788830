import React, { useEffect, useState } from "react";
import { useStyles } from "./FavoritesComponent.styles";
import { Item } from "../types/commonObjIncTypes";
import ItemComponent from "../common/ItemComponent";
import { Incentive } from "../types/incentivesTypes";
import useItems from "../hooks/useItems";
import moment from "moment";
import useHandleItemsUpdate from "../hooks/useHandleItemsUpdate";
import useItem from "../hooks/useItem";

export const FavoriteIncentivesComponent: React.FC = () => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedTag, setSelectedTag] = useState<string>("");
	const [periodStart, setPeriodStart] = useState<string | null>(null);
	const [periodEnd, setPeriodEnd] = useState<string | null>(null);
	const [selectedType, setSelectedType] = useState<string | undefined>("");

	const [selectedIncentiveId, setSelectedIncentiveId] = useState<string>("");
	const [pendingAction, setPendingAction] = useState<{
		id: string;
		type: "archive" | "favorite";
	} | null>(null);

	const { item, itemLoading } = useItem({
		itemId: selectedIncentiveId,
		isObjective: false,
		isArchived: false,
	});

	const { items, currentPage, setCurrentPage, totalPages, loading } = useItems({
		searchPhrase: searchTerm !== "" ? searchTerm : undefined,
		type: selectedType,
		tag: selectedTag || undefined,
		periodStart,
		periodEnd,
		itemsType: "incentives",
		isObjective: false,
		isArchived: false,
		isFavorite: true,
	});

	const { addItem, editItem, deleteItem } = useHandleItemsUpdate({
		itemType: "incentive",
		isObjective: false,
	});

	useEffect(() => {
		if (
			pendingAction &&
			!itemLoading &&
			item &&
			item._id === pendingAction.id
		) {
			const updatedItem = {
				...item,
				archived:
					pendingAction.type === "archive" ? !item.archived : item.archived,
				favorite:
					pendingAction.type === "favorite" ? !item.favorite : item.favorite,
			};

			editItem(pendingAction.id, updatedItem, updatedItem.isGrouped, true);
			setPendingAction(null);
		}
	}, [pendingAction, itemLoading, item, editItem]);

	const handleAdd = async (newData: Omit<Item, "_id">) => {
		return await addItem(newData as Omit<Incentive, "_id">);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editItem(id, updated as Partial<Incentive>);
	};

	const handleDelete = (id: string) => {
		deleteItem(id);
	};

	const handleFavorite = (id: string) => {
		if (id) {
			setSelectedIncentiveId(id);
			setPendingAction({ id, type: "favorite" });
		}
	};

	const handleDuplicate = (id: string) => {
		const item = items.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
				totalAchieved: 0,
				percentageAchieved: 0,
				totalItems: 0,
				totalPayout: 0,
				checks: [],
				isEdited: false,
			}));
			handleAdd({
				...restOfObject,
				name: newName,
				favorite: false,
				users: initializedUsers,
			});
		}
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleTypeChange = (selectedOption: any) => {
		if (selectedOption.value === selectedType) {
			setSelectedType("");
		} else {
			setSelectedType(selectedOption.value);
		}
	};

	const handleTagChange = (selectedOption: any) => {
		if (selectedOption.value === selectedTag) {
			setSelectedTag("");
		} else {
			setSelectedTag(selectedOption.value);
		}
	};

	const handlePeriodStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodStart(formattedDate);
		} else {
			setPeriodStart(null);
		}
	};

	const handlePeriodEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			const formattedDate = moment(value).format("YYYY-MM-DD");
			setPeriodEnd(formattedDate);
		} else {
			setPeriodEnd(null);
		}
	};

	const handleClearFilters = () => {
		setCurrentPage(1);
		setSearchTerm("");
		setSelectedTag("");
		setSelectedType("");
		setPeriodStart(null);
		setPeriodEnd(null);
	};

	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					items={items}
					itemType="incentive"
					showAddButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
					currentPage={currentPage}
					loading={loading}
					setCurrentPage={setCurrentPage}
					totalPages={totalPages}
					onArchive={function (id: string): void {
						throw new Error("Function not implemented.");
					}}
					onAction={function (id: string): void {
						throw new Error("Function not implemented.");
					}}
					onReset={() => {}}
					searchTerm={searchTerm}
					onSearchChange={handleSearchChange}
					selectedTag={selectedTag}
					onTagChange={handleTagChange}
					periodStart={periodStart}
					periodEnd={periodEnd}
					onPeriodStartChange={handlePeriodStartChange}
					onPeriodEndChange={handlePeriodEndChange}
					selectedType={selectedType}
					onTypeChange={handleTypeChange}
					handleClearFilters={handleClearFilters}
				/>
			</div>
		</div>
	);
};
