import { useEffect, useMemo } from "react";
import { useState } from "react";
import { IBoostProductsQuery } from "../../../hooks/useQuery";
import useQuery from "../../../hooks/useQuery";
import { ManualProductsFilters } from "../../usersManagement/types/commonObjIncTypes";
import { boostStore } from "../store/boostStore";
import { debounce } from "lodash";

const useAllProducts = ({
	page,
	boostId,
	filters,
}: {
	page: number;
	boostId: string;
	filters: ManualProductsFilters;
}) => {
	const { query } = useQuery<IBoostProductsQuery>();
	const { wholesalerId } = query;

	const allProducts = boostStore((state) => state.products);

	const [debouncedFilters, setDebouncedFilters] = useState<ManualProductsFilters>(filters);

	const debouncedUpdateFilters = useMemo(
		() =>
			debounce((updatedFilters: ManualProductsFilters) => {
				setDebouncedFilters(updatedFilters);
			}, 300),
		[]
	);

	useEffect(() => {
		debouncedUpdateFilters(filters);
	}, [filters, debouncedUpdateFilters]);

	const updateProducts = (
		newProducts: { productId: string; checked: boolean }[]
	) => {
		boostStore.getState().updateProducts(
			{ boostId, wholesalerId },
			newProducts
		);
	};

	useEffect(() => {
		const extendedFilters = { ...debouncedFilters, wholesalerId };

		boostStore.getState().fetchProducts({
			page,
			wholesalerId,
			boostId,
			query: extendedFilters,
		});
	}, [boostId, page, query, wholesalerId, debouncedFilters]);

	return {
		allProducts,
		productsLoading: boostStore.getState().productsLoading,
		totalPages: boostStore.getState().productsTotalPages,
		updateProducts,
	};
};

export default useAllProducts;
