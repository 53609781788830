import { useEffect, useRef } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import {
	CustomObjIncAccountFilters,
	isIncentive,
} from "../types/commonObjIncTypes";
import { Incentive } from "../types/incentivesTypes";
import { Objective } from "../types/objectiveTypes";

const useSelectedCustomAccounts = ({
	page,
	item,
	selectedAccountsFilters,
	searchPhrase,
}: {
	page: number;
	item: Incentive | Objective;
	selectedAccountsFilters: CustomObjIncAccountFilters;
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;
	const prevItemRef = useRef<string | null>(null);

	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}

	const selectedCustomLoading = commonObjIncStoreZustand(
		(state) => state.selectedCustomAccountsLoading
	);

	const selectedCustomAccounts = commonObjIncStoreZustand(
		(state) => state.selectedCustomAccounts
	);

	useEffect(() => {
		const currentItemId = item?._id;

		// Only fetch if the item has changed or if it's the first render
		if (currentItemId !== prevItemRef.current) {
			const extendedFilters = {
				...selectedAccountsFilters,
				searchPhrase,
				wholesalerId,
			};

			commonObjIncStoreZustand.getState().fetchSelectedCustomAccounts(
				{
					wholesalerId,
					page,
					[typeKey]: currentItemId,
					isObjective: !isIncentive(item),
				},
				extendedFilters
			);

			prevItemRef.current = currentItemId;
		}
	}, [
		item,
		page,
		query,
		typeKey,
		wholesalerId,
		selectedAccountsFilters,
		searchPhrase,
	]);

	return {
		selectedCustomLoading,
		selectedCustomAccounts,
		selectedCustomAccountsTotalPages:
			commonObjIncStoreZustand.getState().selectedCustomAccountsTotalPages,
	};
};

export default useSelectedCustomAccounts;
