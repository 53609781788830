import {
	defaultAdvancedPayments,
	PayoutDetails,
} from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { capitalizeFirstLetter } from "../../usersManagement/utils";
import { IRow } from "../store/commonObjIncStore";

export const columnKeyMap: Record<string, string> = {
	"User Name": "userName",
	Route: "route",
	"User Type": "userType",
	Objective: "objective",
	Incentive: "incentive",
	"Sub Objective": "subObjectives",
	"Sub Incentive": "subIncentives",
	"Grouped Objective": "groupedObjective",
	"Grouped Incentive": "groupedIncentive",
	Type: "type",
	"Start Date": "startDate",
	"End Date": "endDate",
	Status: "status",
	Quantity: "quantity",
	Measure: "measure",
	"Grouped Quantity": "groupedQuantity",
	Target: "userTarget",
	Potential: "payout",
	"Payout MTD": "payoutMTD",
	"Supplier Contribution": "supplierContribution",
	Image: "images",
	Accounts: "accounts",
	Products: "products",
	AccountsResults: "accountsResults",
	"Input Value": "valueInput",
	"Input Value Type": "valueInputType",
	"Minimum Quantity": "minQuantityValue",
	Weight: "weight",
	"Individual Status": "individualStatus",
	"Grouped Status": "groupedStatus",
	"Rejected Images": "rejectedImages",
	"Eligible Quantity": "eligibleQuantity",
	"Parent Item Name": "parentItemName",
};

export const transformColumnName = (colName: string): string => {
	return columnKeyMap[colName] || colName;
};

export const formatDate = (dateString: string, isShort?: boolean): string => {
	const date = new Date(dateString);
	if (isNaN(date.getDate())) {
		return "-";
	}
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: isShort ? "short" : "long",
		day: "numeric",
		timeZone: "UTC",
	};
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatTime = (dateString: string) => {
	const options: Intl.DateTimeFormatOptions = {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		timeZone: "America/Chicago",
	};

	const date = new Date(dateString);
	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDateAndTime = (dateString: string) => {
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		timeZone: "UTC",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	};

	const date = new Date(dateString);

	return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const initialPayoutDetails: PayoutDetails = {
	target: 0,
	allOrNothingAmount: 0,
	perActionAmount: 0,
	atRiskAmount: 0,
	payoutType: "perAction",
	threshold: { units: "percentage", value: 0 },
	cap: { units: "percentage", value: 100 },
	isOpenEnded: false,
	advancedBudget: 0,
	advancedPayments: defaultAdvancedPayments,
	isAccountSpecific: false,
	isFullAmount: false,
};

export function formatTypeLabel(type: string): string {
	if (type)
		return type
			.replace(/([a-z])([A-Z])/g, "$1 $2")
			.replace(/^\w/, (c) => c.toUpperCase());
	return type;
}

export type ObjIncentiveImage = {
	_id: string | null;
	title: string;
	imageUrl: string;
	notes: string;
	rejected: boolean;
	rejectedReason: string;
	buyerId: string;
	account: string;
	date: string;
	validationQuantity?: number;
};

export const mapImagesDataForExport = (
	rows?: IRow[],
	includeQuantities?: boolean
) => {
	return rows?.flatMap((row) => {
		// Ensure `row.images` is treated as an array of ObjIncentiveImage
		const images: ObjIncentiveImage[] = row.images || [];
		return images.map((image: ObjIncentiveImage) => {
			// Base data for each image
			const baseData: Record<string, any> = {
				"Item Name": row.name || "", // Using "name" as Incentive
				"User Name": capitalizeFirstLetter(row.userName) || "",
				"User Id": row.userId || "",
				Route: row.route || "",
				Account: image.account || "",
				"Buyer Id": image.buyerId || "",
				Title: image.title || "",
				"Is Rejected": image.rejected ? "Yes" : "No",
				Date: formatDate(image.date) || "-",
				Time: `${formatTime(image.date)} CST` || "-",
				"Image Url": image.imageUrl || "",
				Notes: image.notes || "-",
				"Rejected Reason": image.rejectedReason || "-",
			};

			// Add quantities if required
			if (includeQuantities) {
				baseData["Minimum Quantity"] = row.quantity || "-";
				baseData["Eligible Quantity"] = row.eligibleQuantity || "-";
			}

			return baseData;
		});
	});
};

export const formatImagesDataToRows = (
	imagesData: any[],
	includeQuantities: boolean
) => {
	const headers = [
		"Item Name",
		"User Name",
		"User Id",
		"Route",
		"Account",
		"Buyer Id",
		"Title",
		"Is Rejected",
		"Date",
		"Time",
		"Image Url",
		"Notes",
		"Rejected Reason",
	];

	if (includeQuantities) {
		headers.splice(6, 0, "Quantity", "Minimum Quantity");
	}

	const rows = imagesData.map((image) => {
		const rowData = [
			image["Item Name"],
			image["User Name"],
			image["User Id"],
			image.Route,
			image.Account,
			image["Buyer Id"],
			image.Title,
			image["Is Rejected"],
			image.Date,
			image.Time,
			image["Image Url"],
			image.Notes,
			image["Rejected Reason"],
		];

		if (includeQuantities) {
			rowData.splice(6, 0, image.Quantity, image["Minimum Quantity"]);
		}

		return rowData;
	});

	let totalQuantity = 0;
	let totalMinimumQuantity = 0;

	if (includeQuantities) {
		imagesData.forEach((image) => {
			totalQuantity += parseFloat(image.Quantity) || 0;
			totalMinimumQuantity += parseFloat(image["Minimum Quantity"]) || 0;
		});

		const totalRow = new Array(headers.length).fill("");
		totalRow[0] = "Total";

		totalRow[6] = totalQuantity.toString();
		totalRow[7] = totalMinimumQuantity.toString();

		return [headers, ...rows, totalRow];
	}

	return [headers, ...rows];
};

const specificColumns: Record<string, string[]> = {
	objective: [
		"User Name",
		"Route",
		"Item Name",
		"Type",
		"Measure",
		"Status",
		"Quantity",
		"Eligible Quantity",
		"Target",
		"Potential",
		"Payout MTD",
		"Accounts",
		"Products",
		"Accounts Results",
	],
	incentive: [
		"User Name",
		"Route",
		"Item Name",
		"Type",
		"Measure",
		"Status",
		"Quantity",
		"Eligible Quantity",
		"Target",
		"Potential",
		"Payout MTD",
		"Supplier Contribution",
		"Accounts",
		"Products",
		"Accounts Results",
	],
};

const typeSpecificColumns: Record<string, string[]> = {
	image: ["Image", "Rejected Images"],
	binary: [],
	quantity: [],
	validation: ["Input Value Type", "Input Value"],
	grouped: [],
	multi: [],
	imageValidation: ["Minimum Quantity", "Image", "Rejected Images"],
};

const getColumnSet = (type: string, specific: string[]) => {
	let combinedColumns = [
		...new Set([...specific, ...(typeSpecificColumns[type] || [])]),
	];

	if (type === "imageValidation") {
		const targetIndex = combinedColumns.indexOf("Target");
		if (targetIndex !== -1) {
			combinedColumns = [
				...combinedColumns.slice(0, targetIndex + 1),
				"Minimum Quantity",
				...combinedColumns
					.slice(targetIndex + 1)
					.filter((col) => col !== "Minimum Quantity"),
			];
		}
	}

	const accountsIndex = combinedColumns.indexOf("Accounts");
	if (accountsIndex !== -1) {
		combinedColumns.splice(accountsIndex, 1);
	}

	const productsIndex = combinedColumns.indexOf("Products");
	if (productsIndex !== -1) {
		combinedColumns.splice(productsIndex, 1);
	}

	const accountsResultsIndex = combinedColumns.indexOf("Accounts Results");
	if (accountsResultsIndex !== -1) {
		combinedColumns.splice(accountsResultsIndex, 1);
	}

	return [...combinedColumns, "Accounts", "Products", "Accounts Results"];
};

const determineColumns = (rows: any[], typeKey: string) => {
	const uniqueTypes = new Set(rows.map((row) => row.type));
	const type =
		uniqueTypes.size === 1 ? uniqueTypes.values().next().value : null;

	if (type) {
		return getColumnSet(type, specificColumns[typeKey]);
	} else {
		return getColumnSet("default", specificColumns[typeKey]);
	}
};

export const determineColumnsToShowObjective = (rows: any[]) => {
	return determineColumns(rows, "objective");
};

export const determineColumnsToShowIncentive = (rows: any[]) => {
	return determineColumns(rows, "incentive");
};

export const calculateTableTotals = (rows: any[], itemType: string) => {
	return rows.reduce(
		(totals, row) => {
			return {
				payout: totals.payout + parseFloat(row.potential || "0"),
				payoutMTD: totals.payoutMTD + parseFloat(row.payoutMTD || "0"),
				target: totals.target + parseFloat(row.userTarget || "0"),
				quantity: totals.quantity + parseInt(row.quantity || "0"),
				eligibleQuantity:
					totals.eligibleQuantity +
					(isNaN(parseInt(row.eligibleQuantity))
						? 0
						: parseInt(row.eligibleQuantity)),
				supplierContribution:
					itemType === "incentive"
						? totals.supplierContribution +
						  parseFloat(row.supplierContribution || "0")
						: 0,
			};
		},
		{
			payout: 0,
			payoutMTD: 0,
			target: 0,
			quantity: 0,
			eligibleQuantity: 0,
			supplierContribution: 0,
		}
	);
};

export const filterRows = (rows: IRow[], searchValue: string) => {
	const normalizedSearch = searchValue.toLowerCase();
	return rows.filter((row) =>
		Object.values(row).some(
			(value) =>
				value && value.toString().toLowerCase().includes(normalizedSearch)
		)
	);
};
