import { makeStyles } from "@material-ui/core";
import colorsArray from "../../utils/colorsArray";
import { indigo } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
	addModal: {
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	dialogTitle: {
		textAlign: "center",
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		width: "500px",
	},
	formControl: {
		margin: "8px 0",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
	},
	button: {
		margin: theme.spacing(1),
		borderRadius: "50px",
		textTransform: "none",
		fontWeight: "lighter",
		width: "90px",
		height: "45px",
	},
	addButton: {
		backgroundColor: colorsArray[2][300],
		color: "white",
		"&:hover": {
			backgroundColor: colorsArray[2][400],
		},
	},
	cancelButton: {
		backgroundColor: "#9e9e9e",
		color: "white",
		"&:hover": {
			backgroundColor: "#757575",
		},
	},
	listWrapper: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		minHeight: "50rem",
		marginTop: "1rem",
	},
	itemList: {
		flex: 1,
		maxWidth: "100%",
		fontSize: 9,
		marginBottom: "1rem",
		maxHeight: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	tableContainer: {
		display: "flex",
		flexDirection: "column",
		height: "100%",
		maxHeight: "calc(100% - 16px)",
		position: "relative",
	},
	tableWrapper: {
		minHeight: 0,
		flex: 1,
		overflow: "auto",
	},
	customTable: {
		width: "100%",
		overflowX: "auto",
	},
	tableHead: {
		"& .MuiTableCell-head": {
			color: "white",
			backgroundColor: indigo[300],
		},
	},
	customCell: {
		backgroundColor: "lightgrey",
		padding: "15px",
	},
	actionsButtons: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	customRow: {
		padding: "5px 10px",
		"& td": {
			minWidth: "fit-content",
		},
	},
	footer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "8px 16px",
		borderTop: "1px solid rgba(224, 224, 224, 1)",
		backgroundColor: "#fff",
		position: "sticky",
		bottom: 0,
		zIndex: 2,
	},
	filterContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
		width: "100%",
		flexWrap: "wrap",
		margin: "0.5rem 0",
	},
	datePickerContainer: {
		display: "flex",
		flexDirection: "row",
		gap: "1rem",
	},
	resetButton: {
		color: "#3D4EA1",
		backgroundColor: "#e8eaf6",
		borderColor: "#D0D5ED",
	},
	typography: {
		fontSize: "16px",
		textTransform: "none",
	},
	loadingContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		minHeight: "300px",
	},
}));
