import {
	Item,
	MeasureState,
	measureFields,
	measureFieldsAccountSpecific,
} from "../../types/commonObjIncTypes";
import { Incentive } from "../../types/incentivesTypes";
import { AdvancedPayment, Objective } from "../../types/objectiveTypes";

export interface PayoutDetails {
	target: number | string;
	payoutType: string;
	allOrNothingAmount: number;
	perActionAmount: number;
	atRiskAmount: number;
	threshold: { units: string; value: number };
	cap: { units: string; value: number };
	isOpenEnded: boolean;
	advancedPayments?: AdvancedPayment[];
	advancedBudget?: number;
	isAccountSpecific: boolean;
	isFullAmount: boolean;
}

export const defaultAdvancedPayments: AdvancedPayment[] = [
	{ completed: 70, ofBudget: 50 },
	{ completed: 80, ofBudget: 60 },
	{ completed: 90, ofBudget: 80 },
	{ completed: 100, ofBudget: 100 },
	{ completed: 110, ofBudget: 105 },
	{ completed: 150, ofBudget: 120 },
];

export interface GroupedTableItem {
	id: string;
	weight: number;
}

export interface UserRole {
	user: string;
	isTeamLead?: boolean;
	isLeadership?: boolean;
}

export const getPayoutType = (payoutDetails: PayoutDetails) => {
	switch (payoutDetails.payoutType) {
		case "allOrNothing":
			return payoutDetails.allOrNothingAmount;
		case "perAction":
			return payoutDetails.perActionAmount;
		case "atRisk":
			return payoutDetails.atRiskAmount;
		default:
			return 0;
	}
};

export const payoutBasedOnType = (item: Incentive | Objective) => {
	switch (item.payoutType) {
		case "allOrNothing": {
			return item?.quantityPayout || 0;
		}
		case "perAction":
			return (item?.quantityPayout || 1) * item.payout;
		case "atRisk":
			return (item?.quantityPayout || 1) * item.payout;
		default:
			return 0;
	}
};

export const updatedUsers = (
	selectedUsers: UserRole[],
	item: Item,
	target: number,
	payout: number,
	payoutType: string,
	isOpenEnded: boolean,
	threshold: { units: string; value: number },
	cap: { units: string; value: number },
	minQuantityValue: number,
	advancedBudget?: number,
	advancedPayments?: AdvancedPayment[],
	isAccountSpecific?: boolean
) => {
	const users = selectedUsers.map((selectedUserId) => {
		// Find the user in the existing users array
		const existingUser = item?.users?.find(
			({ user }) => user === selectedUserId.user
		);

		// If the user already exists, return their current data
		if (existingUser) {
			return existingUser;
		}

		// If the user is new, return a new user object with default values
		return {
			user: selectedUserId.user,
			isTeamLead: selectedUserId.isTeamLead || false,
			isLeadership: selectedUserId.isLeadership || false,
			images: [],
			quantity: null,
			validation: null,
			completed: false,
			subObjectives: null,
			goal: null,
			target: target,
			payout: payout,
			payoutType: payoutType,
			isOpenEnded: isOpenEnded,
			cap: cap,
			threshold: threshold,
			totalPods: 0,
			totalAchieved: 0,
			percentageAchieved: 0,
			totalItems: 0,
			totalPayout: 0,
			isEdited: false,
			minQuantityValue: minQuantityValue,
			advancedBudget: advancedBudget,
			advancedPayments: advancedPayments,
			isAccountSpecific: isAccountSpecific,
		};
	});

	return users;
};

export const initializeMeasures = (
	receivedMeasure: MeasureState | undefined,
	isAccountSpecific: boolean
) => {
	const availableFields = isAccountSpecific
		? measureFieldsAccountSpecific.map((field) => field.label)
		: measureFields.map((field) => field.label);

	const isReceivedMeasureValid = receivedMeasure?.label && availableFields.includes(receivedMeasure.label);

	const measuresInitialState = availableFields.map((field, index) => {
		const shouldBeChecked = isReceivedMeasureValid 
			? field === receivedMeasure?.label
			: index === 0;

		const foundMeasure =
			receivedMeasure && receivedMeasure.label === field
				? receivedMeasure
				: null;

		return (
			foundMeasure || {
				label: field,
				startDate: new Date().toISOString(),
				endDate: new Date().toISOString(),
				checked: shouldBeChecked,
				symbol:
					field !== undefined && measureFields !== undefined
						? measureFields.find((measure) => measure?.label === field)?.symbol
						: "$",
				trackingOption: "default",
				trackingPeriodStart: new Date().toISOString(),
					trackingPeriodEnd: new Date().toISOString(),
					referenceTrackingPeriod: new Date().toISOString(),
			}
		);
	});

	return measuresInitialState;
};

export function lowercaseFirstLetter(str: string) {
	return str.charAt(0).toLowerCase() + str.slice(1);
}
