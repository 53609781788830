import { IGeneralQuery } from "../../../hooks/useQuery";
import { useEffect } from "react";
import { boostStore } from "../store/boostStore";
import useQuery from "../../../hooks/useQuery";

const useBoost = ({ boostId }: { boostId?: string }) => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const boost = boostStore((state) => state.boost);
	const boostLoading = boostStore((state) => state.boostLoading);

	useEffect(() => {
		if (boostId) {
			boostStore.getState().fetchBoost({ wholesalerId }, boostId);
			boostStore.getState().fetchUsedProductsFilters({
				wholesalerId,
			});
		}
	}, [wholesalerId, boostId]);

	return { boost, boostLoading };
};

export default useBoost;
