import React, { useState } from "react";
import {
	Chip,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import ArchiveIcon from "@mui/icons-material/Archive";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ReplayOutlined from "@mui/icons-material/ReplayOutlined";
import { capitalizeFirstLetter } from "../../utils";
import { Item, isIncentive } from "../../types/commonObjIncTypes";
import {
	formatDate,
	formatTime,
	formatTypeLabel,
} from "../../../objectivesIncentives/utils/utils";
import { useStyles } from "./ItemsList.styles";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";

interface ItemDisplayComponentProps {
	item: Item;
	tag?: { name: string; color: string };
	showDeleteButton?: boolean;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate?: () => void;
	onFavorite?: (id: string) => void;
	onArchive: (id: string) => void;
	showActionQueryButton?: boolean;
	onAction: (id: string) => void;
	onReset: (id: string) => void;
}

export const ItemDisplayComponent: React.FC<ItemDisplayComponentProps> = ({
	item,
	tag,
	showDeleteButton = true,
	onDelete,
	onEdit,
	onDuplicate,
	onFavorite,
	onArchive,
	showActionQueryButton,
	onAction,
	onReset,
}) => {
	const isAdmin = useSelector(
		(state: RootState) => state.session.role === "admin"
	);

	const [isFavorite, setIsFavorite] = useState(item?.favorite || false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalAction, setModalAction] = useState<
		"delete" | "archive" | "action" | "reset" | null
	>(null);
	const classes = useStyles();

	const toggleFavorite = () => {
		setIsFavorite(!isFavorite);
		if (item && item._id !== undefined && onFavorite) {
			onFavorite(item?._id);
		}
	};

	const handleModalOpen = (
		action: "delete" | "archive" | "action" | "reset"
	) => {
		setModalAction(action);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalAction(null);
	};

	const wholesalerLastUpdate = useSelector((state: RootState) =>
		state.wholesaler.lastUpdateTimestamp
	);

	const isLessThan30MinutesAgo = (lastRunAt: string | undefined) => {
		if (!lastRunAt) return false;

		const lastRunDate = new Date(lastRunAt);
		const now = new Date();
		const diffInMinutes = (now.getTime() - lastRunDate.getTime()) / (1000 * 60);

		if (wholesalerLastUpdate && new Date(wholesalerLastUpdate) > lastRunDate) {
			return false;
		}

		return diffInMinutes < 30;
	};

	const handleConfirm = () => {
		if (!modalAction) return;
		switch (modalAction) {
			case "delete":
				onDelete(item._id);
				break;
			case "archive":
				onArchive(item._id);
				break;
			case "action":
				onAction(item._id);
				break;
			case "reset":
				onReset(item._id);
				break;
		}
		handleModalClose();
	};

	const getModalProps = () => {
		let title = "";
		let contentText = "";
		let confirmButtonText = "";
		switch (modalAction) {
			case "delete":
				title = "Confirm Delete";
				contentText = `Are you sure you want to delete ${item.name}?`;
				confirmButtonText = "Delete";
				break;
			case "archive":
				const labelArchived = item?.archived ? "Restore" : "Archive";
				title = `Confirm ${labelArchived}`;
				contentText = `Are you sure you want to ${labelArchived} ${item.name}?`;
				confirmButtonText = labelArchived;
				break;
			case "action":
				title = "Confirm Action";
				contentText = `Are you sure you want to execute ${item.name}?`;
				confirmButtonText = "Execute";
				break;
			case "reset":
				const itemType = isIncentive(item) ? "incentive" : "objective";
				title = "Confirm Reset";
				contentText = `Resetting the ${itemType} will reset users values to the default ones`;
				confirmButtonText = "Reset";
				break;
			default:
				break;
		}
		return { title, contentText, confirmButtonText };
	};

	if (!item) {
		return null;
	}

	return (
		<TableRow className={classes.customRow}>
			<TableCell className={`${classes.customRow} ${classes.customRowName}`}>
				<Tooltip title={capitalizeFirstLetter(item.name)}>
					<div className={classes.truncateText}>
						{capitalizeFirstLetter(item.name)}
					</div>
				</Tooltip>
			</TableCell>
			<TableCell className={`${classes.customRow} ${classes.customRowTag}`}>
				{tag ? (
					<Chip
						label={tag.name}
						size="small"
						style={{
							backgroundColor: `${tag.color}1A`,
							color: tag.color,
							borderRadius: "15px",
							height: "28px",
							padding: "0 12px",
							fontWeight: "normal",
						}}
					/>
				) : (
					"-"
				)}
			</TableCell>
			<TableCell
				className={`${classes.customRow} ${classes.customQuantityType}`}
			>
				{formatTypeLabel(item.type)}
			</TableCell>
			<TableCell
				className={`${classes.customRow} ${classes.customMeasuresType}`}
			>
				{formatTypeLabel(item.measure?.label || "-")}
			</TableCell>
			<TableCell className={`${classes.customRow} ${classes.customRowPeriod}`}>
				{`${formatDate(item?.customPeriodStart || "")} -
					${formatDate(item?.customPeriodEnd || "")}`}
			</TableCell>
			{isAdmin && (
				<TableCell className={`${classes.customRow} ${classes.customRowPeriod}`}>
					{item?.lastRunAt
						? `${formatDate(item?.lastRunAt)} ${formatTime(item?.lastRunAt)} CST`
						: "-"}
				</TableCell>
			)}
			<TableCell className={`${classes.customRow} ${classes.actionsButtons}`}>
				<IconButton
					size="small"
					color="primary"
					onClick={() => onEdit(item._id)}
				>
					<Tooltip title="Edit">
						<EditIcon />
					</Tooltip>
				</IconButton>
				<IconButton size="small" color="primary" onClick={onDuplicate}>
					<Tooltip title="Duplicate">
						<FileCopyIcon />
					</Tooltip>
				</IconButton>
				{showDeleteButton && (
					<IconButton size="small" onClick={() => handleModalOpen("delete")}>
						<Tooltip title="Delete">
							<DeleteIcon />
						</Tooltip>
					</IconButton>
				)}
				<IconButton size="small" onClick={toggleFavorite}>
					<Tooltip title={isFavorite ? "Remove Favorite" : "Favorite"}>
						{isFavorite ? (
							<StarIcon style={{ color: "#F0C146" }} />
						) : (
							<StarBorderIcon />
						)}
					</Tooltip>
				</IconButton>
				<IconButton
					size="small"
					color="primary"
					onClick={() => handleModalOpen("reset")}
				>
					<Tooltip title="Reset">
						<ReplayOutlined />
					</Tooltip>
				</IconButton>
				<IconButton
					size="small"
					color="primary"
					onClick={() => handleModalOpen("archive")}
				>
					<Tooltip title={item?.archived ? "Remove from Archived" : "Archive"}>
						<ArchiveIcon />
					</Tooltip>
				</IconButton>
				{showActionQueryButton && (
					<Tooltip title={
						isLessThan30MinutesAgo(item?.lastRunAt || undefined)
							? "You need to wait 30 minutes between executions"
							: "Execute"
					}>
						<span>
							<IconButton
								size="small"
								color="primary"
								disabled={isLessThan30MinutesAgo(item?.lastRunAt || undefined)}
								onClick={() => handleModalOpen("action")}
							>
								<PlayCircleFilledIcon />
							</IconButton>
						</span>
					</Tooltip>
				)}
				<ConfirmationModal
					open={modalOpen}
					title={getModalProps().title}
					contentText={getModalProps().contentText}
					onClose={handleModalClose}
					onConfirm={handleConfirm}
					confirmButtonText={getModalProps().confirmButtonText}
				/>
			</TableCell>
		</TableRow>
	);
};
