import { useEffect } from "react";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";

const useFetchProductsFilters = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	useEffect(() => {
		objectivesStoreZustand.getState().fetchProducts({ wholesalerId });
	}, [wholesalerId]);

};

export default useFetchProductsFilters;
