import { useEffect } from "react";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";

const useFetchDistinctAccounts = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchAccountTypes({ wholesalerId });
	}, [wholesalerId]);

};

export default useFetchDistinctAccounts;
