import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	formWrapper: {
		display: "flex",
		flexDirection: "row",
	},
	formControl: {
		display: "flex",
		flexDirection: "row",
		margin: "20px 0",
	},
	datePicker: {
		marginBottom: "20px",
	},
	periodFormControl: {
		display: "flex",
		flexDirection: "row",
		margin: "0px 20px",
	},
	container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "3rem",
	},
	nameDescriptionContainer: {
		display: "flex",
		flexDirection: "column",
		minWidth: "46%",
	},
	lastRunAt: {
		marginTop: "1rem",
		marginBottom: "1rem",
		fontStyle: "italic",
	},
});
